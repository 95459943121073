<script setup lang="ts">
import { ArticlePath } from '@/generated/graphql'

type ArticleNameProvider = {
  articleNumber: string
  revision: string
  name?: string
  articlePath?: ArticlePath
}

const props = defineProps<{
  article: ArticleNameProvider
  showPath?: boolean
}>()
</script>

<template>
  <span class="number">{{ props.article.articleNumber }}/{{ props.article.revision }}</span>
  <span v-if="props.article.articlePath && props.showPath" class="path">
    {{ props.article.articlePath.articleNumbers.join(' > ') }}
  </span>
  <div v-if="props.article.name" class="name">{{ props.article.name }}</div>
</template>

<style scoped lang="scss">
.number {
  font-weight: bold;
}
.name,
.path {
  font-size: 0.8em;
  color: #555;
}

.path {
  margin-left: 1em;
}
</style>

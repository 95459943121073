import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import EmptyParentView from '@/views/EmptyParentView.vue'
import AdminOrgView from '@/views/admin/OrganizationView.vue'
import AdminUserView from '@/views/admin/UserView.vue'
import ProductView from '@/views/organization/ProductView.vue'
import OrganizationParentView from '@/views/organization/OrganizationParentView.vue'
import ProductConfigurationView from '@/views/organization/ProductConfigurationView.vue'
import SiteView from '@/views/organization/SiteView.vue'
import BopView from '@/views/organization/BopView.vue'
import BopProcessView from '@/views/organization/BopProcessView.vue'
import StepTypeView from '@/views/organization/StepTypeView.vue'
import ModuleView from '@/views/organization/ModuleView.vue'
import ModulePrintView from '@/views/organization/ModulePrintView.vue'
import ToolView from '@/views/organization/ToolView.vue'
import EbomView from '@/views/organization/EbomView.vue'
import opentelemetry, { Span } from '@opentelemetry/api'
import OperatorHomeView from '@/views/organization/operator/OperatorHomeView.vue'
import OperatorProductConfigurationView from '@/views/organization/operator/OperatorProductConfigurationView.vue'
import OperatorModuleView from '@/views/organization/operator/OperatorModuleView.vue'
import ArticleView from '@/views/organization/ArticleView.vue'
import OperatorParentView from '@/views/organization/operator/OperatorParentView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
    },
    {
      name: 'admin-parent',
      path: '/admin/',
      component: EmptyParentView,
      children: [
        {
          name: 'admin-organizations',
          path: 'organizations',
          component: AdminOrgView,
        },
        {
          name: 'admin-users',
          path: 'users',
          component: AdminUserView,
        },
      ],
    },
    {
      name: 'organization-parent',
      path: '/organization/:organizationSlug/',
      component: OrganizationParentView,
      children: [
        {
          name: 'articles',
          path: 'articles',
          component: ArticleView,
        },
        {
          name: 'sites',
          path: 'sites',
          component: SiteView,
        },
        {
          name: 'tools',
          path: 'tools',
          component: ToolView,
        },
        {
          name: 'stepTypes',
          path: 'step-types',
          component: StepTypeView,
        },
        {
          name: 'products',
          path: 'products',
          component: ProductView,
        },
        {
          name: 'productConfigurations',
          path: 'products/:productId/configurations',
          component: ProductConfigurationView,
        },
        {
          name: 'bops',
          path: 'products/:productId/configurations/:productConfigId/sites/:siteId/bills-of-process',
          component: BopView,
        },
        {
          name: 'bopProcess',
          path: 'products/:productId/configurations/:productConfigId/sites/:siteId/bills-of-process/:bopId',
          component: BopProcessView,
        },
        {
          name: 'module',
          path: 'products/:productId/configurations/:productConfigId/sites/:siteId/bills-of-process/:bopId/module/:moduleId/:stepId?',
          component: ModuleView,
        },
        {
          name: 'module-print',
          path: 'products/:productId/configurations/:productConfigId/sites/:siteId/bills-of-process/:bopId/module/:moduleId/print',
          component: ModulePrintView,
        },
        {
          name: 'ebom',
          path: 'products/:productId/configurations/:productConfigId/sites/:siteId/bills-of-process/:bopId/ebom/:ebomId/:ebomPartId?',
          component: EbomView,
        },
        {
          name: 'operatorParent',
          path: 'operator/',
          component: OperatorParentView,
          children: [
            {
              name: 'operatorHome',
              path: '',
              component: OperatorHomeView,
            },
            {
              name: 'operatorProductConfigurations',
              path: 'products/:productId/configurations',
              component: OperatorProductConfigurationView,
            },
            {
              name: 'operatorModule',
              path: 'products/:productId/configurations/:productConfigId/sites/:siteId/bills-of-process/:bopId/module/:moduleId/:stepId?',
              component: OperatorModuleView,
            },
          ],
        },
      ],
    },
  ],
})
let routerSpan: Span | undefined = undefined
router.beforeEach((to, from) => {
  if (routerSpan) {
    routerSpan.end()
  }

  const tracer = opentelemetry.trace.getTracer('vue-router', '1.0')
  routerSpan = tracer.startSpan(`route-${to.path}`)
})

export default router

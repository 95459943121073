<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import useUploads from '@/composables/useUploads'
import { computed, onUnmounted, ref, watch } from 'vue'
import { DateTime } from 'luxon'

const { t } = useI18n()
const { uploadsInProgress, uploadsDone, addUploadDoneListener } = useUploads()
onUnmounted(
  addUploadDoneListener((upload) => {
    setTimeout(() => {
      removeDoneEarlierThan.value = DateTime.now()
    }, 3_000)
  }),
)

const open = ref(false)
watch(
  () => uploadsInProgress.value.length,
  (v, p) => {
    if (v > p) {
      open.value = true
    }
  },
)

const removeDoneEarlierThan = ref(DateTime.now())
const visibleDone = computed(() =>
  uploadsDone.value.filter((u) => DateTime.fromISO(u.createdAt) >= removeDoneEarlierThan.value),
)
watch(visibleDone, (v) => {
  if (v.length == 0 && uploadsInProgress.value.length == 0) {
    open.value = false
  }
})
</script>

<template>
  <v-snackbar timeout="-1" :model-value="open" location="bottom right">
    <template #actions>
      <v-btn icon="close" @click="open = false" />
    </template>
    <v-list density="compact" theme="dark">
      <v-list-item v-for="u in uploadsInProgress" :key="u.id">
        <v-list-item-title>
          {{ u.fileName }}
          <v-progress-linear :max="1" :model-value="u.progress" stream />
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-for="u in visibleDone" :key="u.id">
        <template #append>
          <v-icon icon="done" color="success" />
        </template>
        <v-list-item-title>
          {{ u.name }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-snackbar>
</template>

<style scoped lang="scss"></style>

<script setup lang="ts" generic="MM">
import FormField from '@/components/input/FormField.vue'
import { Validation, ValidationArgs } from '@vuelidate/core'
import { computed, ref, watch } from 'vue'

const props = defineProps<{
  modelValue?: number
  label?: string
  validation?: Validation<ValidationArgs, MM>
}>()
const emit = defineEmits<{
  'update:modelValue': [value: number | undefined]
  blur: []
}>()

const privateVal = ref<string>('')
watch(
  () => props.modelValue,
  (v) => {
    privateVal.value = v ? `${v}` : ''
  },
  { immediate: true },
)
const publicVal = computed<number | undefined>(() => {
  if (isNaN(parseInt(privateVal.value, 10))) {
    return undefined
  }

  return parseInt(privateVal.value, 10)
})

function onBlur() {
  emit('update:modelValue', publicVal.value)
  props.validation?.$touch()
  emit('blur')
}
</script>

<template>
  <form-field :label="props.label" :validation="props.validation">
    <v-text-field v-model="privateVal" v-bind="$attrs" hide-details @blur="onBlur()">
      <template v-for="(_, slot) of $slots" #[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
    </v-text-field>
  </form-field>
</template>

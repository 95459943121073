<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { currentUserAndPermissions } from '@/app'
import { computed } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import { Organization, Query } from '@/generated/graphql'
import gql from 'graphql-tag'
import OrganizationInfo from '@/components/layout/OrganizationInfo.vue'
import { authentication } from '@/authentication'

const { t } = useI18n()

const user = computed(() => currentUserAndPermissions?.value?.user)
const fetchQuery = useQuery<Query>(gql`
  query getSwitchOrganizations {
    account {
      organizations {
        organization {
          id
          slug
          name
        }
      }
    }
  }
`)
const switchOrganizations = computed<Organization[]>(
  () => fetchQuery.result.value?.account.organizations.map((o) => o.organization) || [],
)
function logout() {
  authentication.value?.logout()
}
</script>

<template>
  <template v-if="currentUserAndPermissions">
    <organization-info />

    <v-menu min-width="200px" rounded>
      <template #activator="{ props }">
        <v-btn icon v-bind="props">
          <v-avatar variant="flat" color="secondary">
            <span class="text-h5">{{ user?.firstname.charAt(0) }}</span>
          </v-avatar>
        </v-btn>
      </template>
      <v-card>
        <v-card-text>
          <div class="mx-auto text-center">
            <h3>{{ user?.firstname }} {{ user?.lastname }}</h3>
            <p class="text-caption mt-1">
              {{ user?.email }}
            </p>

            <v-divider class="my-3"></v-divider>
            <v-menu rounded location="start">
              <template #activator="{ props }">
                <v-btn v-bind="props" rounded variant="text">
                  {{ t('component.accountMenu.switchOrganization') }}
                </v-btn>
              </template>

              <v-card>
                <v-card-text>
                  <div class="mx-auto text-center">
                    <h3>{{ t('entity.organization.singular') }}</h3>
                    <template v-for="org in switchOrganizations" :key="org.id">
                      <v-divider class="my-3"></v-divider>
                      <router-link
                        :to="{ name: 'products', params: { organizationSlug: org.slug } }"
                      >
                        <v-btn rounded variant="text">{{ org.name }}</v-btn>
                      </router-link>
                    </template>
                  </div>
                </v-card-text>
              </v-card>
            </v-menu>

            <v-divider class="my-3"></v-divider>
            <v-btn rounded variant="text" @click="logout()">
              {{ t('component.accountMenu.logOut') }}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-menu>
  </template>
</template>

<style scoped lang="scss"></style>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'

const props = defineProps<{
  disableReason?: string
}>()
defineEmits<{
  click: []
}>()

const { t } = useI18n()

const disabled = computed(() => !!props.disableReason)
</script>

<template>
  <v-btn
    density="compact"
    variant="text"
    icon="edit"
    :color="disabled ? `grey` : undefined"
    :title="disabled ? props.disableReason : t('component.editIcon.title')"
    :style="disabled ? 'cursor: not-allowed' : undefined"
    @click="disabled ? undefined : $emit('click')"
  />
</template>

<style scoped lang="scss"></style>

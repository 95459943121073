<script setup lang="ts">
import { useRoute } from 'vue-router'
import { useQuery } from '@vue/apollo-composable'
import { ModulePrintViewQuery, ModulePrintViewQueryVariables } from '@/generated/graphql'
import gql from 'graphql-tag'
import { computed, unref, watch } from 'vue'
import StepsTreePrint from '@/components/step/StepsTreePrint.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'

type PrintViewModule = ModulePrintViewQuery['product']['module']
export type PrintViewStepNode = ModulePrintViewQuery['product']['module']['nodes'][0]

const route = useRoute()
const stepImageDimensions = 1000
const toolImageDimensions = 1000
const moduleId = computed(() => route.params.moduleId as string)
const fetchQuery = useQuery<ModulePrintViewQuery, ModulePrintViewQueryVariables>(
  gql`
    query ModulePrintView($id: ID!, $maxStepImageDimensions: Int!, $maxToolImageDimensions: Int!) {
      product {
        module(id: $id) {
          id
          name
          description
          nodes {
            id
            parentId
            step {
              id
              name
              stepType {
                id
                name
              }
              instructionText
              imageUploadIds
              imageUploads {
                id
                imageUrl(
                  options: { width: $maxStepImageDimensions, height: $maxStepImageDimensions }
                )
              }
              tools {
                id
                name
                description
                imageUploads {
                  imageUrl(
                    options: { width: $maxToolImageDimensions, height: $maxToolImageDimensions }
                  )
                }
              }
            }
          }
        }
      }
    }
  `,
  () => ({
    id: unref(moduleId),
    maxStepImageDimensions: stepImageDimensions,
    maxToolImageDimensions: toolImageDimensions,
  }),
  () => ({
    enabled: !!moduleId.value,
  }),
)
const module = computed<PrintViewModule | undefined>(() => fetchQuery.result.value?.product.module)
const stepNodes = computed<PrintViewStepNode[]>(() => module.value?.nodes || [])

watch(stepNodes, (v) => {
  if (v.length == 0) {
    return
  }

  setTimeout(() => {
    window.print()
  }, 1000)
})
</script>

<template>
  <breadcrumb />
  <img :src="logo" class="first-page-logo" />
  <img :src="logo" class="follow-page-logo" />
  <h1>{{ module?.name }}</h1>
  <steps-tree-print :nodes="stepNodes" :parent-id="undefined" />
</template>

<style scoped lang="scss">
.first-page-logo,
.follow-page-logo {
  display: none;
}
@media print {
  .first-page-logo {
    display: block;
    position: absolute;
    top: 2.7em;
    right: 0.8em;
    width: 150px;
  }
  .follow-page-logo {
    display: block;
    position: fixed;
    bottom: 0.25em;
    right: 0.25em;
    width: 75px;
  }
}
</style>

<script setup lang="ts" generic="MM">
import useVuelidate from '@vuelidate/core'
import { useI18n } from 'vue-i18n'
import TextField from '@/components/input/TextField.vue'
import { helpers, required } from '@vuelidate/validators'
import { computed, ComputedRef, ref, watch } from 'vue'
import { debounce } from 'lodash'
import { useQuery } from '@vue/apollo-composable'
import { Query } from '@/generated/graphql'
import gql from 'graphql-tag'

const props = defineProps<{
  modelValue?: string
  label: string
  required?: boolean
  currentOrgId?: string
}>()
const emit = defineEmits<{
  'update:modelValue': [value: string]
  blur: []
}>()

const { t } = useI18n()

const model = computed(() => props.modelValue)
const debouncedSlugSearch = ref<string>()
const debouncer = debounce(() => {
  debouncedSlugSearch.value = model.value
}, 500)
watch(model, debouncer)
const fetchQuery = useQuery<Query, { slug?: string; orgId?: string }>(
  gql`
    query isOrgSlugTaken($slug: String!, $orgId: ID) {
      account {
        isOrganizationSlugTaken(slug: $slug, exceptOrganization: $orgId)
      }
    }
  `,
  () => ({ slug: debouncedSlugSearch.value || '', orgId: props.currentOrgId }),
  () => ({ enabled: (debouncedSlugSearch.value?.length || 0) > 2 }),
)
const isTaken = computed<boolean>(
  () => fetchQuery.result.value?.account.isOrganizationSlugTaken || false,
)

const validation = useVuelidate<ComputedRef<string | undefined>>(
  {
    required,
    pattern: helpers.withMessage(
      t('validation.orgSlug.pattern'),
      helpers.regex(/^[a-z0-9-]{3,50}$/),
    ),
    taken: helpers.withMessage(t('validation.orgSlug.taken'), () => !isTaken.value),
  },
  model,
)

function onBlur() {
  validation.value.$touch()
  emit('blur')
}
</script>

<template>
  <text-field
    :label="label"
    :model-value="props.modelValue"
    :required="props.required"
    :validation="validation"
    @update:model-value="(v: string) => emit('update:modelValue', v)"
  />
</template>

<style scoped lang="scss"></style>

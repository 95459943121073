<script setup lang="ts" generic="T, MM">
import FormField from '@/components/input/FormField.vue'
import { Validation, ValidationArgs } from '@vuelidate/core'

export type SelectOption<T> = {
  title: string
  value: T
}

const props = defineProps<{
  label?: string
  validation?: Validation<ValidationArgs, MM>
}>()
</script>

<template>
  <form-field :label="props.label" :validation="props.validation">
    <v-select hide-details v-bind="$attrs" @blur="props.validation?.$touch">
      <template v-for="(_, slot) of $slots" #[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
    </v-select>
  </form-field>
</template>

<script setup lang="ts">
import { CompleteProductionBatchTreeNode } from '@/components/productionBatch/CompleteProductionBatch.vue'

const props = defineProps<{
  node: CompleteProductionBatchTreeNode
}>()
</script>

<template>
  <v-list-item v-if="props.node.invalidCheckConfigs.length > 0" density="compact">
    {{ node.numbering }}: {{ node.step.name }}
  </v-list-item>

  <invalid-step-tree-node v-for="c in props.node.children" :key="c.step.id" :node="c" />
</template>

<style scoped lang="scss"></style>

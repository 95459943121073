<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import {
  CreateProductionBatchCommand,
  CreateProductionBatchMutation,
  CreateProductionBatchMutationVariables,
} from '@/generated/graphql'
import { currentUserAndPermissions, KeysOfType } from '@/app'
import { reactive, ref, watch } from 'vue'
import useVuelidate from '@vuelidate/core'
import { minLength, required } from '@/validation'
import TextField from '@/components/input/TextField.vue'
import SerialNumberField from '@/components/input/SerialNumberField.vue'
import EditDialog from '@/components/dialogs/EditDialog.vue'
import { useMutation } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import NotificationSaved from '@/components/notifications/NotificationSaved.vue'

const props = defineProps<{
  bopId: string
  moduleId: string
}>()
const emit = defineEmits<{
  created: [id: string]
}>()
const dialogModel = defineModel<boolean>({ required: true })

watch(
  () => [props.bopId, props.moduleId],
  () => {
    if (!props.bopId || !props.moduleId) {
      dialogModel.value = false
    }
  },
)

const { t } = useI18n()

type Model = Partial<CreateProductionBatchCommand>
const modelKeys: KeysOfType<keyof Model> = [
  'id',
  'bopId',
  'moduleId',
  'assignedUserId',
  'poNumber',
  'serialNumbers',
]
const model = reactive<Model>({})
const resetModel = () => {
  modelKeys.forEach((k) => (model[k] = undefined))
  model.bopId = props.bopId
  model.moduleId = props.moduleId
  model.assignedUserId = currentUserAndPermissions.value?.user?.id as string
  model.serialNumbers = ['']
}

const validation = useVuelidate<Model>(
  {
    poNumber: { required, length: minLength(1) },
    serialNumbers: { required, length: minLength(1) },
  },
  model,
)

watch(
  dialogModel,
  (v) => {
    if (v) {
      resetModel()
      validation.value.$reset()
    }
  },
  { immediate: true },
)

const addSerialNumber = () => {
  model.serialNumbers?.push('')
}
const deleteSerialNumber = (index: number) => {
  if (model.serialNumbers?.length == 1) {
    return
  }
  model.serialNumbers?.splice(index, 1)
}

const notifyCreated = ref(false)
const createMutation = useMutation<
  CreateProductionBatchMutation,
  CreateProductionBatchMutationVariables
>(
  gql`
    mutation CreateProductionBatch($command: CreateProductionBatchCommand!) {
      shopFloor {
        createProductionBatch(command: $command) {
          id
        }
      }
    }
  `,
  {
    refetchQueries: ['UserProductionBatchesData'],
  },
)
const create = () => {
  createMutation.mutate({ command: model as CreateProductionBatchCommand }).then((result) => {
    notifyCreated.value = true
    resetModel()
    dialogModel.value = false
    emit('created', result?.data?.shopFloor.createProductionBatch.id as string)
  })
}
</script>

<template>
  <edit-dialog v-model="dialogModel" create :validation="validation" @save="create">
    <text-field
      v-model="model.poNumber"
      :label="t('entity.productionBatch.field.poNumber')"
      required
      :validation="validation.poNumber"
    />

    <h3>
      {{ t('entity.productionBatch.field.serialNumbers') }}
      <v-btn
        density="compact"
        variant="flat"
        class="float-right"
        icon="add"
        @click="addSerialNumber"
      />
    </h3>
    <serial-number-field
      v-for="(s, i) in model.serialNumbers"
      :key="i"
      v-model="model.serialNumbers!![i]"
      @delete="deleteSerialNumber(i)"
    />
  </edit-dialog>

  <notification-saved v-model="notifyCreated" />
</template>

<style scoped lang="scss"></style>

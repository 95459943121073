<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { CheckDataQuery, CheckDataQueryVariables, CheckType } from '@/generated/graphql'
import { computed } from 'vue'
import useCurrentProductionBatch from '@/components/operator/currentProductionBatch'
import ConfirmationCheck from '@/components/check/ConfirmationCheck.vue'
import StringCheck from '@/components/check/StringCheck.vue'
import NumericCheck from '@/components/check/NumericCheck.vue'

const props = defineProps<{
  stepId: string
}>()

type LocalCheckConfiguration = CheckDataQuery['product']['checkConfigurations'][0]
type LocalCheck = LocalCheckConfiguration['unreleasedChecks'][0]
type LocalConfirmCheck = LocalCheck & { __typename: 'ConfirmCheck' }
type LocalNumericCheck = LocalCheck & { __typename: 'NumericCheck' }
type LocalStringCheck = LocalCheck & { __typename: 'StringCheck' }

const { t } = useI18n()
const { currentProductionBatch } = useCurrentProductionBatch()

const fetchQuery = useQuery<CheckDataQuery, CheckDataQueryVariables>(
  gql`
    query CheckData($stepId: ID!) {
      product {
        checkConfigurations(stepId: $stepId) {
          id
          type
          isRequired
          description
          lowerLimit
          upperLimit
          unreleasedChecks {
            ... on CheckI {
              id
              poNumber
              serialNumber
            }
            ... on ConfirmCheck {
              confirmed
            }
            ... on StringCheck {
              text
            }
            ... on NumericCheck {
              milliQuantity
            }
          }
        }
      }
    }
  `,
  () => ({
    stepId: props.stepId,
  }),
)
const configs = computed<LocalCheckConfiguration[]>(
  () => fetchQuery.result.value?.product.checkConfigurations || [],
)

const value = (config: LocalCheckConfiguration, serialNumber: string) =>
  config.unreleasedChecks.find(
    (c) => c.poNumber == currentProductionBatch.value?.poNumber && c.serialNumber == serialNumber,
  )
const confirmationValue = (config: LocalCheckConfiguration, serialNumber: string) =>
  (value(config, serialNumber) as LocalConfirmCheck | undefined)?.confirmed
const stringValue = (config: LocalCheckConfiguration, serialNumber: string) =>
  (value(config, serialNumber) as LocalStringCheck | undefined)?.text
const numericValue = (config: LocalCheckConfiguration, serialNumber: string) =>
  (value(config, serialNumber) as LocalNumericCheck | undefined)?.milliQuantity
</script>

<template>
  <div v-if="currentProductionBatch" v-bind="$attrs">
    <h2>Checks</h2>
    <v-row>
      <template v-for="config in configs" :key="config.id">
        <v-col cols="12" md="6" lg="4" xl="3">
          <v-card>
            <v-card-text>
              <p>{{ config.description }}</p>

              <template v-for="s in currentProductionBatch.serialNumbers" :key="s">
                <confirmation-check
                  v-if="config.type == CheckType.Confirmation"
                  :required="config.isRequired"
                  :value="confirmationValue(config, s)"
                  :check-configuration-id="config.id"
                  :po-number="currentProductionBatch.poNumber"
                  :serial-number="s"
                  density="compact"
                />
                <string-check
                  v-if="config.type == CheckType.String"
                  :required="config.isRequired"
                  :value="stringValue(config, s)"
                  :check-configuration-id="config.id"
                  :po-number="currentProductionBatch.poNumber"
                  :serial-number="s"
                  density="compact"
                />
                <numeric-check
                  v-if="config.type == CheckType.Numeric"
                  :required="config.isRequired"
                  :value="numericValue(config, s)"
                  :check-configuration-id="config.id"
                  :po-number="currentProductionBatch.poNumber"
                  :serial-number="s"
                  density="compact"
                  :lower-limit="config.lowerLimit"
                  :upper-limit="config.upperLimit"
                />
              </template>
            </v-card-text>
          </v-card>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<style scoped lang="scss"></style>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const props = defineProps<{
  modelValue: boolean
}>()
defineEmits<{
  'update:modelValue': [value: boolean]
}>()

const { t } = useI18n()
</script>

<template>
  <v-snackbar
    :timeout="2000"
    :model-value="props.modelValue"
    @update:model-value="(v) => $emit('update:modelValue', v)"
  >
    {{ t('notification.saved') }}
  </v-snackbar>
</template>

<style scoped lang="scss"></style>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import {
  DownloadMbomDialogDataQuery,
  DownloadMbomDialogDataQueryVariables,
} from '@/generated/graphql'
import { computed } from 'vue'
import { downloadMbom } from '@/components/mbom/mbom'
import DownloadModuleMbomButton from '@/components/mbom/DownloadModuleMbomButton.vue'

const props = defineProps<{
  modelValue: boolean
  bopId: string
  preSelectedModuleIds?: string[]
}>()
const emit = defineEmits<{
  'update:modelValue': [value: boolean]
}>()

const { t } = useI18n()

const fetchQuery = useQuery<DownloadMbomDialogDataQuery, DownloadMbomDialogDataQueryVariables>(
  gql`
    query DownloadMbomDialogData($bopId: ID!) {
      product {
        bop(id: $bopId) {
          nodes {
            id
            module {
              id
              name
              abbreviation
              description
            }
          }
        }
      }
    }
  `,
  () => ({
    bopId: props.bopId,
  }),
)
const modules = computed(
  () => fetchQuery.result.value?.product.bop.nodes.map((n) => n.module) || [],
)

function download() {
  downloadMbom(
    props.bopId,
    modules.value.map((m) => m.id),
    undefined,
  )
}
</script>

<template>
  <v-dialog
    max-width="650"
    :model-value="props.modelValue"
    @update:model-value="(v) => $emit('update:modelValue', v)"
  >
    <v-card>
      <v-card-title>{{ t('component.downloadMbomDialog.title') }}</v-card-title>
      <v-card-text>
        <v-list-item class="text-center">
          <v-btn variant="elevated" color="primary" class="download-button" @click="download()">
            {{ t('component.downloadMbomDialog.downloadBopMbom') }}
          </v-btn>
        </v-list-item>
        <v-list class="text-center">
          <v-list-item v-for="m in modules" :key="m.id">
            <download-module-mbom-button
              :bop-id="props.bopId"
              :module="m"
              variant="outlined"
              class="download-button"
            />
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-btn variant="elevated" color="primary" @click="$emit('update:modelValue', false)">
          {{ t('component.downloadMbomDialog.closeButton') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">
.download-button {
  min-width: 35em;
  overflow: hidden;
}
</style>

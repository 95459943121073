<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import useAsyncDialog from '@/components/dialogs/useAsyncDialog'
import EbomBulkAllocatePartTree from '@/components/ebom/EbomBulkAllocatePartTree.vue'
import useIdSelection from '@/useIdSelection'
import EbomBulkAllocateStepTree from '@/components/ebom/EbomBulkAllocateStepTree.vue'

const props = defineProps<{
  bopId: string
  ebomId: string
}>()

const { t } = useI18n()
const { openAsyncDialog, asyncDialogResolve, asyncDialogIsOpen } =
  useAsyncDialog<() => Promise<void>>()
const { resetSelection: resetSelectedParts, selectedIds } = useIdSelection(
  'ebom-bulk-allocation-parts',
)
const { resetSelection: resetOpenAssemblies } = useIdSelection(
  'ebom-bulk-allocation-open-assemblies',
)

const open = async () => {
  resetSelectedParts()
  resetOpenAssemblies()
  return openAsyncDialog()
}

defineExpose({ open })
</script>

<template>
  <v-dialog
    v-if="asyncDialogResolve"
    v-model="asyncDialogIsOpen"
    width="100%"
    max-width="1600"
    height="100%"
    v-bind="$attrs"
  >
    <v-card height="100%">
      <v-card-title>
        {{ t('component.ebomBulkAllocateDialog.title') }}
        <v-btn
          density="compact"
          variant="text"
          icon="close"
          class="float-right"
          @click="asyncDialogResolve()"
        />
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col class="text-grey" style="font-size: 0.9em">
            {{ t('component.ebomBulkAllocateDialog.text') }}
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6" class="allocationList" @click.right.exact.prevent="resetSelectedParts()">
            <ebom-bulk-allocate-part-tree :ebom-id="props.ebomId" />
          </v-col>
          <v-col class="allocationList">
            <ebom-bulk-allocate-step-tree :bop-id="props.bopId" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">
.allocationList {
  height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { CheckType } from '@/generated/graphql'
import SelectField from '@/components/input/SelectField.vue'

const model = defineModel<CheckType | undefined>({ required: true })

const { t } = useI18n()

const items = Object.values(CheckType).map((ct) => ({
  title: t(`entity.checkConfiguration.type.${ct}`),
  value: ct,
}))
</script>

<template>
  <select-field v-model="model" :items="items" />
</template>

<style scoped lang="scss"></style>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { EbomBulkAllocateStepNode } from '@/components/ebom/EbomBulkAllocateStepTree.vue'
import useIdSelection from '@/useIdSelection'
import { useMutation } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import EbomBulkAllocateStepTreeNode from '@/components/ebom/EbomBulkAllocateStepTreeNode.vue'
import {
  EbomBulkAllocateStepTreeNodeAllocateMutation,
  EbomBulkAllocateStepTreeNodeAllocateMutationVariables,
} from '@/generated/graphql'

const props = defineProps<{
  node: EbomBulkAllocateStepNode
}>()

const { selectedIds, resetSelection } = useIdSelection('ebom-bulk-allocation-parts')

const isOpen = ref(false)
const hasChildren = computed(() => props.node.children.length > 0)
const step = computed(() => props.node.step)

const dropContainer = ref<HTMLElement>()
const variant = ref<'text' | 'tonal'>('text')
const dragEnter = (e: DragEvent) => {
  variant.value = 'tonal'
}
const dragLeave = (e: DragEvent) => {
  variant.value = 'text'
}

const mutation = useMutation<
  EbomBulkAllocateStepTreeNodeAllocateMutation,
  EbomBulkAllocateStepTreeNodeAllocateMutationVariables
>(
  gql`
    mutation EbomBulkAllocateStepTreeNodeAllocate($command: BulkAllocateEbomPartsToStepCommand!) {
      article {
        bulkAllocateEbomPartsToStep(command: $command)
      }
    }
  `,
  { refetchQueries: ['EbomBulkAllocatePartTreeData'] },
)
const allocateSelection = () => {
  mutation
    .mutate({
      command: {
        stepId: step.value.id,
        ebomPartIds: selectedIds.value,
      },
    })
    .then(() => {
      resetSelection()
    })
    .finally(() => {
      variant.value = 'text'
    })
}
</script>

<template>
  <v-list-item
    v-bind="$attrs"
    :variant="variant"
    density="compact"
    rounded
    style="user-select: none"
  >
    <template #prepend>
      <v-icon
        density="compact"
        :icon="hasChildren ? (isOpen ? 'folder_open' : 'folder') : 'handyman'"
        @click.stop="isOpen = !isOpen"
      />
    </template>
    <v-list-item-title>
      <div
        ref="dropContainer"
        class="dropContainer"
        @drop.prevent="allocateSelection"
        @dragover.prevent
        @dragenter.prevent="dragEnter"
        @dragleave.prevent="dragLeave"
      />
      {{ step.name }}
    </v-list-item-title>
    <template #append>
      <v-progress-circular v-if="mutation.loading.value" indeterminate />
    </template>
  </v-list-item>

  <div v-if="hasChildren && isOpen" class="children">
    <ebom-bulk-allocate-step-tree-node v-for="n in props.node.children" :key="n.id" :node="n" />
  </div>
</template>

<style scoped lang="scss">
.children {
  margin-left: 1.5em;
}
.dropContainer {
  position: absolute;
  width: 70%;
  height: 100%;
  z-index: 100;
}
</style>

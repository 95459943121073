import { Locale } from '@/translations/main'

export const enLocale: Locale = {
  button: {
    save: 'Save',
    ok: 'Ok',
    cancel: 'Cancel',
    close: 'Close',
    delete: 'Delete',
  },
  notification: {
    failed: 'Something went wrong',
    saved: 'Saved',
    deleted: 'Deleted',
    duplicated: 'Duplicated',
    deleteTitle: 'Delete {name}?',
    deleteWarningTitle: 'Be careful',
    deleteHindranceText: 'I understand that deleting this item is dangerous and irreversible',
  },
  navigationDrawer: {
    administration: 'Administration',
    organization: 'Organization',
    currentUploads: 'Current uploads',
  },
  view: {
    home: {
      welcome: 'Welcome!',
      selectOrganization: 'Select an organization to get started',
      buttonProcessManagement: 'Process Editor',
      buttonOperator: 'Operations',
    },
    organization: {
      bops: {
        cannotCreateMessage:
          'No new version can be created as long as the last version is not released.',
        releaseTooltip: 'Release this version',
      },
      bopProcess: {
        downloadMbomButton: 'Download MBOM',
        menuView: 'View',
        menuAddChild: 'Add next',
        menuAddParent: 'Link to previous',
        menuUpdateNode: 'Update',
        menuDeleteParent: 'Unlink from previous',
        menuDeleteNode: 'Delete',
        menuMoveAbove: 'Move above',
      },
      module: {
        downloadMbomButton: 'Download MBOM',
      },
      operatorProduct: {
        title: 'Products',
        titleBatchesInProgress: 'Work in progress',
        noData: 'No products found',
      },
      operatorProductConfiguration: {
        title: 'Product configurations',
        noData: 'No configurations found',
        buttonCreateProductionBatch: 'Create production batch',
        buttonViewModule: 'View module',
      },
    },
  },
  component: {
    accountMenu: {
      switchOrganization: 'Switch organization',
      logOut: 'Log out',
    },
    apiErrorSnackbar: {
      error: {
        unknown: {
          title: 'Something went wrong',
          message:
            'An unknown error has occurred. You can retry if you want. These error are reported to us so we are aware and we will take action if needed. If this keeps happening or it is urgent feel free to reach out to our support desk.',
        },
        apiUnreachable: {
          title: 'API unreachable',
          message:
            "We're having trouble reaching our API. Sit tight, this probably won't take long.",
        },
        privileges: {
          title: 'Missing privileges',
          message:
            "You don't have the right privileges to perform this action. That's on us, we shouldn't show you things that you do not have access to. If you do need access, ask you organization admin to give them to you.",
        },
      },
      actionMoreDetails: 'Details',
      actionDismiss: 'Dismiss',
      detailsTitle: 'Error details',
      detailsClose: 'Close',
      detailsCopy: 'Copy',
    },
    bopNavigationDrawer: {
      headerAssociatedEboms: 'Associated EBOMs',
    },
    bopReleaseConfirmation: {
      title: 'Release BOP',
      message:
        "You're about to release the BOP version {bopVersion} for product {productName} with product configuration {productConfigurationName} at site {siteName}. This is an irreversible process that makes this release read-only and will have downstream effects like the creation of article numbers for modules. Are you sure?",
      confirmation: 'I understand this is an irreversible process and understand the consequences',
      cancelButton: 'Cancel',
      releaseButton: 'Release BOP',
    },
    bulkAllocationSuggestionActions: {
      createTitle: 'Create allocation suggestions',
      createButton: 'Create',
      createConfirmation: 'Allocation suggestions created',
      acceptTitle: 'Accept {count} matching allocation suggestion(s)',
      acceptButton: 'Accept',
      acceptConfirmation: 'Matching allocation suggestions accepted',
      rejectTitle: 'Reject {count} undecided allocations suggestion(s)',
      rejectButton: 'Reject',
      rejectConfirmation: 'Undecided suggestions rejected',
    },
    confirmDialog: {
      ok: 'OK',
      cancel: 'Cancel',
    },
    completeProductionBatch: {
      title: 'Complete production batch',
      text: 'Are you sure you want to complete the production batch for PO number {poNumber} with serial number(s) {serialNumbers}?',
      incompleteChecks: 'There are incomplete checks in the following steps:',
      buttonActivator: 'Complete',
      buttonCancel: 'Cancel',
      buttonSave: 'Complete',
    },
    createStep: {
      title: 'Create step',
    },
    csvEbomLoader: {
      headerEbom: 'EBOM',
      headerColumns: 'Columns',
      instructionColumns: 'Please provide the header names for the following columns',
      ebomName: "What's the name of this EBOM?",
      rootArticleNumber: 'What is the root article number',
      columnArticleNumber: 'Article number ',
      columnArticleName: 'Article name',
      columnQuantity: 'Quantity',
      columnArticleRevision: 'Revision',
      columnUnit: 'Unit',
      columnParentArticleNumber: 'Parent article number',
      buttonProcess: 'Process CSV',
    },
    dataTable: {
      loading: 'Loading...',
      noData: 'No data',
      page: `{'{'}0{'}'}-{'{'}1{'}'} of {'{'}2{'}'}`,
      itemsPerPage: 'Items per page',
      moreData:
        "Showing the first {count} results. Adjust the filters if you can't find what you're looking for.",
    },
    deleteDialog: {
      title: 'Delete {name}?',
      areYouSure: 'Are you sure you want to delete {name}?',
      delete: 'Delete',
      cancel: 'Cancel',
      warningTitle: 'Be careful',
      hindranceText: 'I understand that deleting this item is dangerous and irreversible',
      deleteText: 'Are you sure you want to delete {name}?',
    },
    deleteIcon: {
      title: 'Delete',
    },
    downloadMbomDialog: {
      title: 'Download MBOM',
      closeButton: 'Close',
      downloadBopMbom: 'Download BOP MBOM',
    },
    downloadModuleMbomButton: {
      labelShort: 'Download MBOM',
      labelLong: 'Download MBOM for {name}',
    },
    ebomBulkAllocateDialog: {
      title: 'Bulk allocate EBOM parts',
      text: 'Select parts and allocate them to a step by dragging them there. Click to select or deselect one part; Click one part and Shift+Click another part to select all intermediate parts (within one assembly); Ctrl+Click / Cmd+Click an assembly to select all underlying parts; Right-Click to deselect all selected parts.',
    },
    ebomDiffDialog: {
      title: 'Differences between {oldEbomName} and {newEbomName}',
      selectLabel: 'Select EBOM to compare',
      diffButton: 'Show differences',
      closeButton: 'Close',
    },
    ebomDiffSameParts: {
      title: '{count} allocated part(s) overlapping',
      swapExplanation:
        'These EBOM parts are exactly the same in EBOM {oldEbomName} and {newEbomName}. You can now easily swap the allocations from the EBOM Parts in {oldEbomName} to EBOM Parts in {newEbomName}.',
      swapButton: 'Swap',
      notificationSwapped:
        'The overlapping EBOM part allocations have been swapped to the new EBOM parts',
    },
    ebomDiffTree: {
      noDifferences: 'No differences found',
    },
    ebomLoader: {
      buttonActivate: 'Load EBOM',
      buttonCancel: 'Cancel',
      titlePickEbom: 'Pick EBOM',
      titleLoadEbom: 'Load EBOM',
      titlePromoteEbomParts: 'Promote parts',
      titleEbomPartAllocationSuggestions: 'Part allocation suggestions',
      titleDone: 'Done',
      loadEbomFromCsv: 'From CSV',
      doneHeader: 'Done!',
      doneMessage: 'The EBOM is processed.',
      doneButton: 'Close',
    },
    ebomPartAllocationSuggestionsLoader: {
      header: 'Suggest part allocations',
      message:
        'If you previously allocated EBOM parts, we can make suggestions on where to put the current EBOM Parts. You can always trigger these suggestions later in the EBOM view. ',
      skipButton: 'Skip',
      createSuggestionsButton: 'Suggest allocations',
      successMessage: 'Created {count} suggestions',
    },
    ebomPartDetails: {
      buttonPromoteEbomPart: 'Promote',
      quantityAllocatedToSteps: 'Quantity allocated to steps',
      headerStepAllocations: 'Step Allocations',
      tableHeaderModule: 'Module',
      tableHeaderStep: 'Step',
      tableHeaderQuantity: 'Quantity',
      promoteDialogTitle: 'Promote to article?',
      promoteDialogContent: 'Are you sure you want to promote this to an article?',
      labelSuggestion: 'Suggestion',
    },
    ebomPartTree: {
      title: 'Parts',
      filterOpenSuggestionsHint: 'Only show parts with open suggestions',
      filterAllocationDisparityHint: 'Only show parts with an allocation disparity',
    },
    editDialog: {
      save: 'Save',
      cancel: 'Cancel',
    },
    editIcon: {
      title: 'Edit',
    },
    iconArchived: {
      archived: 'Archived',
      notArchived: 'Not archived',
    },
    iconConsumable: {
      title: 'Consumable',
    },
    iconEbom: {
      title: 'EBOM',
    },
    iconManual: {
      title: 'Manual',
    },
    iconPart: {
      title: 'Part',
    },
    iconProductionBatch: {
      title: 'Production batch',
    },
    itemTable: {
      actions: 'Actions',
      noData: 'No data',
    },
    operatorCurrentProductionBatch: {
      title: 'Currently working on PO number {poNumber}',
      deselectButton: 'Deselect',
    },
    operatorModuleStepper: {
      finalizeTitle: 'Finalize',
      doneTitle: 'Done',
      previousButton: 'Previous',
      nextButton: 'Next',
    },
    operatorProductionBatchDrawer: {
      titleBatchesInProgress: 'Your work in progress',
    },
    operatorStepTree: {
      title: 'Steps',
    },
    pageHeader: {
      createButton: 'Create',
    },
    articleAllocationList: {
      source: 'Source',
      sourceEbom: 'EBOM',
      sourceManual: 'Manual',
    },
    promoteEbomParts: {
      title: 'EBOM loaded',
      instructions:
        "Below are the parts in the EBOM that are unknown Articles. You can now choose to promote them to an Article so you can later use them. A good guideline whether to promote an EBOM part is whether you intend it be part of the MBOM. Once you're finished, click DONE",
      buttonSelectNonAssemblies: 'Select non-assemblies',
      buttonPromote: 'Promote to article',
      buttonDone: 'Done',
      successMessage: 'Parts have been promoted to articles',
    },
    stepPickerDialog: {
      title: 'Choose a step',
      select: 'Select this step',
    },
    stepTree: {
      title: 'Steps in module',
    },
    stepToolbar: {
      buttonDelete: 'Delete step',
    },
    uploadButton: {
      instructions: 'Select files to upload, drag & drop them, or paste them',
    },
    uploadPicker: {
      title: 'Pick a file',
      search: 'Search',
      maybeMoreItems: 'Only showing a limited number of items, refine your search to discover more',
      selectButton: 'Select',
      closeButton: 'Close',
      newUploadButton: 'Upload new file',
      tableItemsPerPage: 'Items per page',
      tableNoData: 'No uploads found',
    },
    upsertDialog: {
      title: {
        create: 'Create',
        update: 'Update,',
      },
    },
    userProductionBatches: {
      title: 'Production batches in progress',
      noBatches: 'No production batches found',
    },
    userPermissions: {
      title: 'Permissions',
      organizationPermissions: 'Organization permissions',
      addOrganization: 'Add organization',
      field: {
        userPermissions: 'User permissions',
      },
    },
  },
  validation: {
    abbreviation: {
      regex: 'An abbreviation should be between 1 and 5 characters',
      unique: 'This abbreviation is already taken',
    },
    filename: {
      regex:
        'A filename can only contain letters, numbers, and these characters: -, _, +, .; it also needs to end in an extension of letters, numbers, and `_`',
    },
    isChecked: {
      notChecked: 'This should be checked',
    },
    milliQuantity: {
      min: 'Value should be at least {min}',
      max: 'Value should be at most {max}',
    },
    normalName: {
      regex: 'A name should be between 1 and 150 characters',
    },
    normalDescription: {
      regex: 'A description should be between 0 and 255 characters',
    },
    orgSlug: {
      pattern:
        'The slug must be between 3 and 50 characters and can only contain: lowercase letters, numbers, -',
      taken: 'This slug is already taken',
    },
    unique: {
      unique: 'Should be unique and this is already taken',
    },
    userEmail: {
      taken: 'This email is already taken by another user',
    },
  },
  entity: {
    article: {
      singular: 'Article',
      plural: 'Articles',
      field: {
        articleNumber: 'Article Number',
        revision: 'Revision',
        name: 'Name',
        unit: 'Unit',
        quantity: 'Quantity',
        type: 'Type',
        source: 'Source',
      },
      type: {
        PART: 'Part',
        CONSUMABLE: 'Consumable',
      },
      unit: {
        full: {
          GRAM: 'Gram',
          KILOGRAM: 'Kilogram',
          LITER: 'Liter',
          MILLILITER: 'Milliliter',
          METER: 'Meter',
          MILLIMETER: 'Millimeter',
          PIECES: 'Pieces',
        },
        abbreviated: {
          GRAM: 'GR',
          KILOGRAM: 'KG',
          LITER: 'L',
          MILLILITER: 'ML',
          METER: 'M',
          MILLIMETER: 'MM',
          PIECES: 'PCS',
        },
      },
      validation: {
        articleAndRevisionTaken: 'An article already exists with this article number and revision',
      },
      disableEditBecausePart:
        'Parts cannot be edited because they originate from an EBOM or from the ERP',
      disableDeleteBecausePart:
        'Parts cannot be deleted because they originate from an EBOM or from the ERP',
      disableEditBecauseReleased: 'Cannot edit this article because it is used in a released BOP',
      disableDeleteBecauseReleased:
        'Cannot delete this article because it is used in a released BOP',
    },
    bop: {
      singular: 'Bill of Process',
      plural: 'Bills of Process',
      deleteWarning:
        'When you delete a Bill of Process, you will lose the process tree of this BOP, all Steps in that tree that are not part of other BOPs, reviews and their comments, etc. Make absolutely sure this is what you want to do.',
      field: {
        version: 'Version',
        versionStatus: 'Status',
      },
    },
    checkConfiguration: {
      singular: 'Check',
      plural: 'Checks',
      field: {
        type: 'Type',
        description: 'Description',
        lowerLimit: 'Lower limit',
        upperLimit: 'Upper limit',
        isRequired: 'Required',
      },
      type: {
        CONFIRMATION: 'Confirm',
        NUMERIC: 'Numeric value',
        STRING: 'Text value',
      },
    },
    ebom: {
      singular: 'EBOM',
      plural: 'EBOMs',
      deleteWarning:
        'When you delete this EBOM, all its EBOM Parts will be unallocated from the steps',
      field: {
        name: 'Name',
      },
    },
    ebomPart: {
      singular: 'EBOM Part',
      plural: 'EBOM Parts',
      isPromoted: 'Is promoted to Article',
      field: {
        articleNumber: 'Article number',
        articlePath: 'Path in EBOM',
        name: 'Name',
        quantity: 'Quantity',
        revision: 'Revision',
      },
    },
    module: {
      singular: 'Module',
      plural: 'Modules',
      deleteWarning:
        'When you delete a Module, you delete all underlying entities like Steps, their relations to Articles, etc. Make absolutely sure this is what you want to do.',
      field: {
        name: 'Name',
        abbreviation: 'Abbreviation',
        description: 'Description',
      },
    },
    organization: {
      singular: 'Organization',
      plural: 'Organizations',
      deleteWarning:
        'When you delete an organization, you delete ABSOLUTELY EVERYTHING belonging to it, like its Products, Product Configurations, Sites, BOPs, Checks, etc. This is IRREVERSIBLE and about the MOST DANGEROUS THING you can do on this platform. Make REALLY, ABSOLUTELY, UNEQUIVOCALLY, WITHOUT A DOUBT sure this is what you want to do.',
      field: {
        name: 'Name',
        slug: 'Slug',
      },
    },
    product: {
      singular: 'Product',
      plural: 'Products',
      deleteWarning:
        'When you delete a Product, you delete all underlying entities like Product Configurations, Bills of Process, Steps, etc. Make absolutely sure this is what you want to do.',
      field: {
        name: 'Name',
        abbreviation: 'Abbreviation',
        description: 'Description',
        image: 'Cover image',
      },
    },
    productConfiguration: {
      singular: 'Product Configuration',
      plural: 'Product Configurations',
      deleteWarning:
        'When you delete a product configuration, you delete all underlying entities like the bill of process. Make absolutely sure this is what you want to do.',
      field: {
        name: 'Name',
        abbreviation: 'Abbreviation',
        description: 'Description',
        image: 'Cover image',
      },
    },
    productionBatch: {
      singular: 'Production batch',
      plural: 'Production batches',
      field: {
        poNumber: 'PO number',
        serialNumbers: 'Serial numbers',
      },
    },
    site: {
      singular: 'Site',
      plural: 'Sites',
      deleteWarning:
        'When you delete a Site, you delete all associated entities like Product Configurations, Bills of Process, Steps, Tooling, etc. Make absolutely sure this is what you want to do.',
      field: {
        name: 'Name',
        abbreviation: 'Abbreviation',
        archived: 'Archived',
      },
    },
    stepType: {
      singular: 'Step Type',
      plural: 'Step Types',
      editWarning:
        'This Step Type is already used in a released BOP. If you change it, those changes will be propagated to all those BOPs. Make sure this is what you want to do. Alternatively, you can archive this Step Type and create a new one for future use.',
      disableDeleteBecause:
        'This Step Type is used in one or more released BOPs and cannot de deleted',
      deleteWarning:
        'When you delete a Step Type, you delete all associated entities like Steps and all their associated entities like Part Allocations, Check Configurations, etc. Make absolutely sure this is what you want to do.',
      field: {
        name: 'Name',
        operation: 'Type of operation',
        archived: 'Archived',
        released: 'Released',
      },
    },
    step: {
      singular: 'Step',
      plural: 'Steps',
      field: {
        name: 'Name',
        stepType: 'Type',
        durationInSeconds: 'Duration',
        instructionText: 'Instructions',
        images: 'Images',
        tools: 'Tools',
      },
    },
    tool: {
      singular: 'Tool',
      plural: 'Tools',
      editWarning:
        'This Tool is already used in a released BOP. If you change it, those changes will be propagated to all those BOPs. Make sure this is what you want to do. Alternatively, you can archive this Tool and create a new one for future use.',
      disableDeleteBecause: 'This Tool is used in one or more released BOPs and cannot de deleted',
      deleteWarning:
        'When you delete a Tool, you delete it from all the Steps it is used in (but not the steps themselves). Make absolutely sure this is what you want to do.',
      field: {
        name: 'Name',
        description: 'Description',
        site: 'Site',
        images: 'Images',
        archived: 'Archived',
        released: 'Released',
      },
    },
    upload: {
      singular: 'Upload',
      plural: 'Uploads',
      field: {
        name: 'Name',
        contentType: 'Content type',
        createdAt: 'Created at',
      },
    },
    user: {
      singular: 'User',
      plural: 'Users',
      field: {
        name: 'Name',
        firstname: 'Firstname',
        lastname: 'Lastname',
        email: 'Email',
      },
    },
  },
}

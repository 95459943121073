<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, onUnmounted, ref, watch } from 'vue'
import { MimeTypeFilter } from '@/app'
import useUploads from '@/composables/useUploads'
import { v4 } from 'uuid'

const props = defineProps<{
  mimeTypes: MimeTypeFilter[]
}>()
const emit = defineEmits<{
  mimeTypes: [value: MimeTypeFilter[]]
  newUploadIds: [value: string[]]
}>()

const { t } = useI18n()
const { addUploads } = useUploads()

const accept = computed(() => {
  return props.mimeTypes
    .flatMap((mt) => {
      switch (mt) {
        case MimeTypeFilter.TEXT_CSV:
          return ['.csv']
        case MimeTypeFilter.IMAGE_SVG:
          return ['.svg']
        case MimeTypeFilter.IMAGE_WEBP:
          return ['.webp']
        case MimeTypeFilter.IMAGE_JPEG:
          return ['.jpg', '.jpeg']
        case MimeTypeFilter.IMAGE_PNG:
          return ['.png']
        case MimeTypeFilter.ALL:
          return ['.*']
      }
    })
    .join(',')
})

const fileInputState = ref<File[]>([])
watch(fileInputState, (v) => {
  const idMap = v.reduce((map, file) => map.set(file, v4()), new Map<File, string>())
  addUploads(v, idMap)
  emit('newUploadIds', Array.from(idMap.values()))
})

const fileInput = ref<HTMLInputElement>()

const classDropContainer = 'upload-drop-container'
const classDropHover = 'upload-drop-hover'
const dropFile = (event: DragEvent) => {
  if (!event.dataTransfer) {
    return
  }

  fileInputState.value = [...event.dataTransfer.items]
    .filter((i) => i.kind == 'file')
    .map((i) => i.getAsFile() as File)

  Array.from(document.getElementsByClassName(classDropHover)).forEach((e) =>
    e.classList.remove(classDropHover),
  )
}
function dragEnter(e: DragEvent) {
  const target = e.target as HTMLElement
  if (!target.classList.contains(classDropContainer)) {
    return
  }

  target.classList.add(classDropHover)
}
function dragLeave(e: DragEvent) {
  const target = e.target as HTMLElement
  if (!target.classList.contains(classDropContainer)) {
    return
  }

  target.classList.remove(classDropHover)
}

const pasteListener = (event: ClipboardEvent) => {
  if (!event.clipboardData?.files) {
    return
  }

  fileInputState.value = [...event.clipboardData.files]
}
window.addEventListener('paste', pasteListener)
onUnmounted(() => window.removeEventListener('paste', pasteListener))
</script>

<template>
  <v-file-input
    ref="fileInput"
    v-model="fileInputState"
    hide-details
    multiple
    :accept="accept"
    style="display: none"
  />

  <div
    :class="classDropContainer"
    @drop.prevent="dropFile"
    @dragover.prevent
    @dragenter.prevent="dragEnter"
    @dragleave.prevent="dragLeave"
  >
    <v-btn variant="tonal" density="comfortable" icon="cloud_upload" @click="fileInput?.click()" />
    <div class="instructions">{{ t(`component.uploadButton.instructions`) }}</div>
  </div>
</template>

<style scoped lang="scss">
.upload-drop-container {
  margin: 0 auto;
  padding: 0.5em;
  border: 2px dashed #ccc;
  text-align: center;
}
.upload-drop-container.upload-drop-hover {
  border: 2px dashed #888;
}
.instructions {
  font-size: 0.7em;
  color: #999;
  margin-top: 0.3em;
}
</style>

<script setup lang="ts">
import AccountMenu from '@/components/layout/AccountMenu.vue'
import { authzIsOrgOperator, authzIsOrgProductManagement } from '@/app'
import { useI18n } from 'vue-i18n'

defineEmits<{
  (e: 'toggleDrawer'): void
}>()

const { t } = useI18n()
</script>

<template>
  <v-app-bar class="main-app-bar">
    <template #title>
      <router-link class="main-title" :to="{ name: 'home' }">
        <v-img width="150" src="/img/logo.svg" title="Baseboard" />
      </router-link>
    </template>
    <template #prepend>
      <v-app-bar-nav-icon @click="$emit('toggleDrawer')" />
    </template>
    <template #append>
      <v-btn
        v-if="authzIsOrgProductManagement"
        icon="account_tree"
        :title="t('view.home.buttonProcessManagement')"
        @click="$router.push({ name: 'products' })"
      />
      <v-btn
        v-if="authzIsOrgOperator"
        icon="warehouse"
        class="mr-5"
        :title="t('view.home.buttonOperator')"
        @click="$router.push({ name: 'operatorHome' })"
      />

      <account-menu />
    </template>
  </v-app-bar>
</template>

<style lang="scss">
@media print {
  .v-app-bar {
    display: none !important;
  }
}

.v-app-bar.v-toolbar.main-app-bar {
  background: rgb(var(--v-theme-primary));
  color: darken(#fff, 10%);

  .v-app-bar-nav-icon,
  .main-title,
  a {
    color: darken(#fff, 10%);
  }

  a {
    margin-left: 0.5em;
  }

  a:first-of-type {
    margin-left: 0;
  }

  a:hover {
    text-decoration: none;
  }
}
</style>

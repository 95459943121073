<script setup lang="ts" generic="MM">
import { Step, StepPickerDataQuery, StepPickerDataQueryVariables } from '@/generated/graphql'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import SelectField, { SelectOption } from '@/components/input/SelectField.vue'
import { computed } from 'vue'
import { Validation, ValidationArgs } from '@vuelidate/core'

const props = defineProps<{
  modelValue?: Step
  moduleId: string
  label?: string
  required?: boolean
  validation?: Validation<ValidationArgs, MM>
}>()
defineEmits<{
  'update:modelValue': [value: Step]
}>()

type StepPickerOption = StepPickerDataQuery['product']['module']['nodes'][0]['step']
type StepPickerNode = StepPickerDataQuery['product']['module']['nodes'][0]
const fetchQuery = useQuery<StepPickerDataQuery, StepPickerDataQueryVariables>(
  gql`
    query StepPickerData($moduleId: ID!) {
      product {
        module(id: $moduleId) {
          abbreviation
          nodes {
            id
            parentId
            step {
              id
              name
              durationInSeconds
              instructionText
              stepType {
                id
                name
              }
            }
          }
        }
      }
    }
  `,
  () => ({
    moduleId: props.moduleId,
  }),
)
const module = computed(() => fetchQuery.result.value?.product.module)
const nodes = computed(() => fetchQuery.result.value?.product.module.nodes || [])
const options = computed<SelectOption<StepPickerOption>[]>(() => {
  let newOptions: SelectOption<StepPickerOption>[] = []
  nodes.value
    .filter((n) => !n.parentId)
    .forEach((n, i) => addNodeAndChildren(i, [module.value?.abbreviation || ''], n, newOptions))
  return newOptions
})
function addNodeAndChildren(
  index: number,
  parentNumbering: string[],
  node: StepPickerNode,
  options: SelectOption<StepPickerOption>[],
) {
  const ancestryNumbering = [...parentNumbering, `${index + 1}`]

  options.push({
    title: `${ancestryNumbering.join('.')} ${node.step.name}`,
    value: node.step,
  })
  nodes.value
    .filter((n) => n.parentId == node.id)
    .forEach((n, i) => addNodeAndChildren(i, ancestryNumbering, n, options))
}
</script>

<template>
  <select-field
    :label="props.label"
    :items="options"
    :model-value="props.modelValue"
    :validation="props.validation"
    @update:model-value="(t) => $emit('update:modelValue', t)"
  />
</template>

<style scoped lang="scss"></style>

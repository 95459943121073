import Keycloak from 'keycloak-js'
import { ref } from 'vue'

export type Authentication = {
  token: string
  userId: string
  logout: () => Promise<void>
}
export const authentication = ref<Authentication>()

const initOptions: Keycloak.KeycloakInitOptions = {
  useNonce: true,
  responseMode: 'fragment',
  flow: 'standard',
  pkceMethod: 'S256',
  enableLogging: false,
  scope: 'openid baseboard-console',
  onLoad: 'login-required',
}
const constructorOptions = {
  url: import.meta.env.VITE_KEYCLOAK_URL as string,
  realm: import.meta.env.VITE_KEYCLOAK_REALM as string,
  clientId: import.meta.env.VITE_KEYCLOAK_CLIENT_ID as string,
}
const keycloak = new Keycloak(constructorOptions)

type Token = Keycloak.KeycloakTokenParsed & {
  baseboard: {
    'user-id': string
  }
}
function authSuccess() {
  const details = keycloak.tokenParsed as Token
  authentication.value = {
    token: keycloak.token as string,
    userId: details.baseboard['user-id'],
    logout: async () => await keycloak.logout(),
  }

  const refreshIn = (details.exp as number) * 1000 - Date.now() - 10_000
  setTimeout(() => keycloak.updateToken(600), refreshIn)

  if (initOptions.enableLogging) {
    console.log('[AUTH] Got token', details)
    console.log('[AUTH] Refreshing token in', refreshIn)
  }
}

export default async (): Promise<undefined> => {
  return new Promise((resolve, reject) => {
    try {
      keycloak.onAuthSuccess = () => {
        authSuccess()
        resolve(undefined)
      }
      keycloak.onAuthError = (errorData) => {
        console.error('[AUTH] Could not authenticate', errorData)
      }
      keycloak.onAuthRefreshSuccess = () => {
        if (initOptions.enableLogging) {
          console.log('[AUTH] Token refresh successful')
        }
        authSuccess()
      }
      keycloak.onAuthRefreshError = () => {
        console.error('[AUTH] Could not refresh token')
      }
      keycloak.init(initOptions).then((authenticated) => {
        if (!authenticated) {
          if (initOptions.enableLogging) {
            console.log('[AUTH] Not authenticated, asking keycloak for a login')
          }
          keycloak.login()
        }
      })
    } catch (error) {
      console.error('Failed to initialize Keycloak adapter:', error)
      reject(error)
    }
  })
}

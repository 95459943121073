<script setup lang="ts">
import { computed, ref } from 'vue'
import { EbomBulkAllocateModuleNode } from '@/components/ebom/EbomBulkAllocateStepTree.vue'
import EbomBulkAllocateStepTreeNode from '@/components/ebom/EbomBulkAllocateStepTreeNode.vue'

const props = defineProps<{
  node: EbomBulkAllocateModuleNode
}>()

const isOpen = ref(false)
const hasChildren = computed(() => props.node.stepNodes.length > 0)
const module = computed(() => props.node.module)
</script>

<template>
  <v-list-item v-bind="$attrs" variant="text" density="compact" rounded style="user-select: none">
    <template #prepend>
      <v-icon
        density="compact"
        :icon="isOpen ? 'folder_open' : 'folder'"
        @click.stop="isOpen = !isOpen"
      />
    </template>
    <v-list-item-title>
      {{ module.name }}
    </v-list-item-title>
  </v-list-item>

  <div v-if="hasChildren && isOpen" class="children">
    <ebom-bulk-allocate-step-tree-node v-for="n in props.node.stepNodes" :key="n.id" :node="n" />
  </div>
</template>

<style scoped lang="scss">
.children {
  margin-left: 1.5em;
}
</style>

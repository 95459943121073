<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useQuery } from '@vue/apollo-composable'
import {
  EbomPartAllocationSuggestionDecision,
  EbomPartTreeEbomPartsQuery,
  EbomPartTreeEbomPartsQueryVariables,
} from '@/generated/graphql'
import gql from 'graphql-tag'
import { computed, ref } from 'vue'
import EbomPartTreeNode, { EbomPartNode } from '@/components/ebom/EbomPartTreeNode.vue'
import { sortTree } from '@/app'

const props = defineProps<{
  ebomId: string
  selectedEbomPartId?: string
}>()
defineEmits<{
  selectEbomPartId: [value: string]
}>()

export type EbomPartTreeEbomPart = EbomPartTreeEbomPartsQuery['article']['ebom']['ebomParts'][0] & {
  netAllocationMilliQuantity: number
  undecidedSuggestionsCount: number
}

const { t } = useI18n()
const fetchQuery = useQuery<EbomPartTreeEbomPartsQuery, EbomPartTreeEbomPartsQueryVariables>(
  gql`
    query EbomPartTreeEbomParts($id: ID!) {
      article {
        ebom(id: $id) {
          ebomParts {
            id
            parentId
            articleNumber
            name
            revision
            milliQuantity
            unit
            article {
              id
            }
            articlePath {
              articleNumbers
            }
            stepAllocations {
              milliQuantity
            }
            stepAllocationSuggestions {
              decision
            }
          }
        }
      }
    }
  `,
  () => ({
    id: props.ebomId,
  }),
)
const ebomParts = computed<EbomPartTreeEbomPart[]>(
  () =>
    fetchQuery.result.value?.article.ebom.ebomParts.map((p) => {
      const netAllocationMilliQuantity = p.article
        ? p.stepAllocations.reduce((agg, c) => agg + c.milliQuantity, 0) - p.milliQuantity
        : 0
      const undecidedSuggestionsCount = p.stepAllocationSuggestions.filter(
        (s) => s.decision == EbomPartAllocationSuggestionDecision.Undecided,
      ).length
      return {
        ...p,
        netAllocationMilliQuantity,
        undecidedSuggestionsCount,
      }
    }) || [],
)

const sortNodes = (nodes: EbomPartNode[]) =>
  sortTree(
    nodes,
    (c) => c.children.length > 0,
    (c) => c.ebomPart.articleNumber,
  )

const rootNodes = computed<EbomPartNode[]>(() =>
  sortNodes(ebomParts.value.filter((p) => !p.parentId).map((p) => createPartNode(p))),
)
function createPartNode(ebomPart: EbomPartTreeEbomPart): EbomPartNode {
  const children = ebomParts.value
    .filter((p) => p.parentId == ebomPart.id)
    .map((p) => createPartNode(p))
  const hasAllocationAbberation =
    ebomPart.netAllocationMilliQuantity != 0 || children.some((c) => c.hasAllocationAbberation)

  return {
    ebomPart,
    hasAllocationAbberation,
    children: sortNodes(children),
  }
}

const filterOpenSuggestions = ref(false)
const filterAllocationDisparity = ref(false)
const visibleEbomPartIds = computed(() => {
  const filteredIds = ebomParts.value
    .filter((p) => {
      if (filterOpenSuggestions.value == false) {
        return true
      }

      return (
        p.stepAllocationSuggestions.filter(
          (s) => s.decision == EbomPartAllocationSuggestionDecision.Undecided,
        ).length > 0
      )
    })
    .filter((p) => {
      if (filterAllocationDisparity.value == false) {
        return true
      }

      return p.netAllocationMilliQuantity != 0
    })
    .map((p) => p.id)
  if (props.selectedEbomPartId) {
    filteredIds.push(props.selectedEbomPartId)
  }

  return filteredIds
})
</script>

<template>
  <v-row>
    <v-col cols="8">
      <h2>
        {{ t('component.ebomPartTree.title') }}
        <v-progress-circular v-if="fetchQuery.loading.value" indeterminate size="20" />
      </h2>
    </v-col>
    <v-col class="mb-3 text-right">
      <v-btn
        density="comfortable"
        :variant="filterOpenSuggestions ? 'outlined' : 'plain'"
        icon="settings_suggest"
        :title="t('component.ebomPartTree.filterOpenSuggestionsHint')"
        @click="filterOpenSuggestions = !filterOpenSuggestions"
      />
      <v-btn
        density="comfortable"
        :variant="filterAllocationDisparity ? 'outlined' : 'plain'"
        class="ml-1"
        icon="difference"
        :title="t('component.ebomPartTree.filterAllocationDisparityHint')"
        @click="filterAllocationDisparity = !filterAllocationDisparity"
      />
    </v-col>
  </v-row>

  <div style="height: 76vh; overflow-y: scroll; overflow-x: hidden">
    <v-list nav density="compact">
      <ebom-part-tree-node
        v-for="n in rootNodes"
        :key="n.ebomPart.id"
        :node="n"
        :selected-ebom-part-id="props.selectedEbomPartId"
        :visible-ebom-part-ids="visibleEbomPartIds"
        @part="(v) => $emit('selectEbomPartId', v.id)"
      />
    </v-list>
  </div>
</template>

<style scoped lang="scss"></style>

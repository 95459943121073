<script setup lang="ts">
import FormField from '@/components/input/FormField.vue'
import { SelectOption } from '@/components/input/SelectField.vue'
import { Article, PartPickerDataQuery, PartPickerDataQueryVariables } from '@/generated/graphql'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed, ref, watch } from 'vue'
import { debounce } from 'lodash'

const props = defineProps<{
  modelValue?: Article
  label?: string
}>()
const emit = defineEmits<{
  'update:modelValue': [value: ArticlePickerArticle]
}>()

export type ArticlePickerArticle = PartPickerDataQuery['article']['articles'][0]

const searchString = ref<string>()
const debouncedSearchString = ref<string>()
const debouncer = debounce(() => (debouncedSearchString.value = searchString.value), 300)
watch(searchString, debouncer)

const fetchQuery = useQuery<PartPickerDataQuery, PartPickerDataQueryVariables>(
  gql`
    query PartPickerData($searchString: String) {
      article {
        articles(query: { containsString: $searchString }) {
          id
          articleNumber
          revision
          name
        }
      }
    }
  `,
  () => ({
    searchString: searchString.value,
  }),
  {
    debounce: 300,
  },
)
const items = computed<SelectOption<ArticlePickerArticle>[]>(
  () =>
    fetchQuery.result.value?.article.articles.map((p) => ({
      title: `${p.articleNumber}/${p.revision} ${p.name}`,
      value: p,
    })) || [],
)
</script>

<template>
  <form-field :label="props.label">
    <v-autocomplete
      hide-details
      v-bind="$attrs"
      :model-value="props.modelValue"
      :items="items"
      auto-select-first
      no-filter
      :loading="fetchQuery.loading.value"
      no-data-text="Type to search"
      @update:search="(v) => (searchString = v)"
      @update:model-value="(v) => $emit('update:modelValue', v)"
    >
      <template #selection="{ item }">
        {{ item.title }}
      </template>
    </v-autocomplete>
  </form-field>
</template>

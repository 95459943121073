<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import SelectField from '@/components/input/SelectField.vue'
import { ArticleUnit } from '@/generated/graphql'

const { t } = useI18n()

const items = Object.values(ArticleUnit).map((v) => ({
  title: t(`entity.article.unit.full.${v}`),
  value: v,
}))
</script>

<template>
  <select-field :items="items" />
</template>

<style scoped lang="scss"></style>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, ref } from 'vue'
import { useMutation, useQuery } from '@vue/apollo-composable'
import {
  BopNavigationDrawerDataQuery,
  BopNavigationDrawerDataQueryVariables,
  Mutation,
  VersionStatus,
} from '@/generated/graphql'
import gql from 'graphql-tag'
import { authzIsOrgPartAllocation } from '@/app'
import EbomLoader from '@/components/ebom/EbomLoader.vue'
import { useRouter } from 'vue-router'
import IconEbom from '@/components/icons/IconEbom.vue'
import { ComponentExposed } from 'vue-component-type-helpers'
import DeleteDialogNext from '@/components/dialogs/AsyncDeleteDialog.vue'
import AsyncDeleteDialog from '@/components/dialogs/AsyncDeleteDialog.vue'
import ItemDeleteIcon from '@/components/items/ItemDeleteIcon.vue'
import DataTable from '@/components/dataTable/DataTable.vue'

type LocalEbom = BopNavigationDrawerDataQuery['article']['ebomsForBop'][0]

const props = defineProps<{
  bopId: string
}>()
defineEmits<{
  refresh: []
}>()

const { t } = useI18n()
const router = useRouter()

const fetchQuery = useQuery<BopNavigationDrawerDataQuery, BopNavigationDrawerDataQueryVariables>(
  gql`
    query BopNavigationDrawerData($bopId: ID!) {
      product {
        bop(id: $bopId) {
          versionStatus
        }
      }
      article {
        ebomsForBop(bopId: $bopId) {
          id
          name
          sourceType
          sourceId
        }
      }
    }
  `,
  () => ({
    bopId: props.bopId,
  }),
  () => ({
    enabled: authzIsOrgPartAllocation.value,
  }),
)
const isReleased = computed(
  () => fetchQuery.result.value?.product.bop.versionStatus == VersionStatus.Released,
)
const eboms = computed(() => fetchQuery.result.value?.article.ebomsForBop || [])

const deleteEbomMutation = useMutation<Mutation, { id: string }>(gql`
  mutation deleteEbom($id: ID!) {
    article {
      deleteEbom(id: $id)
    }
  }
`)
const deleteDialog = ref<ComponentExposed<typeof DeleteDialogNext>>()
const doDelete = async (item: LocalEbom) => {
  await deleteDialog.value?.open(item.name, () =>
    deleteEbomMutation.mutate({ id: item.id }).then(() => fetchQuery.refetch()),
  )
}

const goToEbom = (_: unknown, row: { item: LocalEbom }) => {
  router.push({ name: 'ebom', params: { ebomId: row.item.id } })
}

const isRail = ref(true)

const headers = [
  {
    key: 'name',
    title: t('entity.ebom.field.name'),
  },
  {
    key: 'actions',
    width: 100,
    sortable: false,
  },
]
const sort = [{ key: 'name', order: 'asc' }]
</script>

<template>
  <v-navigation-drawer
    :rail="isRail"
    permanent
    location="right"
    width="650"
    class="pt-5"
    rail-width="85"
    @click="isRail = false"
  >
    <v-btn
      :icon="isRail ? 'chevron_left' : 'chevron_right'"
      density="comfortable"
      :size="40"
      style="position: absolute; left: -20px; top: 5px"
      @click.stop="isRail = !isRail"
    />
    <v-list v-if="isRail">
      <v-list-item v-if="authzIsOrgPartAllocation && !isReleased">
        <template #prepend>
          <v-badge :content="eboms.length">
            <icon-ebom
              size="35"
              :title="t('component.bopNavigationDrawer.headerAssociatedEboms')"
            />
          </v-badge>
        </template>
      </v-list-item>
    </v-list>
    <div v-if="!isRail" class="pl-8">
      <template v-if="authzIsOrgPartAllocation && !isReleased">
        <h3>{{ t('component.bopNavigationDrawer.headerAssociatedEboms') }}</h3>

        <data-table
          :loading="fetchQuery.loading"
          :headers="headers"
          :sort-by="sort"
          :items="eboms"
          hide-default-footer
          @click:row="goToEbom"
        >
          <template #header.actions>
            <div class="text-right">
              <ebom-loader :bop-id="bopId" @done="fetchQuery.refetch()" />
            </div>
          </template>
          <template #item.actions="{ item }: { item: LocalEbom }">
            <div class="text-right">
              <item-delete-icon @click="doDelete(item)" />
            </div>
          </template>
        </data-table>
      </template>
    </div>
  </v-navigation-drawer>

  <async-delete-dialog ref="deleteDialog" :warning="t('entity.ebom.deleteWarning')" hindrance />
</template>

<style scoped></style>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import useVuelidate from '@vuelidate/core'
import { minLength, required } from '@/validation'
import TextField from '@/components/input/TextField.vue'

defineEmits<{
  delete: []
}>()
const model = defineModel<string>({ required: false })
const validation = useVuelidate<string>({ required, length: minLength(1) }, model)

const { t } = useI18n()
</script>

<template>
  <text-field v-model="model" required :validation="validation">
    <template #append>
      <v-btn variant="flat" density="comfortable" icon="delete" @click="$emit('delete')" />
    </template>
  </text-field>
</template>

<style scoped lang="scss"></style>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useMutation, useQuery } from '@vue/apollo-composable'
import { Mutation, Query, UpdateStep } from '@/generated/graphql'
import gql from 'graphql-tag'
import { computed, reactive, ref, watch } from 'vue'
import ToolPicker from '@/components/input/ToolPicker.vue'
import IntegerField from '@/components/input/IntegerField.vue'
import StepTypePicker from '@/components/input/StepTypePicker.vue'
import TextAreaField from '@/components/input/TextAreaField.vue'
import TextField from '@/components/input/TextField.vue'
import MultiImagePicker from '@/components/input/MultiImagePicker.vue'
import useVuelidate from '@vuelidate/core'
import { maxValue, minValue, normalName, required } from '@/validation'
import { KeysOfType } from '@/app'
import NotificationSaved from '@/components/notifications/NotificationSaved.vue'
import StepToolbar from '@/components/step/StepToolbar.vue'
import ArticleAllocationList from '@/components/article/ArticleAllocationList.vue'
import StepCheckConfigurations from '@/components/checkConfiguration/StepCheckConfigurations.vue'

type Model = Partial<UpdateStep>
const modelKeys: KeysOfType<keyof Model> = [
  'id',
  'name',
  'stepTypeId',
  'durationInSeconds',
  'instructionText',
  'imageUploadIds',
  'toolIds',
]

const props = defineProps<{
  siteId: string
  stepId: string
  bopId: string
}>()
const emit = defineEmits<{
  deleted: []
}>()

const { t } = useI18n()

const fetchQuery = useQuery<Query, { id: string }>(
  gql`
    query getStep($id: ID!) {
      product {
        step(id: $id) {
          id
          durationInSeconds
          imageUploadIds
          imageUploads {
            id
            imageUrl(options: { height: 500, width: 500 })
          }
          instructionText
          name
          stepType {
            id
            name
          }
          toolIds
          tools {
            id
            name
            imageUploads {
              id
              imageUrl(options: { height: 500, width: 500 })
            }
          }
        }
      }
    }
  `,
  () => ({
    id: props.stepId as string,
  }),
  () => ({
    enabled: !!props.stepId,
  }),
)
const step = computed(() => fetchQuery.result.value?.product.step)

const model = reactive<Model>({})
const validation = useVuelidate<Model>(
  {
    name: { required, normalName },
    stepTypeId: { required },
    durationInSeconds: { max: maxValue(32767), min: minValue(0) },
  },
  model,
)

watch(step, (v) => {
  if (!v) {
    return
  }

  modelKeys.forEach((k) => (model[k] = v[k]))
  model.stepTypeId = v.stepType.id
  validation.value.$reset()
})

const notifySaved = ref(false)
const updateMutation = useMutation<Mutation, { step: UpdateStep }>(
  gql`
    mutation updateStep($step: UpdateStep!) {
      product {
        updateStep(command: $step) {
          id
        }
      }
    }
  `,
  {
    refetchQueries: ['StepTreeData'],
  },
)

function update() {
  updateMutation.mutate({ step: model as UpdateStep }).then(() => {
    notifySaved.value = true
  })
}
</script>

<template>
  <h2 style="position: relative">
    {{ model.name }}
    <step-toolbar :step-id="props.stepId" @deleted="$emit('deleted')" />
  </h2>

  <h3>{{ t('entity.article.plural') }}</h3>
  <article-allocation-list label="Parts" :bop-id="props.bopId" :step-id="props.stepId" />

  <h3>{{ t('entity.step.singular') }}</h3>
  <v-card>
    <v-card-text>
      <text-field
        v-model="model.name"
        :label="t('entity.step.field.name')"
        :validation="validation.name"
        required
      />
      <step-type-picker
        v-model="model.stepTypeId"
        :label="t('entity.step.field.stepType')"
        :validation="validation.stepTypeId"
        required
      />
      <integer-field
        v-model="model.durationInSeconds"
        :label="t('entity.step.field.durationInSeconds')"
        :validation="validation.durationInSeconds"
        required
      />
      <text-area-field
        v-model="model.instructionText"
        :label="t('entity.step.field.instructionText')"
        :validation="validation.instructionText"
      />
      <tool-picker
        v-model="model.toolIds"
        :site-id="props.siteId"
        :label="t('entity.step.field.tools')"
      />
      <multi-image-picker
        v-if="model.imageUploadIds"
        v-model="model.imageUploadIds"
        :label="t('entity.step.field.images')"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="primary"
        variant="elevated"
        :loading="updateMutation.loading.value"
        @click="update()"
      >
        {{ t('button.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>

  <h3>{{ t('entity.checkConfiguration.plural') }}</h3>
  <step-check-configurations :step-id="stepId" />

  <notification-saved v-model="notifySaved" />
</template>

<style scoped lang="scss">
h3 {
  margin-top: 2em;
  margin-bottom: 1em;

  &:first-of-type {
    margin-top: 1em;
  }
}
</style>

<script setup lang="ts">
import { computed } from 'vue'
import { PrintViewStepNode } from '@/views/organization/ModulePrintView.vue'

const props = defineProps<{
  nodes: PrintViewStepNode[]
  parentId?: string
}>()

const nodes = computed(() => props.nodes.filter((n) => n.parentId == props.parentId))
</script>

<template>
  <div v-for="n in nodes" :key="n.id" class="step">
    <v-row class="avoid-page-break">
      <v-col>
        <span class="font-weight-bold">{{ n.step.name }}</span>
      </v-col>
      <v-col cols="3">
        <v-chip density="comfortable">{{ n.step.stepType.name }}</v-chip>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col class="instructiontext">
        <v-chip-group v-if="n.step.tools.length > 0">
          <v-chip density="comfortable">
            <v-icon icon="handyman" />
          </v-chip>
          <v-chip
            v-for="tool in n.step.tools"
            :key="tool.id"
            density="comfortable"
            class="font-weight-bold"
          >
            {{ tool.name }}
          </v-chip>
        </v-chip-group>
        {{ n.step.instructionText }}
      </v-col>
      <v-col v-if="n.step.imageUploadIds.length > 0" cols="8" class="d-inline-flex">
        <v-img
          v-for="img in n.step.imageUploads"
          :key="img.id"
          eager
          :max-width="`${100 / n.step.imageUploadIds.length}%`"
          height="200px"
          :src="img.imageUrl"
          class="ml-2"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col class="pl-4" cols="12">
        <div class="substeps">
          <steps-tree-print :nodes="props.nodes" :parent-id="n.id" />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped lang="scss">
@page {
  margin: 0.5cm;
}
.step {
  border: #ccc dashed 1px;
  border-bottom: none;
  padding: 0.5em 0.5em 0 0.5em;
}
.substeps {
  padding-left: 1em;
  page-break-before: avoid;
}
.avoid-page-break {
  page-break-after: avoid;
  break-after: avoid;
}
</style>

import { computed, ref, Ref, watch } from 'vue'
import { apolloClient } from '@/apollo'
import gql from 'graphql-tag'
import {
  CurrentProductionBatchDataQuery,
  CurrentProductionBatchDataQueryVariables,
  CurrentProductionBatchUpdateMutation,
  CurrentProductionBatchUpdateMutationVariables,
  UpdateProductionBatchCommand,
} from '@/generated/graphql'
import { merge } from 'lodash'

type CurrentProductionBatch =
  CurrentProductionBatchDataQuery['shopFloor']['productionBatchesInProgressForCurrentUser'][0]

const selectedIdKey = 'currentProductionBatchId'
const selectedId = ref<string | undefined>(window.localStorage.getItem(selectedIdKey) || undefined)
watch(selectedId, (v) => {
  if (v) {
    window.localStorage.setItem(selectedIdKey, v)
  } else {
    window.localStorage.removeItem(selectedIdKey)
  }
})

const allBatches = ref<CurrentProductionBatch[]>([])
const fetchAllBatches = () => {
  if (!selectedId.value) {
    return
  }

  apolloClient
    .query<CurrentProductionBatchDataQuery, CurrentProductionBatchDataQueryVariables>({
      query: gql`
        query CurrentProductionBatchData {
          shopFloor {
            productionBatchesInProgressForCurrentUser {
              id
              poNumber
              serialNumbers
              assignedUser {
                id
              }
              completed
              module {
                id
              }
              currentStep {
                id
              }
            }
          }
        }
      `,
    })
    .then((result) => {
      allBatches.value = result?.data.shopFloor.productionBatchesInProgressForCurrentUser || []
    })
}

const currentProductionBatch = computed<CurrentProductionBatch | undefined>(() =>
  allBatches.value.find((b) => b.id == selectedId.value),
)
const setCurrentProductionBatch = (id: string) => {
  selectedId.value = id
  fetchAllBatches()
}
const deselectCurrentProductionBatch = () => {
  selectedId.value = undefined
}

const updateProductionBatch = (command: Partial<UpdateProductionBatchCommand>) => {
  const current = currentProductionBatch.value
  if (!current) {
    return
  }

  const completeCommand = merge(
    {
      id: current.id,
      currentStepId: current.currentStep?.id,
      assignedUserId: current.assignedUser?.id,
      completed: current.completed,
    },
    command,
  )

  return apolloClient
    .mutate<CurrentProductionBatchUpdateMutation, CurrentProductionBatchUpdateMutationVariables>({
      mutation: gql`
        mutation CurrentProductionBatchUpdate($command: UpdateProductionBatchCommand!) {
          shopFloor {
            updateProductionBatch(command: $command) {
              id
              poNumber
              serialNumbers
              assignedUser {
                id
              }
              completed
              module {
                id
              }
              currentStep {
                id
              }
            }
          }
        }
      `,
      variables: { command: completeCommand },
    })
    .then(() => {
      fetchAllBatches()
    })
}

const setCurrentStep = (id: string) => {
  if (currentProductionBatch.value?.currentStep?.id != id) {
    updateProductionBatch({ currentStepId: id })
  }
}

const setCompleted = () => {
  if (!currentProductionBatch.value?.completed) {
    updateProductionBatch({ completed: true })
  }
}

const refetchCurrentProductionBatch = () => {
  fetchAllBatches()
}

let initialized = false

export default function useCurrentProductionBatch(): {
  setCurrentProductionBatch: (id: string) => void
  deselectCurrentProductionBatch: () => void
  currentProductionBatch: Ref<CurrentProductionBatch | undefined>
  setCurrentStep: (id: string) => void
  setCompleted: () => void
  refetchCurrentProductionBatch: () => void
} {
  if (!initialized) {
    fetchAllBatches()
    initialized = true
  }

  return {
    setCurrentProductionBatch,
    deselectCurrentProductionBatch,
    currentProductionBatch,
    setCurrentStep,
    setCompleted,
    refetchCurrentProductionBatch,
  }
}

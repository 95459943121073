<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>

<template>
  <v-icon icon="settings" :title="t('component.iconPart.title')" />
</template>

<style scoped lang="scss"></style>

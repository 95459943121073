<script setup lang="ts" generic="MM">
import { Validation, ValidationArgs } from '@vuelidate/core'
import ValidationErrors from '@/components/input/ValidationErrors.vue'
import { classNameForInvalidFields } from '@/validation'

const props = defineProps<{
  label?: string
  validation?: Validation<ValidationArgs, MM> | Validation<ValidationArgs, MM>[]
  required?: boolean
}>()
</script>

<template>
  <div :class="`form-field ${props.validation?.$invalid ? classNameForInvalidFields : ''}`">
    <div v-if="props.label" class="label">
      <label v-if="!!props.label">{{ props.label }}</label>
      <span v-if="props.required" class="text-error">*</span>
    </div>
    <div>
      <slot name="default" />
    </div>
    <validation-errors :validation="props.validation" />
  </div>
</template>

<style lang="scss">
.form-field {
  margin-bottom: 1rem;

  .label {
    font-size: 0.95em;
    margin-bottom: 3px;
  }
}
</style>

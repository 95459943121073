<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useMutation, useQuery } from '@vue/apollo-composable'
import {
  ArticlePath,
  PromoteEbomPartsEbomQuery,
  PromoteEbomPartsEbomQueryVariables,
  PromoteEbomPartsPromotionMutation,
  PromoteEbomPartsPromotionMutationVariables,
} from '@/generated/graphql'
import gql from 'graphql-tag'
import { computed, ref, watch } from 'vue'
import { isEqual } from 'lodash'

type PromotableEbomPart = PromoteEbomPartsEbomQuery['article']['ebom']['ebomParts'][0]

const props = defineProps<{
  ebomId: string
}>()
const emit = defineEmits<{
  done: []
}>()

const { t } = useI18n()

const fetchQuery = useQuery<PromoteEbomPartsEbomQuery, PromoteEbomPartsEbomQueryVariables>(
  gql`
    query PromoteEbomPartsEbom($id: ID!) {
      article {
        ebom(id: $id) {
          id
          ebomParts {
            id
            articleNumber
            revision
            milliQuantity
            unit
            name
            articlePath {
              articleNumbers
            }
            article {
              id
            }
          }
        }
      }
    }
  `,
  () => ({
    id: props.ebomId,
  }),
)
const allEbomParts = computed(() => fetchQuery.result.value?.article.ebom.ebomParts || [])
const promotableEbomParts = computed(
  () => fetchQuery.result.value?.article.ebom.ebomParts?.filter((p) => !p.article) || [],
)
watch(
  () => [promotableEbomParts.value, fetchQuery.loading.value],
  () => {
    if (promotableEbomParts.value.length == 0 && !fetchQuery.loading.value) {
      emit('done')
    }
  },
)

const mutation = useMutation<
  PromoteEbomPartsPromotionMutation,
  PromoteEbomPartsPromotionMutationVariables
>(
  gql`
    mutation PromoteEbomPartsPromotion($ids: [ID!]!) {
      article {
        promoteEbomPartsToArticles(ids: $ids) {
          id
          article {
            id
            name
          }
        }
      }
    }
  `,
  {
    refetchQueries: ['EbomPartTreeEbomParts'],
  },
)

const addLeafs = (aggregator: PromotableEbomPart[], node: PromotableEbomPart) => {
  const desiredArticlePathLength = node.articlePath.articleNumbers.length + 1
  const isPromotable = !node.article

  const children = allEbomParts.value
    .filter((p) => p.articlePath.articleNumbers.length == desiredArticlePathLength)
    .filter((p) => {
      const nodeParentArticleNumbers = p.articlePath.articleNumbers.slice(0, -1)
      return isEqual(nodeParentArticleNumbers, node.articlePath.articleNumbers)
    })

  if (children.length == 0 && isPromotable) {
    aggregator.push(node)
  }

  children.forEach((n) => addLeafs(aggregator, n))
}
const allLeafs = computed(() => {
  const aggregator: PromotableEbomPart[] = []
  allEbomParts.value
    .filter((p) => p.articlePath.articleNumbers.length == 1)
    .forEach((p) => addLeafs(aggregator, p))
  return aggregator
})
const selectNonAssemblies = () => {
  selected.value = allLeafs.value.map((p) => p.id)
}

const notifySuccess = ref(false)
const selected = ref<string[]>([])
function promoteSelectionToParts() {
  mutation.mutate({ ids: selected.value }).then(() => {
    notifySuccess.value = true
    fetchQuery.refetch()
  })
}

const search = ref<string>('')
const headers = [
  { key: 'articleNumber', title: t('entity.ebomPart.field.articleNumber') },
  { key: 'revision', title: t('entity.ebomPart.field.revision') },
  { key: 'milliQuantity', title: t('entity.ebomPart.field.quantity') },
  { key: 'name', title: t('entity.ebomPart.field.name') },
  { key: 'articlePath', title: t('entity.ebomPart.field.articlePath'), sortable: false },
]
</script>

<template>
  <v-row>
    <v-col cols="10" offset="1">
      <v-alert type="success" class="mb-5">
        <h3>{{ t('component.promoteEbomParts.title') }}</h3>
        <p>{{ t('component.promoteEbomParts.instructions') }}</p>
      </v-alert>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="8">
      <v-btn variant="elevated" @click="selectNonAssemblies()">
        {{ t('component.promoteEbomParts.buttonSelectNonAssemblies') }}
      </v-btn>
      <v-btn
        variant="elevated"
        :disabled="selected.length == 0"
        :loading="mutation.loading.value"
        @click="promoteSelectionToParts"
      >
        {{ t('component.promoteEbomParts.buttonPromote') }}
      </v-btn>
      <v-btn class="ml-5" variant="elevated" color="primary" @click="$emit('done')">
        {{ t('component.promoteEbomParts.buttonDone') }}
      </v-btn>
    </v-col>
    <v-col>
      <v-text-field v-model="search" variant="underlined" density="compact">
        <template #prepend>
          <v-icon icon="search" />
        </template>
      </v-text-field>
    </v-col>
  </v-row>
  <v-data-table-virtual
    v-model="selected"
    :headers="headers"
    show-select
    :items="promotableEbomParts"
    :item-value="(item) => item.id"
    :loading="fetchQuery.loading.value"
    :search="search"
    density="compact"
  >
    <template #item.milliQuantity="{ value }: { value: number }">
      {{ value / 1000 }}
    </template>
    <template #item.articlePath="{ value }: { value: ArticlePath }">
      <v-breadcrumbs :items="value.articleNumbers" density="compact">
        <template #divider>
          <v-icon icon="chevron_right" />
        </template>
      </v-breadcrumbs>
    </template>
    <template #item.isPart="{ value }: { value: boolean }">
      <v-icon
        :icon="value ? 'check-circle' : 'highlight_off'"
        :color="value ? 'success' : 'error'"
      />
    </template>
  </v-data-table-virtual>

  <v-snackbar v-model="notifySuccess" :timeout="5000">
    {{ t('component.promoteEbomParts.successMessage') }}
  </v-snackbar>
</template>

<style scoped lang="scss"></style>

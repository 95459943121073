<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const props = defineProps<{
  isArchived: boolean
}>()

const { t } = useI18n()
</script>

<template>
  <v-icon
    icon="inventory_2"
    :disabled="!props.isArchived"
    :title="t(`component.iconArchived.${props.isArchived ? 'archived' : 'notArchived'}`)"
  />
</template>

<style scoped lang="scss"></style>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import {
  ArticleAllocationListDataQuery,
  ArticleAllocationListDataQueryVariables,
  PartAllocationListAllocationMutationMutation,
  PartAllocationListAllocationMutationMutationVariables,
} from '@/generated/graphql'
import { computed, ref } from 'vue'
import { useMutation, useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import NotificationDeleted from '@/components/notifications/NotificationDeleted.vue'
import { authzIsOrgPartAllocation } from '@/app'
import ArticleQuantity from '@/components/article/ArticleQuantity.vue'
import ArticleName from '@/components/article/ArticleName.vue'
import EbomPartAllocationRow from '@/components/article/EbomPartAllocationRow.vue'
import ManualArticleAllocationRow from '@/components/article/ManualArticleAllocationRow.vue'
import IconManual from '@/components/icons/IconManual.vue'
import IconEbom from '@/components/icons/IconEbom.vue'
import DataTable from '@/components/dataTable/DataTable.vue'
import ItemDeleteIcon from '@/components/items/ItemDeleteIcon.vue'
import ItemEditIcon from '@/components/items/ItemEditIcon.vue'
import IconArticleType from '@/components/icons/IconArticleType.vue'

const props = defineProps<{
  label: string
  bopId: string
  stepId: string
}>()

type LocalArticleAllocation =
  ArticleAllocationListDataQuery['product']['step']['articleAllocations'][0]
type LocalEbomPartAllocation = LocalArticleAllocation & { __typename: 'EbomPartAllocation' }
type LocalManualPartAllocation = LocalArticleAllocation & { __typename: 'ManualArticleAllocation' }

const { t } = useI18n()

const fetchQuery = useQuery<
  ArticleAllocationListDataQuery,
  ArticleAllocationListDataQueryVariables
>(
  gql`
    query ArticleAllocationListData($stepId: ID!) {
      product {
        step(id: $stepId) {
          articleAllocations {
            __typename
            ... on IArticleAllocation {
              id
              article {
                id
                articleNumber
                revision
                name
                unit
                type
              }
              milliQuantity
            }
            ... on EbomPartAllocation {
              ebomPart {
                id
                ebomId
                articleNumber
                revision
                name
                unit
                articlePath {
                  articleNumbers
                }
              }
            }
          }
        }
      }
    }
  `,
  () => ({ stepId: props.stepId }),
)
const allocations = computed<LocalArticleAllocation[]>(
  () => fetchQuery.result.value?.product.step.articleAllocations || [],
)
const setManualAllocationMutation = useMutation<
  PartAllocationListAllocationMutationMutation,
  PartAllocationListAllocationMutationMutationVariables
>(gql`
  mutation PartAllocationListAllocationMutation($command: AllocateArticleToStepCommand!) {
    article {
      allocateArticleToStep(command: $command)
    }
  }
`)

const notifyDeleted = ref(false)

function deleteManualAllocation(articleId: string) {
  setManualAllocationMutation
    .mutate({
      command: {
        articleId,
        stepId: props.stepId,
        milliQuantity: 0,
      },
    })
    .then(() => {
      notifyDeleted.value = true
      fetchQuery.refetch()
    })
}

const headers = [
  {
    key: '__typeName',
    title: t('entity.article.field.source'),
    width: 50,
  },
  {
    key: 'type',
    title: t('entity.article.field.type'),
    width: 50,
  },
  {
    key: 'article.name',
    title: t('entity.article.singular'),
  },
  {
    key: 'quantity',
    title: t('entity.article.field.quantity'),
    width: 50,
  },
  {
    key: 'actions',
    width: 100,
    sortable: false,
  },
]
const sort = [
  { key: '__typeName', order: 'asc' },
  { key: 'type', order: 'asc' },
  { key: 'article.name', order: 'asc' },
]
</script>

<template>
  <data-table
    :items="allocations"
    :headers="headers"
    :loading="fetchQuery.loading"
    :sort-by="sort"
    density="comfortable"
    hide-default-footer
  >
    <template #body.prepend>
      <manual-article-allocation-row :step-id="props.stepId" @allocated="fetchQuery.refetch()" />
      <ebom-part-allocation-row
        :bop-id="props.bopId"
        :step-id="props.stepId"
        @allocated="fetchQuery.refetch()"
      />
    </template>
    <template #item.actions="{ item, index }: { item: LocalArticleAllocation; index: number }">
      <div class="text-right">
        <item-edit-icon
          v-if="authzIsOrgPartAllocation && item.__typename == 'EbomPartAllocation'"
          @click="
            $router.push({
              name: 'ebom',
              params: { ebomId: item.ebomPart.ebomId, ebomPartId: item.ebomPart.id },
            })
          "
        />
        <item-delete-icon
          :loading="setManualAllocationMutation.loading.value"
          @click="deleteManualAllocation(item.article.id)"
        />
      </div>
    </template>
    <template #item.__typeName="{ item }: { item: LocalArticleAllocation }">
      <icon-manual v-if="item.__typename == 'ManualArticleAllocation'" />
      <icon-ebom v-if="item.__typename == 'EbomPartAllocation'" />
    </template>
    <template #item.type="{ item }: { item: LocalArticleAllocation }">
      <icon-article-type :type="item.article.type" />
    </template>
    <template #item.article.name="{ item }: { item: LocalArticleAllocation }">
      <article-name
        :article="item.__typename == 'EbomPartAllocation' ? item.ebomPart : item.article"
      />
    </template>
    <template #item.quantity="{ item }: { item: LocalCheckConfiguration }">
      <article-quantity
        v-if="authzIsOrgPartAllocation"
        :milli-quantity="item.milliQuantity"
        :unit="item.article.unit"
        unit-abbreviated
      />
    </template>
  </data-table>

  <notification-deleted v-model="notifyDeleted" />
</template>

<style scoped lang="scss"></style>

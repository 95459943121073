<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useMutation } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { ref, watch } from 'vue'
import {
  StringCheckDeleteMutation,
  StringCheckDeleteMutationVariables,
  StringCheckSetMutation,
  StringCheckSetMutationVariables,
} from '@/generated/graphql'
import NotificationSaved from '@/components/notifications/NotificationSaved.vue'
import TextField from '@/components/input/TextField.vue'
import { debounce } from 'lodash'

const { t } = useI18n()

const props = defineProps<{
  value?: string
  required?: boolean
  checkConfigurationId: string
  poNumber: string
  serialNumber: string
}>()

const model = ref<string | undefined>(props.value)
const debouncedModel = ref<string | undefined>()
const debouncer = debounce(() => {
  debouncedModel.value = model.value
}, 1000)
watch(model, debouncer)

const notifySaved = ref(false)
const setMutation = useMutation<StringCheckSetMutation, StringCheckSetMutationVariables>(gql`
  mutation StringCheckSet($command: SetStringCheckCommand!) {
    shopFloor {
      setStringCheck(command: $command) {
        id
      }
    }
  }
`)
const deleteMutation = useMutation<StringCheckDeleteMutation, StringCheckDeleteMutationVariables>(
  gql`
    mutation StringCheckDelete($command: DeleteCheckCommand!) {
      shopFloor {
        deleteCheck(command: $command)
      }
    }
  `,
)
watch(debouncedModel, (v) => {
  const baseCommand = {
    checkConfigurationId: props.checkConfigurationId,
    poNumber: props.poNumber,
    serialNumber: props.serialNumber,
  }
  if (!v) {
    deleteMutation.mutate({ command: baseCommand }).then(() => {
      notifySaved.value = true
    })
    return
  }

  setMutation.mutate({ command: { ...baseCommand, text: v } }).then(() => {
    notifySaved.value = true
  })
})
</script>

<template>
  <text-field v-model="model" :required="props.required" :label="serialNumber" v-bind="$attrs" />
  <notification-saved v-model="notifySaved" />
</template>

<style scoped lang="scss"></style>

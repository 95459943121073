<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed, ref } from 'vue'
import { EbomDiffViewerDataQuery, EbomDiffViewerDataQueryVariables } from '@/generated/graphql'
import EbomDiffTree, { EbomPartDiff } from '@/components/ebom/EbomDiffTree.vue'
import EbomPartDetails from '@/components/ebom/EbomPartDetails.vue'

const props = defineProps<{
  bopId: string
  oldEbomId: string
  newEbomId: string
}>()

const { t } = useI18n()

const fetchQuery = useQuery<EbomDiffViewerDataQuery, EbomDiffViewerDataQueryVariables>(
  gql`
    query EbomDiffViewerData($oldEbomId: ID!, $newEbomId: ID!) {
      article {
        oldEbom: ebom(id: $oldEbomId) {
          ...ebomData
        }
        newEbom: ebom(id: $newEbomId) {
          ...ebomData
        }
      }
    }

    fragment ebomData on Ebom {
      id
      name
      sourceType
      sourceId
    }
  `,
  () => ({
    oldEbomId: props.oldEbomId,
    newEbomId: props.newEbomId,
  }),
)
const eboms = computed(() => fetchQuery.result.value?.article)
const selectedDiff = ref<EbomPartDiff>()
const inOld = computed(() => selectedDiff.value?.inOld)
const inNew = computed(() => selectedDiff.value?.inNew)
const calculateChange = <T,>(old: T, neww: T) => (old == neww ? undefined : [old, neww])
const revisionChange = computed(() => calculateChange(inOld.value?.revision, inNew.value?.revision))
const quantityChange = computed(() =>
  calculateChange(inOld.value?.milliQuantity, inNew.value?.milliQuantity),
)
</script>

<template>
  <v-row dense>
    <v-col cols="5">
      <ebom-diff-tree
        :old-ebom-id="props.oldEbomId"
        :new-ebom-id="props.newEbomId"
        @select-diff="(v) => (selectedDiff = v)"
      />
    </v-col>
    <v-col>
      <div v-if="selectedDiff?.inOld" class="ebom-diff-details-viewer details-old">
        <h2 class="text-center">{{ eboms?.oldEbom?.name }}</h2>
        <ebom-part-details :bop-id="props.bopId" :ebom-part-id="selectedDiff.inOld.id" />
      </div>
      <v-row v-if="!!inOld && !!inNew" class="pt-2 text-center" justify="center">
        <v-col v-if="!!revisionChange" cols="6" md="4" lg="3">
          <v-alert type="info" density="compact" variant="tonal" icon="swap_horiz" prominent>
            <h4>Revision</h4>
            {{ revisionChange[0] }} <v-icon icon="forward" /> {{ revisionChange[1] }}
          </v-alert>
        </v-col>
        <v-col v-if="!!quantityChange" cols="6" md="4" lg="3">
          <v-alert type="info" density="compact" variant="tonal" icon="swap_horiz" prominent>
            <h4>Quantity</h4>
            {{ quantityChange[0] ? quantityChange[0] / 1000 : 0 }}
            <v-icon icon="forward" />
            {{ quantityChange[1] ? quantityChange[1] / 1000 : 0 }}
          </v-alert>
        </v-col>
      </v-row>
      <div v-if="selectedDiff?.inNew" class="ebom-diff-details-viewer details-new">
        <h2 class="text-center">{{ eboms?.newEbom?.name }}</h2>
        <ebom-part-details :bop-id="props.bopId" :ebom-part-id="selectedDiff.inNew.id" />
      </div>

      <div v-if="!selectedDiff" class="text-center text-grey-lighten-1">
        <h2>Select an EBOM part</h2>

        <p class="mx-auto" style="width: 60%">
          On the left you see the difference in EBOM parts between EBOM
          {{ eboms?.oldEbom.name }} and {{ eboms?.newEbom.name }}. Select one to get more details on
          its allocation in the BOP.
        </p>
      </div>
    </v-col>
  </v-row>
</template>

<style lang="scss">
.ebom-diff-details-viewer {
  padding: 0.7em;
  border-radius: 0.5em;

  &.details-old {
    background: rgba(var(--v-theme-error), var(--v-activated-opacity));
  }
  &.details-new {
    margin-top: 0.5em;
    background: rgba(var(--v-theme-success), var(--v-activated-opacity));
  }
}
.ebom-diff-change {
}
</style>

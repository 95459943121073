<script setup lang="ts" generic="MM">
import { useI18n } from 'vue-i18n'

const props = defineProps<{
  modelValue: boolean
  title: string
  content: string
}>()
const emit = defineEmits<{
  'update:modelValue': [value: boolean]
  ok: []
}>()

const { t } = useI18n()

function ok() {
  emit('ok')
  emit('update:modelValue', false)
}
</script>

<template>
  <v-dialog
    max-width="650"
    :model-value="props.modelValue"
    @update:model-value="(v) => $emit('update:modelValue', v)"
    @keydown.ctrl.enter.stop="ok()"
    @keydown.meta.enter.stop="ok()"
  >
    <v-card>
      <v-card-title>{{ props.title }}</v-card-title>
      <v-card-text>
        {{ props.content }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="$emit('update:modelValue', false)">
          {{ t('component.confirmDialog.cancel') }}
        </v-btn>
        <v-btn color="primary" variant="elevated" @click="ok()">
          {{ t('component.confirmDialog.ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

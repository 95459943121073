<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { computed, ref, watch } from 'vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import UpdateStep from '@/components/step/UpdateStep.vue'
import CreateStep from '@/components/step/CreateStep.vue'
import StepTree from '@/components/step/StepTree.vue'
import {
  ModuleViewDataQuery,
  ModuleViewDataQueryVariables,
  VersionStatus,
} from '@/generated/graphql'
import { authzIsOrgMbom } from '@/app'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import DownloadModuleMbomButton from '@/components/mbom/DownloadModuleMbomButton.vue'
import ViewStep from '@/components/step/ViewStep.vue'
import ViewCheckConfigurations from '@/components/checkConfiguration/ViewCheckConfigurations.vue'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()

const productId = computed(() => route.params.productId as string)
const moduleId = computed(() => route.params.moduleId as string)
const stepId = computed(() => route.params.stepId as string | undefined)
const bopId = computed(() => route.params.bopId as string)
const siteId = computed(() => route.params.siteId as string)

const fetchQuery = useQuery<ModuleViewDataQuery, ModuleViewDataQueryVariables>(
  gql`
    query ModuleViewData($bopId: ID!, $moduleId: ID!) {
      product {
        bop(id: $bopId) {
          id
          versionStatus
        }
        module(id: $moduleId) {
          id
          name
          abbreviation
        }
      }
    }
  `,
  () => ({
    bopId: bopId.value,
    moduleId: moduleId.value,
  }),
)
const isReleased = computed(
  () => fetchQuery.result.value?.product.bop.versionStatus == VersionStatus.Released,
)
const canMbom = computed(() => isReleased.value && authzIsOrgMbom.value)
const module = computed(() => fetchQuery.result.value?.product.module)

type State = 'initial' | 'update' | 'create' | 'view'
const state = ref<State>('initial')
function createNode() {
  state.value = 'create'
}
watch(
  [stepId, isReleased],
  (v) => {
    if (v) {
      state.value = isReleased.value ? 'view' : 'update'
    }
  },
  { immediate: true },
)

function selectStep(id: string) {
  router.push({ params: { stepId: id } })
}
</script>

<template>
  <breadcrumb />
  <template v-if="canMbom">
    <download-module-mbom-button
      v-if="!!module"
      :bop-id="bopId"
      :module="module"
      short
      variant="elevated"
      color="primary"
      style="position: absolute; right: 1em; top: 6em"
    />
  </template>
  <v-row class="mt-1">
    <v-col cols="6" xl="5" xxl="4">
      <step-tree
        :bop-id="bopId"
        :module-id="moduleId"
        :selected-step-id="stepId"
        @create-node="createNode"
        @select-step-id="selectStep"
      />
    </v-col>
    <v-col>
      <div>
        <v-empty-state v-if="state == 'initial'" icon="account_tree" text="Select a step" />
        <create-step
          v-if="state == 'create'"
          :site-id="siteId"
          :module-id="moduleId"
          :product-id="productId"
          @created="selectStep"
        />
        <update-step
          v-if="!!stepId && state == 'update'"
          :step-id="stepId"
          :site-id="siteId"
          :bop-id="bopId"
          @deleted="state = 'initial'"
        />
        <template v-if="!!stepId && state == 'view'">
          <view-step :step-id="stepId" />

          <h2 class="mt-10">{{ t('entity.checkConfiguration.plural') }}</h2>
          <view-check-configurations :step-id="stepId" />
        </template>
      </div>
    </v-col>
  </v-row>
</template>

<style scoped lang="scss"></style>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, ref, watch } from 'vue'
import ArticleQuantity from '@/components/article/ArticleQuantity.vue'
import { EbomBulkAllocatePartNode } from '@/components/ebom/EbomBulkAllocatePartTree.vue'
import ArticleName from '@/components/article/ArticleName.vue'
import useIdSelection from '@/useIdSelection'
import iconPart from '@/assets/icon-part.svg'

const props = defineProps<{
  node: EbomBulkAllocatePartNode
}>()
const emit = defineEmits<{
  startSelection: [id: string]
  endSelection: [id: string]
}>()

const { t } = useI18n()
const { selectId, deselectId, selectedIds } = useIdSelection('ebom-bulk-allocation-parts')
const {
  selectedIds: openAssembyIds,
  selectId: markAssemblyAsOpen,
  deselectId: markAssemblyAsClosed,
} = useIdSelection('ebom-bulk-allocation-open-assemblies')

const hasChildren = computed(() => props.node.children.length > 0)
const ebomPart = computed(() => props.node.ebomPart)
const isSelectable = computed(() => props.node.isSelectable)
const isActive = computed(() => selectedIds.value.includes(props.node.ebomPart.id))
const isOpen = ref(openAssembyIds.value.includes(ebomPart.value.id))
watch(isOpen, (v) => {
  v ? markAssemblyAsOpen(ebomPart.value.id) : markAssemblyAsClosed(ebomPart.value.id)
})

const select = () => {
  if (!isSelectable.value) {
    return
  }

  const id = ebomPart.value.id
  isActive.value ? deselectId(id) : selectId(id)
  emit('startSelection', id)
}
const selectMulti = () => {
  if (!isSelectable.value) {
    return
  }

  emit('endSelection', ebomPart.value.id)
}

const startSelectectionId = ref<string>()
const endSelection = (lastId: string) => {
  if (!startSelectectionId.value) {
    return
  }

  let active = false
  let endId: string | undefined = undefined
  props.node.children.forEach((c) => {
    const ebomPartId = c.ebomPart.id
    if (ebomPartId == startSelectectionId.value && !active) {
      active = true
      endId = lastId
    }
    if (ebomPartId == lastId && !active) {
      active = true
      endId = startSelectectionId.value
    }

    if (active) {
      selectId(ebomPartId)
    }

    if (ebomPartId == endId) {
      active = false
    }
  })
}

const selectChildren = () => {
  isOpen.value = true
  addRecursively(props.node)
}
const addRecursively = (node: EbomBulkAllocatePartNode) => {
  if (node.isSelectable) {
    selectId(node.ebomPart.id)
  }
  node.children.forEach((c) => addRecursively(c))
}

const dragImage = new Image()
dragImage.src = iconPart

const dragStart = (e: DragEvent) => {
  if (isSelectable.value) {
    selectId(ebomPart.value.id)
  }

  const dataTransfer = e.dataTransfer as DataTransfer
  dataTransfer.setDragImage(dragImage, 30, 20)
  dataTransfer.dropEffect = 'copy'
}
</script>

<template>
  <v-list-item
    v-bind="$attrs"
    variant="text"
    density="compact"
    rounded
    :active="isActive"
    style="user-select: none"
    :draggable="true"
    @click.exact="select"
    @click.shift.exact="selectMulti"
    @click.ctrl.exact="selectChildren"
    @click.meta.exact="selectChildren"
    @dragstart="dragStart"
  >
    <template #prepend>
      <v-icon
        density="compact"
        :icon="hasChildren ? (isOpen ? 'folder_open' : 'folder') : 'settings'"
        :color="props.node.isOrHasAbberation ? 'error' : undefined"
        @click.stop="isOpen = !isOpen"
      />
    </template>
    <template #append>
      <v-chip
        v-if="!!ebomPart.article"
        :color="props.node.netAllocationMilliQuantity == 0 ? 'success' : 'error'"
        density="compact"
        class="count"
      >
        <article-quantity
          :milli-quantity="props.node.netAllocationMilliQuantity"
          :unit="ebomPart.unit"
          unit-abbreviated
        />
      </v-chip>
    </template>
    <v-list-item-title>
      <article-name :article="props.node.ebomPart" />
    </v-list-item-title>
  </v-list-item>

  <div v-if="hasChildren && isOpen" class="children">
    <ebom-bulk-allocate-part-tree-node
      v-for="n in props.node.children"
      :key="n.id"
      :node="n"
      @start-selection="(v: string) => (startSelectectionId = v)"
      @end-selection="endSelection"
    />
  </div>
</template>

<style scoped lang="scss">
.children {
  margin-left: 1.5em;
}
</style>

<script setup lang="ts">
import { useLazyQuery } from '@vue/apollo-composable'
import { Query } from '@/generated/graphql'
import gql from 'graphql-tag'
import { ref } from 'vue'

const fetchQuery = useLazyQuery<Query>(gql`
  query testException {
    exception
  }
`)
const loaded = ref(false)
function fetch() {
  loaded.value ? fetchQuery.refetch() : fetchQuery.load()
  loaded.value = true
}
</script>

<template>
  <v-icon
    icon="bug_report"
    variant="flat"
    style="opacity: 0.03; position: absolute; bottom: 10px"
    @click="fetch()"
  />
</template>

<style scoped lang="scss"></style>

<script setup lang="ts" generic="NODE extends DagNode">
import { useI18n } from 'vue-i18n'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import createPanZoom, { PanZoom } from 'panzoom'
import {
  drawDagEdgeInSVG,
  drawDagNodeInSVG,
  nodeHeightWithMargin,
  nodeWidthWithMargin,
  RenderableDagNode,
  RenderedDagNode,
} from '@/components/graphs/dag/svg'
import { v4 } from 'uuid'
import { DagNode } from '@/generated/graphql'

const props = defineProps<{
  nodes: NODE[]
  nodeText: (node: NODE) => string
  createNode: (nodeId: string, parentIds: string[]) => void
  isEditable: boolean
}>()
const emit = defineEmits<{
  nodes: [value: NODE[]]
}>()

const { t } = useI18n()

const diagramScene = ref<SVGGElement>()
const panzoom = ref<PanZoom>()
onMounted(() => {
  panzoom.value = createPanZoom(diagramScene.value as SVGGElement, {
    bounds: true,
    boundsPadding: 0.3,
  })
})
onUnmounted(() => {
  panzoom.value?.dispose()
})

const nodeList = computed<NODE[]>(() => props.nodes)
const nodeIdsBeingUpdated = ref<string[]>([])

function getNode(id: string): NODE {
  return nodeList.value.find((n) => n.id == id) as NODE
}

function isNodeBeingUpdated(id: string) {
  return nodeIdsBeingUpdated.value.includes(id)
}

function builRenderableNode(node: NODE, level: number): RenderableDagNode {
  const children = nodeList.value.filter((n) => n.parentIds.includes(node.id))

  return {
    id: node.id,
    text: props.nodeText(node),
    parentIds: node.parentIds,
    level: level,
    children: children.map((n) => builRenderableNode(n, level + 1)),
  }
}

const rootNodes = computed(() => {
  if (diagramScene.value == undefined || nodeList.value.length == 0) {
    return []
  }

  return nodeList.value.filter((n) => n.parentIds.length == 0).map((n) => builRenderableNode(n, 0))
})

const renderedNodes = ref<RenderedDagNode[]>([])
const renderedNodeIds = computed(() => renderedNodes.value.map((n) => n.id))

function calculateNodeHeight(n: RenderableDagNode): number {
  if (renderedNodeIds.value.includes(n.id)) {
    return 0
  }

  let height = 0
  n.children.forEach((c) => (height += calculateNodeHeight(c)))

  return Math.max(nodeHeightWithMargin, height)
}

function drawConnectionsToParents(node: RenderedDagNode) {
  const svg = diagramScene.value as SVGElement
  const parentIds = getNode(node.id).parentIds

  renderedNodes.value
    .filter((r) => parentIds.includes(r.id))
    .forEach((p) => {
      drawDagEdgeInSVG(svg, p, node, isNodeBeingUpdated(node.id))
    })
}

function drawDagNode(n: RenderableDagNode, startHeight: number) {
  const alreadyRenderedNode = renderedNodes.value.find((r) => r.id == n.id)
  if (alreadyRenderedNode) {
    drawConnectionsToParents(alreadyRenderedNode)
    return
  }

  const x = n.level * nodeWidthWithMargin
  const y = startHeight + nodeHeightWithMargin
  drawDagNodeInSVG(diagramScene.value as SVGElement, x, y, n, isNodeBeingUpdated(n.id))

  const justRenderedNode = { id: n.id, parentIds: n.parentIds, x, y }
  renderedNodes.value.push(justRenderedNode)
  drawConnectionsToParents(justRenderedNode)

  let childY = startHeight
  n.children.forEach((n1) => {
    const childNodeHeightBeforeDrawing = calculateNodeHeight(n1)
    drawDagNode(n1, childY)
    childY += childNodeHeightBeforeDrawing
  })
}

function resetRendering() {
  renderedNodes.value = []

  const d = diagramScene.value
  while (d?.firstChild) {
    d.removeChild(d.firstChild)
  }
}

watch(
  rootNodes,
  (v) => {
    resetRendering()

    let y = -1 * nodeHeightWithMargin
    v.forEach((n) => {
      const nodeHeightBeforeDrawing = calculateNodeHeight(n)
      drawDagNode(n, y)
      y += nodeHeightBeforeDrawing
    })
  },
  { deep: true },
)

const nodeIdsWithRandomnessSoMenusRerender = computed(() => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const justSoItRerendersEvenWithDeepUpdates = nodeIdsBeingUpdated.value
  return nodeList.value.map((n) => ({
    id: n.id,
    random: v4(),
  }))
})

function possibleParents(id: string): NODE[] {
  const node = getNode(id)
  const parentIds = node.parentIds
  const siblingIds = nodeList.value
    .filter((n) => n.parentIds.some((p) => parentIds.includes(p)))
    .map((n) => n.id)
  const childIds: string[] = []
  cascadingChildIds(childIds, node)

  return nodeList.value
    .filter((n) => n.id != id)
    .filter((n) => !parentIds.includes(n.id))
    .filter((n) => !siblingIds.includes(n.id))
    .filter((n) => !childIds.includes(n.id))
}
function cascadingChildIds(ids: string[], node: NODE) {
  nodeList.value
    .filter((n) => n.parentIds.includes(node.id))
    .forEach((n) => {
      ids.push(n.id)
      cascadingChildIds(ids, n)
    })
}

function addParent(childId: string, parentId: string) {
  getNode(childId).parentIds.push(parentId)
  nodeIdsBeingUpdated.value = [childId]
  emit('nodes', nodeList.value)
}

function currentParents(id: string): NODE[] {
  const parentIds = getNode(id).parentIds
  return nodeList.value.filter((n) => parentIds?.includes(n.id))
}

function deleteParentFromChild(childId: string, parentId: string) {
  let node = getNode(childId)
  node.parentIds = node.parentIds.filter((p) => p != parentId)
  emit('nodes', nodeList.value)
  nodeIdsBeingUpdated.value = [childId]
}

function possibleNodesAbove(id: string): NODE[] {
  const node = getNode(id)
  const firstParent = nodeList.value.find((n1) => node.parentIds.includes(n1.id))
  if (firstParent) {
    return nodeList.value
      .filter((n1) => n1.parentIds.includes(firstParent.id))
      .filter((n) => n.id != id)
  }

  return nodeList.value.filter((n) => n.parentIds.length == 0).filter((n) => n.id != id)
}

function moveNodeAbove(moveThisId: string, aboveThatId: string) {
  const currentIndex = nodeList.value.indexOf(getNode(moveThisId))
  const targetIndex = nodeList.value.indexOf(getNode(aboveThatId))
  if (currentIndex == targetIndex) {
    return
  }

  const nodes = nodeList.value
  nodes.splice(targetIndex, 0, nodes.splice(currentIndex, 1)[0])

  emit('nodes', nodes)
}
</script>

<template>
  <v-menu
    v-for="n in nodeIdsWithRandomnessSoMenusRerender"
    :key="n.random"
    :activator="`#dag-node-${n.id}-menu-activator`"
  >
    <v-list>
      <slot name="menu-items-prepend" :node="getNode(n.id)" />
      <template v-if="isEditable">
        <v-list-item @click="props.createNode(v4(), [n.id])">
          <v-list-item-title>
            {{ t('view.organization.bopProcess.menuAddChild') }}
          </v-list-item-title>
        </v-list-item>

        <v-menu rounded location="start">
          <template #activator="{ props: activatorProps }">
            <v-list-item v-bind="activatorProps" :disabled="possibleParents(n.id).length == 0">
              {{ t('view.organization.bopProcess.menuAddParent') }}
            </v-list-item>
          </template>

          <v-card>
            <v-card-text>
              <v-list>
                <v-list-item
                  v-for="p in possibleParents(n.id)"
                  :key="`${n.id}-${p.id}`"
                  @click="addParent(n.id, p.id)"
                >
                  {{ props.nodeText(p) }}
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-menu>

        <v-menu rounded location="start">
          <template #activator="{ props: activatorProps }">
            <v-list-item v-bind="activatorProps" :disabled="currentParents(n.id).length == 0">
              {{ t('view.organization.bopProcess.menuDeleteParent') }}
            </v-list-item>
          </template>

          <v-card>
            <v-card-text>
              <v-list>
                <v-list-item
                  v-for="p in currentParents(n.id)"
                  :key="`${n.id}-${p.id}`"
                  @click="deleteParentFromChild(n.id, p.id)"
                >
                  {{ props.nodeText(p) }}
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-menu>

        <v-menu rounded location="start">
          <template #activator="{ props: activatorProps }">
            <v-list-item v-bind="activatorProps" :disabled="possibleNodesAbove(n.id).length == 0">
              {{ t('view.organization.bopProcess.menuMoveAbove') }}
            </v-list-item>
          </template>

          <v-card>
            <v-card-text>
              <v-list>
                <v-list-item
                  v-for="a in possibleNodesAbove(n.id)"
                  :key="`${n.id}-${a.id}`"
                  @click="moveNodeAbove(n.id, a.id)"
                >
                  {{ props.nodeText(a) }}
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-menu>
      </template>
      <slot name="menu-items-append" :node="getNode(n.id)" />
    </v-list>
  </v-menu>

  <svg id="dag-node-diagram" width="100%" height="85%" xmlns="http://www.w3.org/2000/svg">
    <g id="diagramScene" ref="diagramScene" />
  </svg>
</template>

<style lang="scss">
#dag-node-diagram {
  outline: none;

  .dag-node-background {
    stroke: #777;
    stroke-width: 1px;
    fill: rgb(var(--v-theme-background));
    filter: drop-shadow(5px 3px 3px rgb(0 0 0 / 0.3));
  }

  @keyframes fade-in-background {
    from {
      fill: rgb(var(--v-theme-background));
    }
    to {
      fill: rgb(var(--v-theme-primary));
    }
  }

  .dag-node-background.dag-node-updated {
    animation-name: fade-in-background;
    animation-duration: 0.4s;
  }

  .dag-node-text {
    fill: rgb(var(--v-theme-on-background));
  }

  .dag-node-menu-activator {
    fill: rgb(var(--v-theme-on-background));
    cursor: pointer;
  }

  .dag-edge {
    fill: none;
    stroke: #aaa;
    stroke-width: 2px;
  }

  @keyframes fade-in-connection {
    from {
      stroke: rgb(var(--v-theme-background));
    }
    to {
      stroke: rgb(var(--v-theme-secondary));
    }
  }

  .dag-edge.dag-edge-updated {
    animation-name: fade-in-connection;
    animation-duration: 1.5s;
  }
}
</style>

<script setup lang="ts">
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed, watch } from 'vue'
import {
  EbomBulkAllocatePartTreeDataQuery,
  EbomBulkAllocatePartTreeDataQueryVariables,
} from '@/generated/graphql'
import { v4 } from 'uuid'
import { sortTree } from '@/app'
import EbomBulkAllocatePartTreeNode from '@/components/ebom/EbomBulkAllocatePartTreeNode.vue'
import useIdSelection from '@/useIdSelection'

const props = defineProps<{
  ebomId: string
}>()

const fetchQuery = useQuery<
  EbomBulkAllocatePartTreeDataQuery,
  EbomBulkAllocatePartTreeDataQueryVariables
>(
  gql`
    query EbomBulkAllocatePartTreeData($id: ID!) {
      article {
        ebom(id: $id) {
          ebomParts {
            id
            parentId
            articleNumber
            name
            revision
            unit
            milliQuantity
            article {
              id
            }
            articlePath {
              articleNumbers
            }
            stepAllocations {
              id
              milliQuantity
              article {
                unit
              }
            }
          }
        }
      }
    }
  `,
  () => ({
    id: props.ebomId,
  }),
)
const ebomParts = computed(() => fetchQuery.result.value?.article.ebom.ebomParts || [])

type LocalPart = EbomBulkAllocatePartTreeDataQuery['article']['ebom']['ebomParts'][0]
export type EbomBulkAllocatePartNode = {
  id: string
  ebomPart: LocalPart
  isSelectable: boolean
  netAllocationMilliQuantity: number
  isOrHasAbberation: boolean
  children: EbomBulkAllocatePartNode[]
}

const sortNodes = (nodes: EbomBulkAllocatePartNode[]) =>
  sortTree(
    nodes,
    (n) => n.children.length > 0,
    (n) => `${n.ebomPart.articleNumber}/${n.ebomPart.revision}`,
  )
const rootNodes = computed<EbomBulkAllocatePartNode[]>(() =>
  sortNodes(ebomParts.value.filter((p) => !p.parentId).map((p) => createNode(ebomParts.value, p))),
)
function createNode(parts: LocalPart[], part: LocalPart): EbomBulkAllocatePartNode {
  const children = parts.filter((p) => p.parentId == part.id).map((p) => createNode(parts, p))
  const netAllocationMilliQuantity = part.article
    ? part.stepAllocations.reduce((agg, c) => agg + c.milliQuantity, 0) - part.milliQuantity
    : 0
  const isOrHasAbberation = netAllocationMilliQuantity != 0 || children.some((c) => c.isOrHasAbberation)

  return {
    id: v4(),
    ebomPart: part,
    isSelectable: !!part.article,
    netAllocationMilliQuantity,
    isOrHasAbberation,
    children: sortNodes(children),
  }
}

const { selectId } = useIdSelection('ebom-bulk-allocation-open-assemblies')
watch(rootNodes, (v) => {
  if (v.length != 1) {
    return
  }

  selectId(v[0].ebomPart.id)
})
</script>

<template>
  <v-list density="compact">
    <ebom-bulk-allocate-part-tree-node v-for="n in rootNodes" :key="n.id" :node="n" />
  </v-list>
</template>

<style scoped lang="scss"></style>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, ref } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import { Query } from '@/generated/graphql'
import gql from 'graphql-tag'
import { useRoute, useRouter } from 'vue-router'
import Breadcrumb from '@/components/Breadcrumb.vue'
import EbomPartTree from '@/components/ebom/EbomPartTree.vue'
import EbomPartDetails from '@/components/ebom/EbomPartDetails.vue'
import EbomDiffDialog from '@/components/ebom/EbomDiffDialog.vue'
import BulkAllocationSuggestionActions from '@/components/ebomPartAllocationSuggestions/BulkAllocationSuggestionActions.vue'
import EbomBulkAllocateDialog from '@/components/ebom/EbomBulkAllocateDialog.vue'
import { ComponentExposed } from 'vue-component-type-helpers'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()

const bopId = computed(() => route.params.bopId as string)
const ebomId = computed(() => route.params.ebomId as string)
const ebomPartId = computed(() => route.params.ebomPartId as string | undefined)
const fetchQuery = useQuery<Query>(
  gql`
    query getEbom($id: ID!) {
      article {
        ebom(id: $id) {
          id
          name
          sourceId
          sourceType
        }
      }
    }
  `,
  () => ({
    id: ebomId.value,
  }),
  () => ({
    enabled: !!ebomId.value,
  }),
)
const ebom = computed(() => fetchQuery.result.value?.article.ebom)

function selectEbomPartId(id: string) {
  router.push({ params: { ebomPartId: id } })
}

const menuOpen = ref(false)

const bulkAllocateDialog = ref<ComponentExposed<typeof EbomBulkAllocateDialog>>()
</script>

<template>
  <breadcrumb />

  <v-menu v-model="menuOpen" :close-on-content-click="false">
    <template #activator="{ props }">
      <v-btn color="primary" v-bind="props" icon="menu" class="float-right" />
    </template>
    <v-list min-width="600">
      <v-list-item class="text-right">
        <v-btn class="mb-1" @click="bulkAllocateDialog?.open()">
          {{ t('component.ebomBulkAllocateDialog.title') }}
        </v-btn>
      </v-list-item>
      <v-list-item>
        <ebom-diff-dialog :bop-id="bopId" :current-ebom-id="ebomId" @close="menuOpen = false" />
      </v-list-item>
      <bulk-allocation-suggestion-actions :ebom-id="ebomId" />
    </v-list>
  </v-menu>

  <ebom-bulk-allocate-dialog ref="bulkAllocateDialog" :ebom-id="ebomId" :bop-id="bopId" />

  <h1>{{ t('entity.ebom.singular') }} {{ ebom?.name }}</h1>

  <v-row>
    <v-col cols="4">
      <ebom-part-tree
        v-if="ebomId"
        :ebom-id="ebomId"
        :selected-ebom-part-id="ebomPartId"
        @select-ebom-part-id="selectEbomPartId"
      />
    </v-col>
    <v-col>
      <ebom-part-details v-if="ebomPartId" :bop-id="bopId" :ebom-part-id="ebomPartId" />
    </v-col>
  </v-row>
</template>

<style scoped lang="scss"></style>

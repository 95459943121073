<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useQuery } from '@vue/apollo-composable'
import { OperatorViewDataQuery, OperatorViewDataQueryVariables } from '@/generated/graphql'
import gql from 'graphql-tag'
import { itemSortByName } from '@/app'
import { computed } from 'vue'
import UserProductionBatches from '@/components/productionBatch/UserProductionBatches.vue'

const { t } = useI18n()

const fetchQuery = useQuery<OperatorViewDataQuery, OperatorViewDataQueryVariables>(gql`
  query OperatorViewData {
    product {
      products {
        id
        name
        abbreviation
        description
        imageUploadId
        imageUpload {
          id
          imageUrl(options: { width: 500, height: 300 })
        }
      }
    }
  }
`)
const items = computed(() =>
  itemSortByName(fetchQuery.result.value?.product.products || [], (i) => i.name),
)
</script>

<template>
  <h1>{{ t('view.organization.operatorProduct.titleBatchesInProgress') }}</h1>
  <user-production-batches />

  <h1 class="mt-5">
    {{ t('view.organization.operatorProduct.title') }}
  </h1>

  <v-alert v-if="items.length == 0 && !fetchQuery.loading" type="info">
    {{ t('view.organization.operatorProduct.noData') }}
  </v-alert>

  <v-row>
    <v-col v-for="p in items" :key="p.id" xxl="2" xl="3" lg="3" sm="4" cols="6">
      <v-card>
        <router-link :to="{ name: 'operatorProductConfigurations', params: { productId: p.id } }">
          <v-img v-if="p.imageUpload?.imageUrl" :src="p.imageUpload.imageUrl" height="175px" />
        </router-link>
        <v-card-title>
          <router-link
            :to="{ name: 'operatorProductConfigurations', params: { productId: p.id } }"
            style="color: #000"
          >
            {{ p.name }}
          </router-link>
        </v-card-title>
        <v-card-text>
          {{ p.description }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            density="compact"
            size="small"
            icon="settings"
            @click="
              $router.push({
                name: 'operatorProductConfigurations',
                params: { productId: p.id },
              })
            "
          />
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<style scoped lang="scss"></style>

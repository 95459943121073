<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, reactive, ref, watch } from 'vue'
import { useMutation, useQuery } from '@vue/apollo-composable'
import {
  CreateProductConfiguration,
  CreateSite,
  Mutation,
  ProductConfigurationViewDataQuery,
  ProductConfigurationViewDataQueryVariables,
  UpdateProductConfiguration,
  UpdateSite,
} from '@/generated/graphql'
import gql from 'graphql-tag'
import useVuelidate from '@vuelidate/core'
import { authzIsOrgProductManagement, KeysOfType } from '@/app'
import TextField from '@/components/input/TextField.vue'
import { abbreviation, normalDescription, normalName, required, unique } from '@/validation'
import TextAreaField from '@/components/input/TextAreaField.vue'
import { useRoute } from 'vue-router'
import BopSelector from '@/components/bop/BopSelector.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import ImagePicker from '@/components/input/ImagePicker.vue'
import { merge } from 'lodash'
import { SelectOption } from '@/components/input/SelectField.vue'
import { ComponentExposed } from 'vue-component-type-helpers'
import UpsertDialog from '@/components/dialogs/UpsertDialog.vue'
import DeleteDialogNext from '@/components/dialogs/AsyncDeleteDialog.vue'
import AsyncDeleteDialog from '@/components/dialogs/AsyncDeleteDialog.vue'

type CreateModel = Partial<CreateProductConfiguration>
type UpdateModel = Partial<UpdateProductConfiguration>
const modelKeys: KeysOfType<keyof UpdateModel> = [
  'id',
  'name',
  'abbreviation',
  'description',
  'imageUploadId',
]
const { t } = useI18n()
const route = useRoute()
const productId = computed(() => route.params.productId as string)

type LocalSite = ProductConfigurationViewDataQuery['site']['sites'][0]
type LocalProdConfig =
  ProductConfigurationViewDataQuery['product']['product']['productConfigurations'][0]
type LocalBop = LocalProdConfig['bops'][0]
const fetchQuery = useQuery<
  ProductConfigurationViewDataQuery,
  ProductConfigurationViewDataQueryVariables
>(
  gql`
    query ProductConfigurationViewData($productId: ID!) {
      site {
        sites {
          id
          name
        }
      }
      product {
        product(id: $productId) {
          productConfigurations {
            id
            name
            abbreviation
            description
            imageUploadId
            imageUpload {
              id
              imageUrl(options: { width: 500, height: 300 })
            }
            bops {
              id
              site {
                id
                name
              }
              version
              versionStatus
            }
          }
        }
      }
    }
  `,
  () => ({
    productId: productId.value,
  }),
)
const productConfigs = computed<LocalProdConfig[]>(
  () => fetchQuery.result.value?.product.product.productConfigurations || [],
)
const sites = computed<LocalSite[]>(() => fetchQuery.result.value?.site.sites || [])

const validationRules = {
  name: { required, normalName },
  abbreviation: { required, abbreviation },
  description: { normalDescription },
}

const createModel = reactive<CreateModel>({})
const createTakenAbbreviations = computed(() => productConfigs.value.map((s) => s.abbreviation))
const createValidationRules = merge(validationRules, {
  abbreviation: { unique: unique(createTakenAbbreviations) },
})
const createValidation = useVuelidate<CreateModel>(createValidationRules, createModel)
const createMutation = useMutation<Mutation>(gql`
  mutation ProductConfigurationViewCreate($command: CreateProductConfiguration!) {
    product {
      createProductConfiguration(command: $command) {
        id
      }
    }
  }
`)
const createDialog = ref<ComponentExposed<typeof UpsertDialog<() => Promise<unknown>>>>()
const doCreate = async () => {
  modelKeys.forEach((k) => (createModel[k] = undefined))
  createModel.forProductId = productId.value
  await createDialog.value?.open(() =>
    createMutation.mutate({ command: createModel as CreateSite }).then(() => fetchQuery.refetch()),
  )
}

const updateModel = reactive<UpdateModel>({})
const updateTakenAbbreviations = computed(() =>
  productConfigs.value.filter((pc) => pc.id != updateModel.id).map((s) => s.abbreviation),
)
const updateValidationRules = merge(validationRules, {
  abbreviation: { unique: unique(updateTakenAbbreviations) },
})
const updateValidation = useVuelidate<UpdateModel>(updateValidationRules, updateModel)
const updateMutation = useMutation<Mutation>(gql`
  mutation ProductConfigurationViewUpdate($command: UpdateProductConfiguration!) {
    product {
      updateProductConfiguration(command: $command) {
        id
      }
    }
  }
`)
const updateDialog = ref<ComponentExposed<typeof UpsertDialog<() => Promise<unknown>>>>()
const doUpdate = async (item: LocalSite) => {
  modelKeys.forEach((k) => (updateModel[k] = item[k]))
  await updateDialog.value?.open(() =>
    updateMutation.mutate({ command: updateModel as UpdateSite }).then(() => fetchQuery.refetch()),
  )
}

const deleteMutation = useMutation<Mutation>(gql`
  mutation ProductConfigurationViewDelete($id: ID!) {
    product {
      deleteProductConfiguration(id: $id)
    }
  }
`)
const deleteDialog = ref<ComponentExposed<typeof DeleteDialogNext>>()
const doDelete = async (item: LocalProdConfig) => {
  await deleteDialog.value?.open(item.name, () =>
    deleteMutation.mutate({ id: item.id }).then(() => fetchQuery.refetch()),
  )
}

type BopPerConfigPerSite = {
  [key: string]: {
    [key: string]: {
      site: LocalSite
      bops: LocalBop[]
      selectedBopId: string | undefined
      options: SelectOption<LocalBop>[]
    }
  }
}
const bopsPerConfigPerSite = ref<BopPerConfigPerSite>({})
watch([sites, productConfigs], () => {
  bopsPerConfigPerSite.value = {}
  productConfigs.value.forEach((config) => {
    bopsPerConfigPerSite.value[config.id] = {}
    sites.value.forEach((site) => {
      const bops = config.bops
        .filter((bop) => bop.site.id == site.id)
        .sort((a, b) => b.version - a.version)
      const selectedBopId = bops[0]?.id
      const options = bops.map((b) => ({
        title: `${b.version} (${b.versionStatus})`,
        value: b,
      }))

      bopsPerConfigPerSite.value[config.id][site.id] = { site, bops, selectedBopId, options }
    })
  })
})
</script>

<template>
  <breadcrumb />
  <h1>
    {{ t('entity.productConfiguration.plural') }}
    <v-btn icon="add" color="primary" class="float-right" @click="doCreate()" />
  </h1>

  <v-alert v-if="productConfigs.length == 0 && !fetchQuery.loading" type="info">
    {{ t('component.dataTable.noData') }}
  </v-alert>
  <v-row>
    <v-col v-for="config in productConfigs" :key="config.id" xxl="2" xl="3" lg="3" sm="4" xs="6">
      <v-card>
        <v-img
          v-if="config.imageUpload?.imageUrl"
          :src="config.imageUpload.imageUrl"
          height="175px"
        />
        <v-card-title>{{ config.name }}</v-card-title>
        <v-card-text>
          <p>{{ config.description }}</p>

          <h3 class="mt-3">{{ t('entity.bop.plural') }}</h3>
          <bop-selector
            v-for="site in sites"
            :key="site.id"
            v-model="bopsPerConfigPerSite[config.id][site.id].selectedBopId"
            :product-config-id="config.id"
            :site-id="site.id"
            :label="site.name"
            :bops="bopsPerConfigPerSite[config.id][site.id].bops"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="authzIsOrgProductManagement"
            density="compact"
            size="small"
            icon="edit"
            @click="doUpdate(config)"
          />
          <v-btn
            v-if="authzIsOrgProductManagement"
            density="compact"
            size="small"
            icon="delete"
            @click="doDelete(config)"
          />
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>

  <async-delete-dialog
    ref="deleteDialog"
    :warning="t('entity.productConfiguration.deleteWarning')"
    hindrance
  />

  <upsert-dialog ref="createDialog" type="create" :validation="createValidation">
    <text-field
      v-model="createModel.name"
      :label="t('entity.productConfiguration.field.name')"
      required
      :validation="createValidation.name"
    />
    <text-field
      v-model="createModel.abbreviation"
      :label="t('entity.productConfiguration.field.abbreviation')"
      required
      :validation="createValidation.abbreviation"
    />
    <text-area-field
      v-model="createModel.description"
      :label="t('entity.productConfiguration.field.description')"
      :validation="createValidation.description"
    />
    <image-picker
      v-model="createModel.imageUploadId"
      :label="t('entity.productConfiguration.field.image')"
    />
  </upsert-dialog>

  <upsert-dialog ref="updateDialog" type="update" :validation="updateValidation">
    <text-field
      v-model="updateModel.name"
      required
      :label="t('entity.productConfiguration.field.name')"
      :validation="updateValidation.name"
    />
    <text-field
      v-model="updateModel.abbreviation"
      required
      :label="t('entity.productConfiguration.field.abbreviation')"
      :validation="updateValidation.abbreviation"
    />
    <text-area-field
      v-model="updateModel.description"
      :label="t('entity.productConfiguration.field.description')"
      :validation="updateValidation.description"
    />
    <image-picker
      v-model="updateModel.imageUploadId"
      :label="t('entity.productConfiguration.field.image')"
    />
  </upsert-dialog>
</template>

<style scoped lang="scss"></style>

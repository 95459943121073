<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, reactive, ref } from 'vue'
import { useMutation, useQuery } from '@vue/apollo-composable'
import {
  AdminUserViewCreateMutation,
  AdminUserViewCreateMutationVariables,
  AdminUserViewDataQuery,
  AdminUserViewDataQueryVariables,
  CreateUser,
  Mutation,
  UpdateSite,
  UpdateUser,
  User,
} from '@/generated/graphql'
import gql from 'graphql-tag'
import useVuelidate from '@vuelidate/core'
import { KeysOfType } from '@/app'
import ItemEditIcon from '@/components/items/ItemEditIcon.vue'
import TextField from '@/components/input/TextField.vue'
import { email, normalName, required } from '@/validation'
import UserEmail from '@/components/input/UserEmail.vue'
import UserPermissions from '@/components/user/UserPermissions.vue'
import { ComponentExposed } from 'vue-component-type-helpers'
import UpsertDialog from '@/components/dialogs/UpsertDialog.vue'
import DataTable from '@/components/dataTable/DataTable.vue'
import DataTableSearch from '@/components/dataTable/DataTableSearch.vue'
import DataTableCreateButton from '@/components/dataTable/DataTableCreateButton.vue'

type LocalUser = AdminUserViewDataQuery['account']['adminSearchUsers'][0]
type Model = Partial<UpdateUser>
const modelKeys: KeysOfType<keyof Model> = ['id', 'email', 'firstname', 'lastname']
const { t } = useI18n()

const userContains = ref<string>()
const fetchQuery = useQuery<AdminUserViewDataQuery, AdminUserViewDataQueryVariables>(
  gql`
    query AdminUserViewData($contains: String) {
      account {
        adminSearchUsers(criteria: { contains: $contains }) {
          id
          email
          firstname
          lastname
        }
      }
    }
  `,
  () => ({ contains: userContains.value }),
  { debounce: 500 },
)
const items = computed(() => fetchQuery.result.value?.account.adminSearchUsers || [])

const model = reactive<Model>({})
const validation = useVuelidate<Model>(
  {
    email: { required, email },
    firstname: { required, normalName },
    lastname: { required, normalName },
  },
  model,
)

const createMutation = useMutation<
  AdminUserViewCreateMutation,
  AdminUserViewCreateMutationVariables
>(gql`
  mutation AdminUserViewCreate($command: CreateUser!) {
    account {
      adminCreateUser(command: $command) {
        id
      }
    }
  }
`)
const createDialog = ref<ComponentExposed<typeof UpsertDialog<() => Promise<unknown>>>>()
const doCreate = async () => {
  modelKeys.forEach((k) => (model[k] = undefined))
  await createDialog.value?.open(() =>
    createMutation.mutate({ command: model as CreateUser }).then(() => {
      fetchQuery.refetch()
    }),
  )
}

const updateMutation = useMutation<Mutation>(gql`
  mutation AdminUserViewUpdate($command: UpdateUser!) {
    account {
      adminUpdateUser(command: $command) {
        id
      }
    }
  }
`)
const updateDialog = ref<ComponentExposed<typeof UpsertDialog<() => Promise<unknown>>>>()
const doUpdate = async (item: LocalUser) => {
  modelKeys.forEach((k) => (model[k] = item[k]))
  await updateDialog.value?.open(() =>
    updateMutation.mutate({ command: model as UpdateSite }).then(() => fetchQuery.refetch()),
  )
}

const headers = [
  {
    key: 'firstname',
    title: t('entity.user.field.firstname'),
  },
  {
    key: 'lastname',
    title: t('entity.user.field.lastname'),
  },
  {
    key: 'email',
    title: t('entity.user.field.email'),
  },
  {
    key: 'actions',
    width: '100',
    sortable: false,
  },
]
const sort = [
  { key: 'firstname', order: 'asc' },
  { key: 'lastname', order: 'asc' },
]

const userPermissionsUser = ref<User>()
</script>

<template>
  <h1>{{ t('entity.user.plural') }}</h1>

  <data-table
    :items="items"
    :headers="headers"
    :loading="fetchQuery.loading.value"
    :sort-by="sort"
    density="comfortable"
  >
    <template #header.actions>
      <div class="text-right">
        <data-table-search v-model="userContains" :loading="fetchQuery.loading" />
        <data-table-create-button @click="doCreate" />
      </div>
    </template>
    <template #item.actions="{ item }: { item: LocalUser }">
      <div class="text-right">
        <item-edit-icon @click="doUpdate(item)" />
        <v-btn density="compact" variant="flat" icon="shield" @click="userPermissionsUser = item" />
      </div>
    </template>
  </data-table>

  <upsert-dialog ref="createDialog" type="create" :validation="validation">
    <user-email v-model="model.email" :label="t('entity.user.field.email')" required />
    <text-field
      v-model="model.firstname"
      :label="t('entity.user.field.firstname')"
      required
      :validation="validation.firstname"
    />
    <text-field
      v-model="model.lastname"
      :label="t('entity.user.field.lastname')"
      required
      :validation="validation.lastname"
    />
  </upsert-dialog>

  <upsert-dialog ref="updateDialog" type="update" :validation="validation">
    <user-email
      v-model="model.email"
      :label="t('entity.user.field.email')"
      required
      :current-user-id="model.id!"
    />
    <text-field
      v-model="model.firstname"
      :label="t('entity.user.field.firstname')"
      required
      :validation="validation.firstname"
    />
    <text-field
      v-model="model.lastname"
      :label="t('entity.user.field.lastname')"
      required
      :validation="validation.lastname"
    />
  </upsert-dialog>

  <user-permissions v-model="userPermissionsUser" />
</template>

<style scoped lang="scss"></style>

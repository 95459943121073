<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import {
  EbomDiffTreeEbomPart,
  EbomPartDiff,
  EbomPartDiffNode,
} from '@/components/ebom/EbomDiffTree.vue'
import ArticleQuantity from '@/components/article/ArticleQuantity.vue'

const props = defineProps<{
  node: EbomPartDiffNode
}>()
const emit = defineEmits<{
  selectDiff: [value: EbomPartDiff]
}>()

const { t } = useI18n()

const diff = computed(() => props.node.diff)
const ebomPart = computed(() => (diff.value.inNew || diff.value.inOld) as EbomDiffTreeEbomPart)
const hasChildren = computed(() => props.node.children.length > 0)
const showAllocation = computed(() => !!props.node.diff.inOld?.article)
const newAllocated = computed(
  () => diff.value.inNew?.stepAllocations.reduce((agg, a) => agg + a.milliQuantity, 0) || 0,
)
const oldAllocated = computed(
  () => diff.value.inOld?.stepAllocations.reduce((agg, a) => agg + a.milliQuantity, 0) || 0,
)
const netAllocated = computed(() => {
  if (diff.value.status == 'deleted') {
    return oldAllocated.value
  }

  return newAllocated.value - ebomPart.value.milliQuantity
})

function selectEbomPartId() {
  if (diff.value.status == 'same') {
    return
  }

  emit('selectDiff', diff.value)
}
</script>

<template>
  <v-list-item
    v-if="props.node.isOrContainsDiff"
    v-bind="$attrs"
    variant="text"
    density="compact"
    :class="`ebom-diff-node status-${diff.status}`"
    rounded
    @click="selectEbomPartId()"
  >
    <template #prepend>
      <v-icon density="compact" :icon="hasChildren ? 'folder_open' : 'settings'" />
    </template>
    <template v-if="showAllocation" #append>
      <v-chip :color="netAllocated == 0 ? 'success' : 'error'" density="compact">
        <article-quantity :milli-quantity="netAllocated" :unit="ebomPart.unit" unit-abbreviated />
      </v-chip>
    </template>
    <v-list-item-title class="font-weight-bold">
      {{ diff.articleNumber }}/{{ diff.revision }}
    </v-list-item-title>
    <v-list-item-subtitle>
      {{ diff.name }}
    </v-list-item-subtitle>
  </v-list-item>

  <div class="ebom-diff-node-children">
    <ebom-diff-tree-node
      v-for="n in props.node.children"
      :key="n.id"
      :node="n"
      @select-diff="(v) => $emit('selectDiff', v)"
    />
  </div>
</template>

<style lang="scss">
.ebom-diff-node {
  &.status-new {
    background: rgba(var(--v-theme-success), var(--v-activated-opacity));
  }
  &.status-deleted {
    background: rgba(var(--v-theme-error), var(--v-activated-opacity));
  }
  &.status-changed {
    background: rgba(var(--v-theme-warning), var(--v-activated-opacity));
  }
}
.ebom-diff-node-changed-subsets {
  margin-left: 0.5em;
}
.ebom-diff-node-children {
  margin-left: 1.5em;
}
</style>

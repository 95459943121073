<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, ref } from 'vue'
import { useMutation, useQuery } from '@vue/apollo-composable'
import { Mutation, StepToolbarDataQuery, StepToolbarDataQueryVariables } from '@/generated/graphql'
import gql from 'graphql-tag'
import AsyncDeleteDialog from '@/components/dialogs/AsyncDeleteDialog.vue'
import DeleteDialogNext from '@/components/dialogs/AsyncDeleteDialog.vue'
import { ComponentExposed } from 'vue-component-type-helpers'

type LocalStep = StepToolbarDataQuery['product']['step']

const props = defineProps<{
  stepId: string
}>()
const emit = defineEmits<{
  deleted: []
}>()
const { t } = useI18n()

// @todo copy step

const fetchQuery = useQuery<StepToolbarDataQuery, StepToolbarDataQueryVariables>(
  gql`
    query StepToolbarData($id: ID!) {
      product {
        step(id: $id) {
          id
          name
        }
      }
    }
  `,
  () => ({
    id: props.stepId,
  }),
)
const step = computed(() => fetchQuery.result.value?.product.step)

const deleteMutation = useMutation<Mutation, { id: string }>(
  gql`
    mutation deleteStep($id: ID!) {
      product {
        deleteStep(id: $id)
      }
    }
  `,
  { refetchQueries: ['StepTreeData'] },
)
const deleteDialog = ref<ComponentExposed<typeof DeleteDialogNext>>()
const doDelete = async (item: LocalStep) => {
  await deleteDialog.value?.open(item.name, () =>
    deleteMutation.mutate({ id: item.id }).then(() => emit('deleted')),
  )
}
</script>

<template>
  <div v-if="!!step" class="step-toolbar">
    <v-btn
      icon="delete"
      density="compact"
      variant="flat"
      :title="t('component.stepToolbar.buttonDelete')"
      :loading="deleteMutation.loading.value"
      @click="doDelete(step)"
    />
  </div>

  <async-delete-dialog ref="deleteDialog" />
</template>

<style scoped lang="scss">
.step-toolbar {
  position: absolute;
  right: 0;
  top: 0;
}
</style>

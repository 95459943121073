<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { floor } from 'lodash'
import { computed } from 'vue'

const props = defineProps<{
  seconds: number
}>()

const asString = computed(() => {
  let remainder = props.seconds
  let ret = ''
  const units = [
    { unit: 'd', count: 86400 },
    { unit: 'h', count: 3600 },
    { unit: 'm', count: 60 },
    { unit: 's', count: 1 },
  ]
  units.forEach(({ unit, count }) => {
    if (remainder < count) {
      return
    }

    const units = floor(remainder / count)
    remainder -= units * count
    ret += `${units}${unit}`
  })

  return ret
})

const { t } = useI18n()
</script>

<template>
  {{ asString }}
</template>

<style scoped lang="scss"></style>

import { DagNode } from '@/generated/graphql'

export type RenderableDagNode = DagNode & {
  text: string
  children: RenderableDagNode[]
  level: number
}

export type RenderedDagNode = {
  id: string
  parentIds: string[]
  x: number
  y: number
}

export const nodeWidth = 200
export const nodeHorizontalMargin = 40
export const nodeWidthWithMargin = nodeWidth + nodeHorizontalMargin
export const nodeHeight = 50
export const nodeVerticalMargin = 7
export const nodeHeightWithMargin = nodeHeight + nodeVerticalMargin
const pathOffset = 30

export function drawDagNodeInSVG(
  svg: SVGElement,
  x: number,
  y: number,
  n: RenderableDagNode,
  isUpdated: boolean,
) {
  const id = `dag-node-${n.id}`
  const background = document.createElementNS('http://www.w3.org/2000/svg', 'rect')
  background.setAttribute(
    'class',
    `${id} dag-node-background ${isUpdated ? 'dag-node-updated' : ''}`,
  )
  background.setAttribute('x', `${x}`)
  background.setAttribute('y', `${y}`)
  background.setAttribute('width', `${nodeWidth}`)
  background.setAttribute('height', `${nodeHeight}`)
  background.setAttribute('rx', '7')
  background.setAttribute('ry', '7')
  svg.appendChild(background)

  let nodeText = n.text
  if (nodeText.length > 23) {
    nodeText = n.text.substring(0, 21) + '...'
  }

  const txt = document.createElementNS('http://www.w3.org/2000/svg', 'text')
  txt.setAttribute('class', `${id} dag-node-text`)
  txt.setAttribute('x', `${x + 6}`)
  txt.setAttribute('y', `${y + 30}`)
  txt.setAttribute('width', `${nodeWidth - 12}`)
  txt.setAttribute('height', `${nodeHeight - 6}`)
  txt.appendChild(document.createTextNode(nodeText))
  svg.appendChild(txt)

  const menuActivator = document.createElementNS('http://www.w3.org/2000/svg', 'text')
  menuActivator.id = `${id}-menu-activator`
  menuActivator.setAttribute('class', `${id} dag-node-menu-activator material-icons`)
  menuActivator.setAttribute('style', 'font-size: 35px; height: 35px; width: 35px;')
  menuActivator.setAttribute('x', `${x + nodeWidth - 33}`)
  menuActivator.setAttribute('y', `${y + nodeHeight - 8}`)
  menuActivator.setAttribute('width', `${nodeWidth}`)
  menuActivator.setAttribute('height', `${nodeHeight}`)
  menuActivator.appendChild(document.createTextNode('arrow_drop_down'))
  svg.appendChild(menuActivator)
}

export function drawDagEdgeInSVG(
  svg: SVGElement,
  parent: RenderedDagNode,
  child: RenderedDagNode,
  isUpdated: boolean,
) {
  const inX = child.x
  const inY = child.y + nodeHeight / 2
  const inX1 = inX - pathOffset
  const outX = parent.x + nodeWidth
  const outX1 = outX + pathOffset
  const outY = parent.y + nodeHeight / 2

  const path = document.createElementNS('http://www.w3.org/2000/svg', 'path')
  path.setAttribute('d', `M ${inX} ${inY} C ${inX1} ${inY} ${outX1} ${outY} ${outX} ${outY}`)
  path.setAttribute('class', `dag-edge ${isUpdated ? 'dag-edge-updated' : ''}`)
  svg.appendChild(path)
}

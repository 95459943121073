<script setup lang="ts">
import FormField from '@/components/input/FormField.vue'
import { SelectOption } from '@/components/input/SelectField.vue'
import {
  EbomPartPickerDataQuery,
  EbomPartPickerDataQueryVariables,
  EbomPartSearchQuery,
} from '@/generated/graphql'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed, reactive, ref, watch } from 'vue'
import { debounce } from 'lodash'

const props = defineProps<{
  ebomId: string
  modelValue?: EbomPartPickerOption
  label?: string
}>()
const emit = defineEmits<{
  'update:modelValue': [value: EbomPartPickerOption]
}>()

export type EbomPartPickerOption = EbomPartPickerDataQuery['article']['ebomParts'][0]

const query = reactive<EbomPartSearchQuery>({
  ebomId: props.ebomId,
  searchString: '',
})
const searchString = ref('')
const debouncer = debounce(() => {
  query.searchString = searchString.value
}, 300)
watch(searchString, debouncer)

const fetchQuery = useQuery<EbomPartPickerDataQuery, EbomPartPickerDataQueryVariables>(
  gql`
    query EbomPartPickerData($query: EbomPartSearchQuery!) {
      article {
        ebomParts(query: $query) {
          id
          articleNumber
          revision
          name
          articlePath {
            articleNumbers
          }
          article {
            id
          }
        }
      }
    }
  `,
  () => ({ query }),
  () => ({ enabled: !!query.searchString }),
)
const items = computed<SelectOption<EbomPartPickerOption>[]>(
  () =>
    fetchQuery.result.value?.article.ebomParts
      .filter((p) => !!p.article)
      .map((p) => ({
        title: 'ignored',
        value: p,
      })) || [],
)
</script>

<template>
  <form-field :label="props.label">
    <v-autocomplete
      hide-details
      v-bind="$attrs"
      :model-value="props.modelValue"
      :items="items"
      auto-select-first
      no-filter
      :loading="fetchQuery.loading.value"
      no-data-text="Type to search"
      @update:search="(v) => (searchString = v)"
      @update:model-value="(v) => $emit('update:modelValue', v)"
    >
      <template #selection="{ item }: { item: SelectOption<EbomPartPickerOption> }">
        {{ item.value.articleNumber }}/{{ item.value.revision }} {{ item.value.name }}
      </template>
      <template #item="{ item, props }: { item: SelectOption<EbomPartPickerOption>; props: any }">
        <v-list-item
          v-bind="props"
          :title="`${item.value.articleNumber}/${item.value.revision} ${item.value.name}`"
          :subtitle="item.value.articlePath.articleNumbers.join(' > ')"
        />
      </template>
    </v-autocomplete>
  </form-field>
</template>

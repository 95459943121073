<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ArticleUnit } from '@/generated/graphql'
import { computed } from 'vue'

const props = defineProps<{
  modelValue: ArticleUnit
  abbreviated?: boolean
}>()

const { t } = useI18n()
const abbreviationPrefix = computed(() => (props.abbreviated ? 'abbreviated' : 'full'))
const value = computed(() =>
  t(`entity.article.unit.${abbreviationPrefix.value}.${props.modelValue}`),
)
</script>

<template>
  {{ value }}
</template>

<style scoped lang="scss"></style>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, ref, unref, watch } from 'vue'
import { useMutation, useQuery } from '@vue/apollo-composable'
import {
  BopViewCreateMutation,
  BopViewCreateMutationVariables,
  BopViewDataQuery,
  BopViewDataQueryVariables,
  BopViewDeleteMutation,
  BopViewDeleteMutationVariables,
  VersionStatus,
} from '@/generated/graphql'
import gql from 'graphql-tag'
import Breadcrumb from '@/components/Breadcrumb.vue'
import { useRoute } from 'vue-router'
import NotificationSaved from '@/components/notifications/NotificationSaved.vue'
import ItemDeleteIcon from '@/components/items/ItemDeleteIcon.vue'
import BopReleaseConfirmation from '@/components/bop/BopReleaseConfirmation.vue'
import DataTable from '@/components/dataTable/DataTable.vue'
import AsyncDeleteDialog from '@/components/dialogs/AsyncDeleteDialog.vue'
import DeleteDialogNext from '@/components/dialogs/AsyncDeleteDialog.vue'
import { ComponentExposed } from 'vue-component-type-helpers'
import DataTableCreateButton from '@/components/dataTable/DataTableCreateButton.vue'

type LocalBOP = BopViewDataQuery['product']['productConfiguration']['bopsAtSite'][0]

const { t } = useI18n()
const route = useRoute()

const productId = computed(() => route.params.productId as string)
const configId = computed(() => route.params.productConfigId as string)
const siteId = computed(() => route.params.siteId as string)
const fetchQuery = useQuery<BopViewDataQuery, BopViewDataQueryVariables>(
  gql`
    query BOPViewData($configId: ID!, $siteId: ID!) {
      product {
        productConfiguration(id: $configId) {
          bopsAtSite(siteId: $siteId) {
            id
            version
            versionStatus
          }
        }
      }
    }
  `,
  () => ({
    configId: configId.value,
    siteId: siteId.value,
  }),
)
const items = computed(() => fetchQuery.result.value?.product.productConfiguration.bopsAtSite || [])

const notifySaved = ref(false)

const canCreate = computed(
  () =>
    items.value.length == 0 ||
    [...items.value].sort((a, b) => b.version - a.version)[0].versionStatus ==
      VersionStatus.Released,
)
const draftNewVersionMutation = useMutation<BopViewCreateMutation, BopViewCreateMutationVariables>(
  gql`
    mutation BOPViewCreate($configId: ID!, $siteId: ID!) {
      product {
        draftNewBOPVersion(forProductConfigId: $configId, atSiteId: $siteId) {
          id
        }
      }
    }
  `,
)
function draftNewVersion() {
  draftNewVersionMutation
    .mutate({
      configId: unref(configId),
      siteId: unref(siteId),
    })
    .then(() => {
      notifySaved.value = true
      fetchQuery.refetch()
    })
}

const releaseDialogOpen = ref(false)
const selectedBopId = ref<string>()
watch(selectedBopId, () => {
  releaseDialogOpen.value = true
})

const deleteLatestVersionMutation = useMutation<
  BopViewDeleteMutation,
  BopViewDeleteMutationVariables
>(gql`
  mutation BOPViewDelete($configId: ID!, $siteId: ID!) {
    product {
      deleteLatestUnreleasedBOPVersion(forProductConfigId: $configId, atSiteId: $siteId)
    }
  }
`)
const deleteDialog = ref<ComponentExposed<typeof DeleteDialogNext>>()
const doDelete = async (item: LocalBOP) => {
  await deleteDialog.value?.open(item.version.toString(), () =>
    deleteLatestVersionMutation
      .mutate({ configId: configId.value, siteId: siteId.value })
      .then(() => fetchQuery.refetch()),
  )
}

const headers = [
  {
    key: 'version',
    title: t('entity.bop.field.version'),
  },
  {
    key: 'versionStatus',
    title: t('entity.bop.field.versionStatus'),
  },
  {
    key: 'actions',
    width: '100',
    sortable: false,
  },
]
const sort = [{ key: 'version', order: 'desc' }]
</script>

<template>
  <breadcrumb />

  <h1>{{ t('entity.bop.plural') }}</h1>

  <data-table
    :items="items"
    :headers="headers"
    :loading="fetchQuery.loading.value"
    :sort-by="sort"
    density="comfortable"
  >
    <template #header.actions>
      <div class="text-right">
        <v-tooltip v-if="!canCreate">
          <template #activator="{ props }">
            <v-icon v-bind="props" icon="info" color="grey" class="mr-1" />
          </template>

          <span>
            {{ t('view.organization.bops.cannotCreateMessage') }}
          </span>
        </v-tooltip>
        <data-table-create-button :disabled="!canCreate" @click="draftNewVersion()" />
      </div>
    </template>
    <template #item.actions="{ item }: { item: LocalBOP }">
      <div class="text-right">
        <item-delete-icon
          v-if="item.versionStatus != VersionStatus.Released"
          @click="doDelete(item)"
        />
        <v-btn
          v-if="item.versionStatus != VersionStatus.Released"
          variant="flat"
          density="compact"
          icon="verified"
          :title="t('view.organization.bops.releaseTooltip')"
          @click="selectedBopId = item.id"
        />
      </div>
    </template>
  </data-table>

  <bop-release-confirmation
    v-if="!!selectedBopId"
    v-model="releaseDialogOpen"
    :product-id="productId"
    :product-configuration-id="configId"
    :bop-id="selectedBopId"
    max-width="500"
    @release="fetchQuery.refetch()"
  />

  <async-delete-dialog ref="deleteDialog" :warning="t('entity.bop.deleteWarning')" hindrance />

  <notification-saved v-model="notifySaved" />
</template>

<style scoped lang="scss">
.title-container {
  position: relative;

  .create-button {
    position: absolute;
    top: 11px;
    right: 0;
  }
}
</style>

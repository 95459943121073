<script setup lang="ts">
import { useQuery } from '@vue/apollo-composable'
import { Query } from '@/generated/graphql'
import gql from 'graphql-tag'
import { computed, ref } from 'vue'
import FormField from '@/components/input/FormField.vue'
import UploadPicker, { PickedUpload } from '@/components/upload/UploadPicker.vue'
import { MimeTypeFilter } from '@/app'

const props = defineProps<{
  modelValue?: string
  label: string
}>()
const emit = defineEmits<{
  'update:modelValue': [value: string | undefined]
}>()

const imageMaxDimension = 75
const id = computed(() => props.modelValue)
const fetchQuery = useQuery<Query>(
  gql`
    query getUploadUrlForImage($id: ID!, $maxDimension: Int!) {
      upload {
        upload(id: $id) {
          id
          imageUrl(options: { width: $maxDimension, height: $maxDimension })
        }
      }
    }
  `,
  () => ({
    id: id.value,
    maxDimension: imageMaxDimension,
  }),
  () => ({
    enabled: !!id.value,
  }),
)
const upload = computed(() => fetchQuery.result.value?.upload.upload)

const uploadPickerOpen = ref(false)
function selectUpload(upload: PickedUpload) {
  emit('update:modelValue', upload.id)
}
</script>

<template>
  <form-field :label="props.label">
    <div class="d-flex align-center">
      <v-btn icon="edit" class="mr-5" @click="uploadPickerOpen = true" />
      <template v-if="!!upload && !!props.modelValue">
        <div>
          <v-img
            :src="upload.imageUrl"
            :height="`${imageMaxDimension}px`"
            :width="`${imageMaxDimension}px`"
            class="text-center align-center"
          >
            <v-btn
              variant="outlined"
              color="white"
              icon="delete"
              density="compact"
              @click="emit('update:modelValue', undefined)"
            />
          </v-img>
        </div>
      </template>
    </div>
  </form-field>
  <upload-picker
    v-model="uploadPickerOpen"
    pick-one
    :mime-types="[MimeTypeFilter.IMAGE_PNG, MimeTypeFilter.IMAGE_JPEG, MimeTypeFilter.IMAGE_WEBP]"
    @pick-one="selectUpload"
  />
</template>

<style scoped lang="scss"></style>

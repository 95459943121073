<script lang="ts" setup>
import { computed } from 'vue'

const loggingIn = computed(() => false)
</script>

<template>
  <v-dialog v-model="loggingIn" width="300" persistent>
    <v-card>
      <v-card-title>Log in</v-card-title>
      <v-card-text> Your login is being processed</v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'
import { computed, unref, watch } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import { Organization, Query } from '@/generated/graphql'
import gql from 'graphql-tag'
import { currentOrganization } from '@/app'

const route = useRoute()
const slug = computed<string | undefined>(() => route.params.organizationSlug as string)
const fetchQuery = useQuery<Query>(
  gql`
    query getOrganizationInfo($slug: String!) {
      account {
        organizationBySlug(slug: $slug) {
          id
          name
          slug
        }
      }
    }
  `,
  () => ({
    slug: unref(slug) || ''
  }),
  () => ({
    enabled: !!slug.value
  })
)
const organization = computed<Organization | undefined>(
  () => fetchQuery.result.value?.account.organizationBySlug as Organization | undefined
)
watch(organization, (v) => (currentOrganization.value = v))
watch(slug, (v) => {
  if (!v) {
    currentOrganization.value = undefined
  }
})
</script>

<template>
  <span v-if="!!currentOrganization" class="mr-2 text-overline">
    {{ currentOrganization.name }}
  </span>
</template>

<style scoped lang="scss"></style>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'
import { EbomDiffTreeDataQuery, EbomDiffTreeDataQueryVariables } from '@/generated/graphql'
import { isEqual } from 'lodash'
import EbomDiffTreeNode from '@/components/ebom/EbomDiffTreeNode.vue'
import { v4 } from 'uuid'
import { sortTree } from '@/app'
import EbomDiffSameParts from '@/components/ebom/EbomDiffSameParts.vue'

const props = defineProps<{
  oldEbomId: string
  newEbomId: string
}>()
const emit = defineEmits<{
  selectDiff: [value: EbomPartDiff]
}>()

const { t } = useI18n()

const fetchQuery = useQuery<EbomDiffTreeDataQuery, EbomDiffTreeDataQueryVariables>(
  gql`
    query EbomDiffTreeData($oldEbomId: ID!, $newEbomId: ID!) {
      article {
        oldEbom: ebom(id: $oldEbomId) {
          ...ebomPartData
        }
        newEbom: ebom(id: $newEbomId) {
          ...ebomPartData
        }
      }
    }

    fragment ebomPartData on Ebom {
      id
      name
      ebomParts {
        id
        article {
          id
        }
        articleNumber
        unit
        revision
        name
        milliQuantity
        articlePath {
          articleNumbers
        }
        stepAllocations {
          id
          milliQuantity
          article {
            unit
          }
        }
      }
    }
  `,
  () => ({
    oldEbomId: props.oldEbomId,
    newEbomId: props.newEbomId,
  }),
)
const eboms = computed(() => fetchQuery.result.value?.article)
const oldEbomParts = computed(() => fetchQuery.result.value?.article.oldEbom.ebomParts || [])
const newEbomParts = computed(() => fetchQuery.result.value?.article.newEbom.ebomParts || [])

export type EbomDiffTreeEbomPart = EbomDiffTreeDataQuery['article']['oldEbom']['ebomParts'][0]
type EbomPartComparison = {
  articleNumber: string
  revision: string
  articlePath: {
    articleNumbers: string[]
  }
}
type DiffStatus = 'deleted' | 'new' | 'changed' | 'same'
export type EbomPartDiff = {
  status: DiffStatus
  articleNumber: string
  revision: string
  name?: string
  articlePath: {
    articleNumbers: string[]
  }
  inOld?: EbomDiffTreeEbomPart
  inNew?: EbomDiffTreeEbomPart
}
export type EbomPartDiffNode = {
  id: string
  diff: EbomPartDiff
  isOrContainsDiff: boolean
  children: EbomPartDiffNode[]
}

const sortEbomParts = (nodes: EbomPartDiffNode[]) =>
  sortTree(
    nodes,
    (n) => n.children.length > 0,
    (n) => `${n.diff.articleNumber}/${n.diff.revision}`,
  )
const diffs = computed<EbomPartDiff[]>(() => {
  const diffs: EbomPartDiff[] = []
  oldEbomParts.value.forEach((p) => addDiffNode(diffs, p))
  newEbomParts.value.forEach((p) => addDiffNode(diffs, p))
  return diffs
})
const addDiffNode = (aggregator: EbomPartDiff[], part: EbomDiffTreeEbomPart) => {
  const loosePartComparator = (p: EbomPartComparison) =>
    p.articleNumber == part.articleNumber &&
    isEqual(p.articlePath.articleNumbers, part.articlePath.articleNumbers)
  const strictComparator = (p: EbomPartComparison) =>
    p.articleNumber == part.articleNumber &&
    p.revision == part.revision &&
    isEqual(p.articlePath.articleNumbers, part.articlePath.articleNumbers)

  const existing = aggregator.find(loosePartComparator)
  if (existing) {
    return
  }

  const inOld = oldEbomParts.value.find(strictComparator)
  const inNew = newEbomParts.value.find(loosePartComparator)
  let status: DiffStatus = 'same'
  if (!inNew) {
    status = 'deleted'
  }
  if (!inOld) {
    status = 'new'
  }
  if (
    !!inNew &&
    !!inOld &&
    (inNew.milliQuantity != inOld.milliQuantity || inNew.revision != inOld.revision)
  ) {
    status = 'changed'
  }

  aggregator.push({
    status,
    articleNumber: part.articleNumber,
    revision: part.revision,
    name: part.name,
    articlePath: part.articlePath,
    inOld,
    inNew,
  })
}

const diffTreeRootNodes = computed<EbomPartDiffNode[]>(() => {
  const nodes = diffs.value
    .filter((d) => d.articlePath.articleNumbers.length == 1)
    .map((d) => createNode(diffs.value, d))
  return sortEbomParts(nodes)
})
function createNode(diffs: EbomPartDiff[], diff: EbomPartDiff): EbomPartDiffNode {
  const desiredArticlePathLength = diff.articlePath.articleNumbers.length + 1

  const children = diffs
    .filter((p) => p.articlePath.articleNumbers.length == desiredArticlePathLength)
    .filter((p) => {
      const nodeParentArticleNumbers = p.articlePath.articleNumbers.slice(0, -1)
      return isEqual(nodeParentArticleNumbers, diff.articlePath.articleNumbers)
    })
    .map((p) => createNode(diffs, p))
  const isOrContainsDiff = children.some((c) => c.isOrContainsDiff) || diff.status != 'same'

  return {
    id: v4(),
    diff,
    isOrContainsDiff,
    children: sortEbomParts(children),
  }
}
const noDifferences = computed(() => !diffTreeRootNodes.value.some((n) => n.isOrContainsDiff))
</script>

<template>
  <v-list density="compact">
    <ebom-diff-same-parts
      v-if="eboms"
      :old-ebom-name="eboms?.oldEbom.name"
      :new-ebom-name="eboms?.newEbom.name"
      :diffs="diffs"
    />
    <ebom-diff-tree-node
      v-for="n in diffTreeRootNodes"
      :key="n.id"
      :node="n"
      @select-diff="(v) => $emit('selectDiff', v)"
    />
  </v-list>
  <v-alert v-if="noDifferences && !fetchQuery.loading.value" type="info">
    {{ t('component.ebomDiffTree.noDifferences') }}
  </v-alert>
</template>

<style scoped lang="scss"></style>

<script setup lang="ts">
import { RouterView } from 'vue-router'
import { ref, watch } from 'vue'
import MainNavigationDrawer from '@/components/layout/MainNavigationDrawer.vue'
import MainAppBar from '@/components/layout/MainAppBar.vue'
import { currentOrganization, currentUserAndPermissions, Tracker } from '@/app'
import LoginOverlay from '@/components/layout/LoginOverlay.vue'
import * as Sentry from '@sentry/vue'
import UserInfo from '@/components/layout/UserInfo.vue'
import { CurrentUserAndPermissions } from '@/generated/graphql'
import ApiErrorSnackbar from '@/components/layout/ApiErrorSnackbar.vue'
import { apiErrors } from '@/apollo'
import UploadProgress from '@/components/upload/UploadProgress.vue'

const navbarOpen = ref(false)

watch(
  currentUserAndPermissions,
  (v?) => {
    if (!v) {
      return
    }

    Tracker.track('login', { userId: v.user.id })
    Sentry.setUser({ id: v.user.id })
  },
  { immediate: true, deep: true },
)
watch(
  currentOrganization,
  (v?) => {
    Sentry.setTag('organizationId', v?.id)
    Sentry.setTag('organizationName', v?.name)
  },
  { immediate: true, deep: true },
)
</script>

<template>
  <v-layout class="main-layout">
    <login-overlay />
    <user-info
      @user="
        (v: CurrentUserAndPermissions) => {
          currentUserAndPermissions = v
        }
      "
    />
    <main-app-bar @toggle-drawer="navbarOpen = !navbarOpen" />
    <main-navigation-drawer v-model="navbarOpen" />

    <v-main>
      <div class="router-view">
        <RouterView />
      </div>
    </v-main>

    <api-error-snackbar v-model="apiErrors" />
    <upload-progress />
  </v-layout>
</template>

<style>
html,
body,
.router-view {
  height: 100%;
  min-height: 100%;
}

.router-view {
  height: calc(100vh - 144px);
  margin: 20px;
}

h1 {
  font-size: 40px;
  margin-bottom: 15px;
}

a,
a:visited,
a:active,
a:hover {
  color: rgb(var(--v-theme-primary));
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

@media print {
  .router-view,
  .v-main {
    margin: 0 !important;
    padding: 0 !important;
  }
}
</style>

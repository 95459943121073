<script setup lang="ts" generic="MM">
import { ArticleUnit } from '@/generated/graphql'
import ArticleUnitTag from '@/components/article/ArticleUnitTag.vue'
import MilliQuantityField from '@/components/input/MilliQuantityField.vue'

const props = defineProps<{
  unit?: ArticleUnit
}>()
</script>

<template>
  <milli-quantity-field v-bind="$attrs">
    <template v-if="props.unit" #append>
      <article-unit-tag :model-value="props.unit" abbreviated />
    </template>
    <template v-for="(_, slot) of $slots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </milli-quantity-field>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed, ref, watch } from 'vue'
import { ImagesViewerDataQuery, ImagesViewerDataQueryVariables } from '@/generated/graphql'

const props = defineProps<{
  modelValue?: string
  uploadIds: string[]
  selectedUploadId?: string
}>()
defineEmits<{
  'update:modelValue': [value: string | undefined]
}>()

const { t } = useI18n()

const height = Math.round(document.body.scrollHeight * 0.8)
const width = Math.round(document.body.scrollWidth * 0.8)
const fetchQuery = useQuery<ImagesViewerDataQuery, ImagesViewerDataQueryVariables>(
  gql`
    query ImagesViewerData($ids: [ID!]!, $height: Int!, $width: Int!) {
      upload {
        uploads(ids: $ids) {
          id
          name
          imageUrl(options: { height: $height, width: $width })
        }
      }
    }
  `,
  () => ({
    ids: props.uploadIds,
    height,
    width,
  }),
)
const uploads = computed(() => fetchQuery.result.value?.upload.uploads || [])
const windowModel = ref(0)

watch(
  () => [props.selectedUploadId, uploads.value],
  () => {
    if (uploads.value.length == 0) {
      return
    }

    const index = uploads.value.findIndex((u) => u.id == props.selectedUploadId)
    if (index >= 0) {
      windowModel.value = index
    }
  },
)
</script>

<template>
  <v-overlay
    :model-value="!!props.modelValue"
    class="align-center justify-center"
    @update:model-value="$emit('update:modelValue', undefined)"
  >
    <v-window v-model="windowModel">
      <v-window-item v-for="(u, i) in uploads" :key="u.id" :value="i" eager>
        <div class="image-container text-center">
          <img :alt="u.name" :src="u.imageUrl" />

          <p class="caption">
            <v-btn
              icon="chevron_right"
              class="img-nav-button img-nav-right"
              :disabled="windowModel == uploads.length - 1"
              @click="windowModel = windowModel + 1"
            />
            <v-btn
              icon="chevron_left"
              class="img-nav-button img-nav-left"
              :disabled="windowModel == 0"
              @click="windowModel = windowModel - 1"
            />
            {{ u.name }}
          </p>
        </div>
      </v-window-item>
    </v-window>
  </v-overlay>
</template>

<style scoped lang="scss">
.image-container {
  .img-nav-button {
    position: absolute;
    bottom: 0;
    opacity: 0.6;
  }
  .img-nav-left {
    left: 0;
  }
  .img-nav-right {
    right: 0;
  }
  .caption {
    width: max-content;
    max-width: calc(100% - 8em);
    margin: 1em auto 0.5em auto;
    padding: 0.2em 0.5em;
    border-radius: 0.5em;
    background: rgba(255, 255, 255, 0.6);
    font-size: 0.9em;
  }
}
</style>

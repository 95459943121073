<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ErrorResponse } from '@apollo/client/link/error'
import { computed, ref, watch } from 'vue'
import { DateTime } from 'luxon'
import { currentUserAndPermissions } from '../../app'

const props = defineProps<{
  modelValue?: ApiErrors
}>()
defineEmits<{
  'update:modelValue': [value: ApiErrors | undefined]
}>()

export type ApiErrors = Omit<ErrorResponse, 'nextLink'>

const { t } = useI18n()

const snackbarOpen = computed(() => !!props.modelValue)
const detailsOpen = ref(false)

const userAgent = computed(() => window.navigator.userAgent)
const errorTime = ref(DateTime.now())
watch(
  () => props.modelValue,
  () => {
    errorTime.value = DateTime.now()
  },
)

type ErrorType = 'unknown' | 'apiUnreachable' | 'privileges'
const errorType = computed<ErrorType>(() => {
  if (!props.modelValue) {
    return 'unknown'
  }

  const networkErrorMessage = props.modelValue?.networkError?.message.toLowerCase()
  if (networkErrorMessage?.includes('failed to fetch')) {
    return 'apiUnreachable'
  }

  const isForbidden = props.modelValue?.graphQLErrors?.some(
    (e) => e.message.toLowerCase() == 'forbidden',
  )
  if (isForbidden) {
    return 'privileges'
  }

  return 'unknown'
})
const icon = computed(() => {
  switch (errorType.value) {
    case 'privileges':
      return 'security'
    case 'unknown':
      return 'error'
    case 'apiUnreachable':
      return 'cloud_off'
  }
})

const copied = ref<boolean>()
async function copy() {
  const text = document.getElementById('copy-errors')?.innerText as string

  try {
    await window.navigator.clipboard.writeText(text)
    copied.value = true
  } catch (err) {
    copied.value = false
  }
  setTimeout(() => {
    copied.value = undefined
  }, 2000)
}
</script>

<template>
  <v-snackbar :model-value="snackbarOpen" timeout="-1" color="error" vertical>
    <v-row>
      <v-col cols="2" class="align-content-center text-center">
        <v-icon :icon="icon" size="40" class="mt-2" />
      </v-col>
      <v-col>
        <h3>
          {{ t(`component.apiErrorSnackbar.error.${errorType}.title`) }}
        </h3>

        {{ t(`component.apiErrorSnackbar.error.${errorType}.message`) }}
      </v-col>
    </v-row>

    <template #actions>
      <v-btn @click="detailsOpen = true">
        {{ t('component.apiErrorSnackbar.actionMoreDetails') }}
      </v-btn>
      <v-btn @click="$emit('update:modelValue', undefined)">
        {{ t('component.apiErrorSnackbar.actionDismiss') }}
      </v-btn>
    </template>
  </v-snackbar>

  <v-dialog v-model="detailsOpen">
    <v-card>
      <v-card-title>
        {{ t('component.apiErrorSnackbar.detailsTitle') }}
      </v-card-title>
      <v-card-text>
        <div id="errors">
          <h3>Meta</h3>
          {{ errorTime }}<br />
          {{ userAgent }}

          <h3>User</h3>
          <pre>{{ JSON.stringify(currentUserAndPermissions, null, 2) }}</pre>

          <h3>Response</h3>
          <pre>{{ JSON.stringify(props.modelValue?.response || 'None', null, 2) }}</pre>

          <h3>Network error</h3>
          <pre>{{ JSON.stringify(props.modelValue?.networkError || 'None', null, 2) }}</pre>

          <h3>GraphQL errors</h3>
          <pre>{{ JSON.stringify(props.modelValue?.graphQLErrors || 'None', null, 2) }}</pre>

          <h3>Operation</h3>
          <pre>{{ JSON.stringify(props.modelValue?.operation || 'None', null, 2) }}</pre>
        </div>
        <div id="copy-errors">
          <pre>
Meta
{{ errorTime }}
{{ userAgent }}

User
{{ JSON.stringify(currentUserAndPermissions, null, 2) }}

Response:
{{ JSON.stringify(props.modelValue?.response || 'None', null, 2) }}

Network error
{{ JSON.stringify(props.modelValue?.networkError || 'None', null, 2) }}

GraphQL errors
{{ JSON.stringify(props.modelValue?.graphQLErrors || 'None', null, 2) }}

Operation
{{ JSON.stringify(props.modelValue?.operation || 'None', null, 2) }}
          </pre>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="copy()">
          <template #append>
            <v-icon
              v-if="copied !== undefined"
              :icon="copied ? 'done' : 'cancel'"
              :color="copied ? 'success' : 'error'"
            />
          </template>
          {{ t('component.apiErrorSnackbar.detailsCopy') }}
        </v-btn>
        <v-btn color="primary" variant="elevated" @click="detailsOpen = false">
          {{ t('component.apiErrorSnackbar.detailsClose') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">
h3 {
  margin-top: 1em;
}

#errors {
  max-height: 70vh;
  overflow-y: auto;
}
#copy-errors {
  display: none;
}
</style>

<script setup lang="ts" generic="MM">
import FormField from '@/components/input/FormField.vue'
import { Validation, ValidationArgs } from '@vuelidate/core'

const props = defineProps<{
  modelValue?: string
  label: string
  validation?: Validation<ValidationArgs, MM>
}>()
const emit = defineEmits<{
  'update:modelValue': [value: string]
  blur: []
}>()

function onBlur() {
  props.validation?.$touch
  emit('blur')
}
</script>

<template>
  <form-field :label="props.label" :validation="props.validation">
    <v-textarea
      v-bind="$attrs"
      :model-value="props.modelValue"
      hide-details
      @update:model-value="(val) => $emit('update:modelValue', val)"
      @blur="onBlur"
    />
  </form-field>
</template>

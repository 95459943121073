<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>

<template>
  <v-btn density="compact" icon="add" color="primary" />
</template>

<style scoped lang="scss"></style>

<script setup lang="ts">
import { BreadcrumbDataQuery, BreadcrumbDataQueryVariables } from '@/generated/graphql'
import { useRoute, useRouter } from 'vue-router'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { SelectOption } from '@/components/input/SelectField.vue'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()

type LocalProduct = BreadcrumbDataQuery['product']['products'][0]
type LocalConfig = LocalProduct['productConfigurations'][0]
type LocalBop = NonNullable<BreadcrumbDataQuery['product']['productConfiguration']>['bops'][0]
type LocalModule = NonNullable<BreadcrumbDataQuery['product']['bop']>['nodes'][0]['module']
type LocalEbom = NonNullable<BreadcrumbDataQuery['article']['ebomsForBop']>[0]

const productId = computed(() => route.params.productId as string | undefined)
const configId = computed(() => route.params.productConfigId as string | undefined)
const siteId = computed(() => route.params.siteId as string | undefined)
const bopId = computed(() => route.params.bopId as string | undefined)
const moduleId = computed(() => route.params.moduleId as string | undefined)
const ebomId = computed(() => route.params.ebomId as string | undefined)
const fetchQuery = useQuery<BreadcrumbDataQuery, BreadcrumbDataQueryVariables>(
  gql`
    query BreadcrumbData(
      $includeConfig: Boolean!
      $configId: ID!
      $includeBop: Boolean!
      $siteId: ID!
      $includeSite: Boolean!
      $bopId: ID!
      $includeEbom: Boolean!
    ) {
      product {
        products {
          id
          name
          productConfigurations {
            id
            name
          }
        }
        productConfiguration(id: $configId) @include(if: $includeConfig) {
          name
          bops {
            id
            version
            versionStatus
            site {
              id
              name
            }
          }
        }
        bop(id: $bopId) @include(if: $includeBop) {
          version
          versionStatus
          nodes {
            module {
              id
              name
            }
          }
        }
      }
      article {
        ebomsForBop(bopId: $bopId) @include(if: $includeEbom) {
          id
          name
        }
      }
      site {
        site(id: $siteId) @include(if: $includeSite) {
          id
          name
        }
      }
    }
  `,
  () => ({
    includeConfig: !!configId.value,
    configId: configId.value || '',
    includeSite: !!siteId.value,
    siteId: siteId.value || '',
    includeBop: !!bopId.value,
    bopId: bopId.value || '',
    includeModule: !!moduleId.value,
    includeEbom: !!ebomId.value,
  }),
)

const products = computed<SelectOption<LocalProduct>[]>(
  () =>
    fetchQuery.result.value?.product.products.map((p) => ({
      title: p.name,
      value: p,
    })) || [],
)
const product = ref<LocalProduct>()
watch(products, (v) => {
  product.value = v.find((p) => p.value.id == productId.value)?.value
})
function chooseProduct(choice: LocalProduct) {
  product.value = choice
  router.push({ name: 'productConfigurations', params: { productId: choice.id } })
}

const configs = computed<SelectOption<LocalConfig>[]>(
  () =>
    products.value
      .find((p) => p.value.id == productId.value)
      ?.value.productConfigurations.map((c) => ({
        title: c.name,
        value: c,
      })) || [],
)
const config = ref<LocalConfig>()
watch(configs, (v) => {
  config.value = v.find((c) => c.value.id == configId.value)?.value
})

const site = computed(() => fetchQuery.result.value?.site.site)

const bops = computed<SelectOption<LocalBop>[]>(
  () =>
    fetchQuery.result.value?.product.productConfiguration?.bops.map((b) => ({
      title: `${b.version} ${b.versionStatus}`,
      value: b,
    })) || [],
)
const bop = ref<LocalBop>()
watch(bops, (v) => {
  bop.value = v.find((b) => b.value.id == bopId.value)?.value
})
function chooseBop(choice: LocalBop) {
  bop.value = choice
  router.push({ name: 'bopProcess', params: { bopId: choice.id } })
}

const modules = computed<SelectOption<LocalModule>[]>(
  () =>
    fetchQuery.result.value?.product.bop?.nodes.map((n) => ({
      title: n.module.name,
      value: n.module,
    })) || [],
)
const module = ref<LocalModule>()
watch(modules, (v) => {
  module.value = v.find((m) => m.value.id == moduleId.value)?.value
})
function chooseModule(choice: LocalModule) {
  module.value = choice
  router.push({ name: 'module', params: { moduleId: choice.id, stepId: undefined } })
}

const eboms = computed<SelectOption<LocalEbom>[]>(
  () =>
    fetchQuery.result.value?.article.ebomsForBop?.map((m) => ({
      title: m.name,
      value: m,
    })) || [],
)
const ebom = ref<LocalEbom>()
watch(eboms, (v) => {
  ebom.value = v.find((e) => e.value.id == ebomId.value)?.value
})
function chooseEbom(choice: LocalEbom) {
  ebom.value = choice
  router.push({ name: 'ebom', params: { ebomId: choice.id, ebomPartId: undefined } })
}
</script>

<template>
  <div class="breadcrumb-container">
    <router-link :to="{ name: 'products' }" class="breadcrumb">
      {{ t('entity.product.plural') }}
    </router-link>

    <div class="breadcrumb">
      <v-icon icon="navigate_next" />
      <v-icon v-if="product" icon="zoom_in" @click="chooseProduct(product)" />
      <v-select
        :model-value="product"
        :items="products"
        :loading="fetchQuery.loading.value"
        variant="plain"
        density="compact"
        class="route-selector"
        hide-details
        @update:model-value="chooseProduct"
      />
    </div>

    <div v-if="config" class="breadcrumb">
      <v-icon icon="navigate_next" />
      <router-link :to="{ name: 'productConfigurations' }">
        {{ config.name }}
      </router-link>
    </div>

    <div v-if="site" class="breadcrumb">
      <v-icon icon="navigate_next" />
      <router-link :to="{ name: 'productConfigurations' }">
        {{ site.name }}
      </router-link>
    </div>

    <div v-if="bop" class="breadcrumb">
      <v-icon icon="navigate_next" />
      <v-icon icon="zoom_in" @click="chooseBop(bop)" />
      <v-select
        :model-value="bop"
        :items="bops"
        :loading="fetchQuery.loading.value"
        variant="plain"
        density="compact"
        class="route-selector"
        hide-details
        @update:model-value="chooseBop"
      />
    </div>
    <div v-if="module" class="breadcrumb">
      <v-icon icon="navigate_next" />
      <v-select
        :model-value="module"
        :items="modules"
        :loading="fetchQuery.loading.value"
        variant="plain"
        density="compact"
        class="route-selector"
        hide-details
        @update:model-value="chooseModule"
      />
    </div>
    <div v-if="ebom" class="breadcrumb">
      <v-icon icon="navigate_next" />
      <v-select
        :model-value="ebom"
        :items="eboms"
        :loading="fetchQuery.loading.value"
        variant="plain"
        density="compact"
        class="route-selector"
        hide-details
        @update:model-value="chooseEbom"
      />
    </div>
  </div>
</template>

<style lang="scss">
.breadcrumb-container {
  z-index: 1000;
  position: sticky;
  top: 4em;
  width: max-content;
  background: rgba(var(--v-theme-background), 0.75);

  .breadcrumb {
    display: inline-block;
    position: relative;
  }
  .route-selector {
    display: inline-block;
    margin: 0 0.5em 0.07em 0.5em;
    width: max-content;
    vertical-align: bottom;
  }
}
</style>

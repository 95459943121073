<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import { computed } from 'vue'
import OperatorBreadcrumb from '@/components/OperatorBreadcrumb.vue'
import {
  OperatorStepTreeDataQuery,
  OperatorStepTreeDataQueryVariables,
  StepTreeDataQuery,
} from '@/generated/graphql'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import OperatorStepTree from '@/components/operator/OperatorStepTree.vue'
import OperatorModuleStepper from '@/components/operator/OperatorModuleStepper.vue'
import OperatorCurrentProductionBatch from '@/components/operator/OperatorCurrentProductionBatch.vue'

export type OperatorModuleViewModule = StepTreeDataQuery['product']['module']
export type OperatorModuleViewNode = OperatorModuleViewModule['nodes'][0]
export type OperatorModuleViewTreeNode = {
  numbering: string
  isSelected: boolean
  ancestryNodeIds: string[]
  stepNode: OperatorModuleViewNode
  children: OperatorModuleViewTreeNode[]
}

const route = useRoute()
const router = useRouter()

const moduleId = computed(() => route.params.moduleId as string)
const stepId = computed(() => route.params.stepId as string | undefined)
const fetchQuery = useQuery<OperatorStepTreeDataQuery, OperatorStepTreeDataQueryVariables>(
  gql`
    query OperatorStepTreeData($moduleId: ID!) {
      product {
        module(id: $moduleId) {
          id
          abbreviation
          nodes {
            id
            parentId
            step {
              id
              name
              durationInSeconds
              stepType {
                id
                name
              }
            }
          }
        }
      }
    }
  `,
  () => ({
    moduleId: moduleId.value,
  }),
)

const abbreviation = computed(() => fetchQuery.result.value?.product.module.abbreviation || '')
const stepNodes = computed<OperatorModuleViewNode[]>(
  () => fetchQuery.result.value?.product.module.nodes || [],
)

const treeNodes = computed<OperatorModuleViewTreeNode[]>(() =>
  stepNodes.value
    .filter((n) => !n.parentId)
    .map((n, i) => createStepTreeNode(i, [abbreviation.value], n, [])),
)
function createStepTreeNode(
  index: number,
  parentNumbering: string[],
  node: OperatorModuleViewNode,
  previousNodeIds: string[],
): OperatorModuleViewTreeNode {
  const ancestryNodeIds = [...previousNodeIds, node.id]
  const ancestryNumbering = [...parentNumbering, `${index + 1}`]
  const children = stepNodes.value
    .filter((n) => n.parentId == node.id)
    .map((n, i) => createStepTreeNode(i, ancestryNumbering, n, ancestryNodeIds))
  const isSelected = node.step.id == stepId.value

  return {
    numbering: ancestryNumbering.join('.'),
    stepNode: node,
    children,
    ancestryNodeIds,
    isSelected,
  }
}

async function selectStep(id: string | undefined) {
  router.push({ params: { stepId: id } })
}
</script>

<template>
  <operator-breadcrumb />
  <operator-current-production-batch :step-id="stepId" :module-id="moduleId" class="my-2" />

  <v-row>
    <v-col cols="8">
      <operator-module-stepper
        :module-id="moduleId"
        :step-id="stepId"
        :tree-nodes="treeNodes"
        @select-step-id="selectStep"
      />
    </v-col>
    <v-col>
      <operator-step-tree
        :tree-nodes="treeNodes"
        :loading="fetchQuery.loading.value"
        @select-step-id="selectStep"
      />
    </v-col>
  </v-row>
</template>

<style scoped lang="scss"></style>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useMutation } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import {
  EbomPartAllocationSuggestionsLoaderCreateSuggestionsMutation,
  EbomPartAllocationSuggestionsLoaderCreateSuggestionsMutationVariables,
} from '@/generated/graphql'
import { ref } from 'vue'

const props = defineProps<{
  ebomId: string
}>()
const emit = defineEmits<{
  done: []
}>()

const { t } = useI18n()

const createMutation = useMutation<
  EbomPartAllocationSuggestionsLoaderCreateSuggestionsMutation,
  EbomPartAllocationSuggestionsLoaderCreateSuggestionsMutationVariables
>(gql`
  mutation EbomPartAllocationSuggestionsLoaderCreateSuggestions($ebomId: ID!) {
    article {
      createEbomPartAllocationSuggestions(ebomId: $ebomId) {
        source
      }
    }
  }
`)

const suggestionCount = ref(0)
const notifySuccess = ref(false)

function createSuggestions() {
  createMutation.mutate({ ebomId: props.ebomId }).then((result) => {
    suggestionCount.value = result?.data?.article.createEbomPartAllocationSuggestions.length || 0
    notifySuccess.value = true
    emit('done')
  })
}
</script>

<template>
  <v-row>
    <v-col offset="2" cols="8" class="text-center">
      <v-icon icon="settings_suggest" size="60" class="mt-3 mb-3" />
      <h2>{{ t('component.ebomPartAllocationSuggestionsLoader.header') }}</h2>
      <p class="mb-5">{{ t('component.ebomPartAllocationSuggestionsLoader.message') }}</p>

      <v-btn variant="flat" @click="$emit('done')">
        {{ t('component.ebomPartAllocationSuggestionsLoader.skipButton') }}
      </v-btn>
      <v-btn color="primary" variant="elevated" @click="createSuggestions()">
        {{ t('component.ebomPartAllocationSuggestionsLoader.createSuggestionsButton') }}
      </v-btn>
    </v-col>
  </v-row>

  <v-snackbar v-model="notifySuccess" :timeout="5000">
    {{
      t('component.ebomPartAllocationSuggestionsLoader.successMessage', { count: suggestionCount })
    }}
  </v-snackbar>
</template>

<style scoped lang="scss"></style>

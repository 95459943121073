<script setup lang="ts">
import { useRoute } from 'vue-router'
import { computed, ref } from 'vue'

const route = useRoute()

const bopId = computed(() => route.params.bopId as string | undefined)
const moduleId = computed(() => route.params.moduleId as string | undefined)
const drawerIsRail = ref(true)
</script>

<template>
  <router-view />
</template>

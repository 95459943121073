<script setup lang="ts">
import { useMutation, useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed, ref } from 'vue'
import {
  UserProductionBatchesDataQuery,
  UserProductionBatchesDataQueryVariables,
  UserProductionBatchesDeleteMutation,
  UserProductionBatchesDeleteMutationVariables,
} from '@/generated/graphql'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import ItemDeleteIcon from '@/components/items/ItemDeleteIcon.vue'
import useCurrentProductionBatch from '@/components/operator/currentProductionBatch'
import AsyncDeleteDialog from '@/components/dialogs/AsyncDeleteDialog.vue'
import DeleteDialogNext from '@/components/dialogs/AsyncDeleteDialog.vue'
import { ComponentExposed } from 'vue-component-type-helpers'
import DataTable from '@/components/dataTable/DataTable.vue'

type LocalBatch =
  UserProductionBatchesDataQuery['shopFloor']['productionBatchesInProgressForCurrentUser'][0]

const { t } = useI18n()
const router = useRouter()
const { setCurrentProductionBatch } = useCurrentProductionBatch()

const fetchQuery = useQuery<
  UserProductionBatchesDataQuery,
  UserProductionBatchesDataQueryVariables
>(gql`
  query UserProductionBatchesData {
    shopFloor {
      productionBatchesInProgressForCurrentUser {
        id
        poNumber
        serialNumbers
        currentStep {
          id
          name
        }
        bop {
          id
          version
          site {
            id
            name
          }
          productConfiguration {
            id
            name
            product {
              id
              name
            }
          }
        }
        module {
          id
          name
        }
      }
    }
  }
`)
const batches = computed(
  () => fetchQuery.result.value?.shopFloor.productionBatchesInProgressForCurrentUser || [],
)

const deleteMutation = useMutation<
  UserProductionBatchesDeleteMutation,
  UserProductionBatchesDeleteMutationVariables
>(gql`
  mutation UserProductionBatchesDelete($id: ID!) {
    shopFloor {
      deleteProductionBatch(id: $id)
    }
  }
`)
const deleteDialog = ref<ComponentExposed<typeof DeleteDialogNext>>()
const doDelete = async (item: LocalBatch) => {
  await deleteDialog.value?.open(item.poNumber, () =>
    deleteMutation.mutate({ id: item.id }).then(() => fetchQuery.refetch()),
  )
}

const goToBatch = (_: unknown, row: { item: LocalBatch }) => {
  const batch = row.item
  setCurrentProductionBatch(batch.id)
  router.push({
    name: 'operatorModule',
    params: {
      productId: batch.bop.productConfiguration.product.id,
      productConfigId: batch.bop.productConfiguration.id,
      siteId: batch.bop.site.id,
      bopId: batch.bop.id,
      moduleId: batch.module.id,
      stepId: batch.currentStep?.id,
    },
  })
}

const headers = [
  {
    key: 'poNumber',
    title: t('entity.productionBatch.field.poNumber'),
    width: 150,
  },
  {
    key: 'serialNumbers',
    title: t('entity.productionBatch.field.serialNumbers'),
    sortable: false,
    width: 300,
  },
  {
    key: 'module',
    title: t('entity.module.singular'),
    sortable: false,
  },
  {
    key: 'actions',
    width: 100,
    sortable: false,
  },
]
const sort = [{ key: 'poNumber', order: 'asc' }]
</script>

<template>
  <data-table
    :items="batches"
    :headers="headers"
    :loading="fetchQuery.loading.value"
    :sort-by="sort"
    density="comfortable"
    hide-default-footer
    @click:row="goToBatch"
  >
    <template #item.actions="{ item }: { item: LocalBatch }">
      <div class="text-right">
        <item-delete-icon @click="doDelete(item)" />
      </div>
    </template>
    <template #item.module="{ item }: { item: LocalBatch }">
      <span>{{ item.bop.productConfiguration.product.name }} <v-icon icon="chevron_right" /></span>
      <span>{{ item.bop.productConfiguration.name }} <v-icon icon="chevron_right" /></span>
      <span>{{ item.bop.site.name }} <v-icon icon="chevron_right" /></span>
      <span>{{ item.bop.version }} <v-icon icon="chevron_right" /></span>
      <span>{{ item.module.name }}</span>
    </template>
    <template #item.serialNumbers="{ item }: { item: LocalBatch }">
      {{ item.serialNumbers.join(', ') }}
    </template>
  </data-table>

  <async-delete-dialog ref="deleteDialog" />
</template>

<style scoped lang="scss"></style>

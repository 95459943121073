<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import Checkbox from '@/components/input/Checkbox.vue'
import { useMutation } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { ref, watch } from 'vue'
import {
  ConfirmationCheckDeleteMutation,
  ConfirmationCheckDeleteMutationVariables,
  ConfirmationCheckSetMutation,
  ConfirmationCheckSetMutationVariables,
} from '@/generated/graphql'
import NotificationSaved from '@/components/notifications/NotificationSaved.vue'

const { t } = useI18n()

const props = defineProps<{
  value?: boolean
  required?: boolean
  checkConfigurationId: string
  poNumber: string
  serialNumber: string
}>()

const model = ref<boolean>(props.value)
const notifySaved = ref(false)
const setMutation = useMutation<
  ConfirmationCheckSetMutation,
  ConfirmationCheckSetMutationVariables
>(gql`
  mutation ConfirmationCheckSet($command: SetConfirmCheckCommand!) {
    shopFloor {
      setConfirmCheck(command: $command) {
        id
      }
    }
  }
`)
const deleteMutation = useMutation<
  ConfirmationCheckDeleteMutation,
  ConfirmationCheckDeleteMutationVariables
>(gql`
  mutation ConfirmationCheckDelete($command: DeleteCheckCommand!) {
    shopFloor {
      deleteCheck(command: $command)
    }
  }
`)

watch(model, (v) => {
  const baseCommand = {
    checkConfigurationId: props.checkConfigurationId,
    poNumber: props.poNumber,
    serialNumber: props.serialNumber,
  }
  if (!v) {
    deleteMutation.mutate({ command: baseCommand }).then(() => {
      notifySaved.value = true
    })
    return
  }

  setMutation.mutate({ command: { ...baseCommand, confirmed: v } }).then(() => {
    notifySaved.value = true
  })
})
</script>

<template>
  <checkbox v-model="model" :required="props.required" :label="serialNumber" v-bind="$attrs" />
  <notification-saved v-model="notifySaved" />
</template>

<style scoped lang="scss"></style>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, ref, watch } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import {
  OperatorProductConfigurationViewBopModulesQuery,
  OperatorProductConfigurationViewBopModulesQueryVariables,
  OperatorProductConfigurationViewDataQuery,
  OperatorProductConfigurationViewDataQueryVariables,
  VersionStatus,
} from '@/generated/graphql'
import gql from 'graphql-tag'
import { itemSortByName } from '@/app'
import { useRoute, useRouter } from 'vue-router'
import SelectField, { SelectOption } from '@/components/input/SelectField.vue'
import OperatorBreadcrumb from '@/components/OperatorBreadcrumb.vue'
import CreateProductionBatch from '@/components/productionBatch/CreateProductionBatch.vue'
import useCurrentProductionBatch from '@/components/operator/currentProductionBatch'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const productId = computed(() => route.params.productId as string)

type LocalSite = OperatorProductConfigurationViewDataQuery['site']['sites'][0]
type LocalProdConfig =
  OperatorProductConfigurationViewDataQuery['product']['product']['productConfigurations'][0]
type LocalBop = LocalProdConfig['bops'][0]
type LocalModule =
  OperatorProductConfigurationViewBopModulesQuery['product']['bop']['nodes'][0]['module']

const fetchQuery = useQuery<
  OperatorProductConfigurationViewDataQuery,
  OperatorProductConfigurationViewDataQueryVariables
>(
  gql`
    query OperatorProductConfigurationViewData($productId: ID!) {
      site {
        sites {
          id
          name
        }
      }
      product {
        product(id: $productId) {
          productConfigurations {
            id
            name
            abbreviation
            description
            imageUploadId
            imageUpload {
              id
              imageUrl(options: { width: 500, height: 300 })
            }
            bops {
              id
              site {
                id
                name
              }
              version
              versionStatus
            }
          }
        }
      }
    }
  `,
  () => ({
    productId: productId.value,
  }),
)
const productConfigs = computed<LocalProdConfig[]>(() =>
  itemSortByName(
    fetchQuery.result.value?.product.product.productConfigurations || [],
    (i) => i.name,
  ),
)
const sites = computed<LocalSite[]>(() => fetchQuery.result.value?.site.sites || [])

type BopPerConfigPerSite = {
  [key: string]: {
    [key: string]: {
      site: LocalSite
      bops: LocalBop[]
      options: SelectOption<LocalBop>[]
      selectedBop: LocalBop | undefined
    }
  }
}
const bopsPerConfigPerSite = ref<BopPerConfigPerSite>({})
watch([sites, productConfigs], () => {
  bopsPerConfigPerSite.value = {}
  productConfigs.value.forEach((config) => {
    bopsPerConfigPerSite.value[config.id] = {}
    sites.value.forEach((site) => {
      const bops = config.bops
        .filter((bop) => bop.site.id == site.id)
        .filter((b) => b.versionStatus == VersionStatus.Released)
        .sort((a, b) => b.version - a.version)
      const selectedBop = bops[0]
      const options = bops.map((b) => ({
        title: `${b.version} (${b.versionStatus})`,
        value: b,
      }))

      bopsPerConfigPerSite.value[config.id][site.id] = { site, bops, selectedBop, options }
    })
  })
})

const lastSelectedBop = ref<LocalBop>()
function selectBop(configId: string, siteId: string, bop: LocalBop) {
  bopsPerConfigPerSite.value[configId][siteId].selectedBop = bop
  lastSelectedBop.value = bop
}
const moduleFetchQuery = useQuery<
  OperatorProductConfigurationViewBopModulesQuery,
  OperatorProductConfigurationViewBopModulesQueryVariables
>(
  gql`
    query OperatorProductConfigurationViewBopModules($bopId: ID!) {
      product {
        bop(id: $bopId) {
          nodes {
            module {
              id
              name
              description
              abbreviation
            }
          }
        }
      }
    }
  `,
  () => ({
    bopId: lastSelectedBop.value?.id as string,
  }),
  () => ({
    enabled: !!lastSelectedBop.value,
  }),
)
const moduleOptions = computed(
  () =>
    moduleFetchQuery.result.value?.product.bop.nodes.map((n) => ({
      title: n.module.name,
      value: n.module,
    })) || [],
)

type SelectedModule = {
  productConfigId: string
  siteId: string
  bopId: string
  moduleId: string
}
const selectedModule = ref<SelectedModule | undefined>()
function selectModule(productConfigId: string, siteId: string, module: LocalModule) {
  selectedModule.value = {
    productConfigId,
    siteId,
    bopId: lastSelectedBop.value?.id as string,
    moduleId: module.id,
  }
}

const { deselectCurrentProductionBatch, setCurrentProductionBatch } = useCurrentProductionBatch()
const viewSelectedModule = (currentBatchId: string | undefined) => {
  currentBatchId ? setCurrentProductionBatch(currentBatchId) : deselectCurrentProductionBatch()
  router.push({
    name: 'operatorModule',
    params: selectedModule.value,
  })
}

const createBatchOpen = ref(false)
</script>

<template>
  <operator-breadcrumb />
  <h1>
    {{ t('view.organization.operatorProductConfiguration.title') }}
  </h1>

  <v-alert v-if="productConfigs.length == 0 && !fetchQuery.loading" type="info">
    {{ t('view.organization.operatorProductConfiguration.noData') }}
  </v-alert>
  <v-row>
    <v-col v-for="config in productConfigs" :key="config.id" xxl="2" xl="3" lg="3" sm="4" xs="6">
      <v-card>
        <v-img
          v-if="config.imageUpload?.imageUrl"
          :src="config.imageUpload.imageUrl"
          height="175px"
        />
        <v-card-title>{{ config.name }}</v-card-title>
        <v-card-text>
          <p>{{ config.description }}</p>

          <h3 class="mt-3">{{ t('entity.bop.plural') }}</h3>
          <template v-for="site in sites" :key="site.id">
            <select-field
              v-model="bopsPerConfigPerSite[config.id][site.id].selectedBop"
              :label="site.name"
              :items="bopsPerConfigPerSite[config.id][site.id].options"
            >
              <template #append>
                <v-btn
                  icon="account_tree"
                  density="comfortable"
                  @click="lastSelectedBop = bopsPerConfigPerSite[config.id][site.id].selectedBop"
                />
              </template>
            </select-field>
            <select-field
              v-if="
                !!lastSelectedBop &&
                lastSelectedBop == bopsPerConfigPerSite[config.id][site.id].selectedBop
              "
              :items="moduleOptions"
              @update:model-value="(v: LocalModule) => selectModule(config.id, site.id, v)"
            >
              <template
                v-if="
                  selectedModule?.productConfigId == config.id && selectedModule?.siteId == site.id
                "
                #append
              >
                <v-btn
                  :title="t('view.organization.operatorProductConfiguration.buttonViewModule')"
                  density="comfortable"
                  icon="search"
                  @click="viewSelectedModule(undefined)"
                />
                <v-btn
                  :title="
                    t('view.organization.operatorProductConfiguration.buttonCreateProductionBatch')
                  "
                  density="comfortable"
                  icon="handyman"
                  color="primary"
                  @click="createBatchOpen = true"
                />
              </template>
            </select-field>
          </template>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>

  <create-production-batch
    v-if="selectedModule"
    v-model="createBatchOpen"
    :bop-id="selectedModule.bopId"
    :module-id="selectedModule.moduleId"
    @created="viewSelectedModule"
  />
</template>

<style scoped lang="scss"></style>

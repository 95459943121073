import { ref, Ref } from 'vue'
import { v4 } from 'uuid'
import { Upload } from '@/generated/graphql'
import gql from 'graphql-tag'
import axios from 'axios'
import { apolloClient } from '@/apollo'

type UploadInProgress = {
  id: string
  file: File
  fileName: string
  initialized: boolean
  activelyUploading: boolean
  doneWithUploading: boolean
  finalized: boolean
  progress: number
  chunks: UploadingChunk[]
  url?: string
  errors: string[]
}
type UploadingChunk = {
  id: string
  uploadId: string
  part: number
  active: boolean
  done: boolean
  progress: number
  retryCount: number
}
type ActiveUploads = {
  [key: string]: Promise<unknown>
}
export type OnDoneCallback = ({
  name,
  contentType,
  id,
}: {
  name: string
  contentType: string
  id: string
}) => void

const maxParallelUploads = 4
const maxRetries = 3
const chunkSize = 5 * 1024 * 1024

const uploadingChunks: ActiveUploads = {}
const addUploadsLoading = ref(false)
const uploadsInProgress = ref<UploadInProgress[]>([])
const uploadsDone = ref<Upload[]>([])
const onDoneCallbacks: OnDoneCallback[] = []

export default function useUploads(): {
  addUploadsLoading: Ref<boolean>
  uploadsInProgress: Ref<UploadInProgress[]>
  addUploads: (files: File[], fileUploadIds?: Map<File, string>) => Promise<void>
  uploadsDone: Ref<Upload[]>
  addUploadDoneListener: (callback: OnDoneCallback) => () => void
} {
  const addUploadDoneListener = (cb: OnDoneCallback) => {
    onDoneCallbacks.push(cb)

    return () => {
      onDoneCallbacks.splice(onDoneCallbacks.indexOf(cb), 1)
    }
  }

  function getUpload(id: string) {
    return uploadsInProgress.value.find((u) => u.id == id) as UploadInProgress
  }

  function processProgress(upload: UploadInProgress, chunk: UploadingChunk, progress: number) {
    chunk.progress = progress
    upload.activelyUploading = upload.chunks.reduce((init, c) => init || c.active, false)
    upload.doneWithUploading = upload.chunks.reduce((init, c) => init && c.done, true)
    upload.progress = upload.chunks.reduce((init, c) => init + c.progress, 0) / upload.chunks.length

    if (!upload.doneWithUploading || upload.finalized) {
      return
    }

    apolloClient
      .mutate({
        mutation: gql`
          mutation finalizeMultipartUpload($id: ID!) {
            upload {
              finalizeMultipartUpload(id: $id) {
                id
                name
                contentType
                createdAt
              }
            }
          }
        `,
        variables: { id: upload.id },
      })
      .then((result) => {
        const finalized = result?.data?.upload.finalizeMultipartUpload as Upload
        uploadsDone.value.push(finalized)
        uploadsInProgress.value = uploadsInProgress.value.filter((u) => u.id != upload.id)
        onDoneCallbacks.forEach((cb) => cb(finalized))
      })
      .catch((reason) => {
        const message = `'Could not finalize upload: ${reason}`
        console.error(message)
        upload.errors.push(message)
      })
      .finally(() => {
        upload.finalized = true
      })
  }

  function scheduleChunk(chunk: UploadingChunk) {
    const upload = getUpload(chunk.uploadId)
    chunk.active = true
    uploadingChunks[chunk.id] = axios
      .post(
        `/upload/multipart/${upload.id}/${chunk.part}`,
        upload.file.slice((chunk.part - 1) * chunkSize, chunk.part * chunkSize, upload.file.type),
        {
          headers: {
            'Content-Type': 'application/octet-stream',
          },
          onUploadProgress(progressEvent) {
            processProgress(upload, chunk, progressEvent.progress as number)
          },
        },
      )
      .then(() => {
        chunk.active = false
        chunk.done = true
        processProgress(upload, chunk, 1)
      })
      .catch((reason) => {
        chunk.progress = 0
        chunk.active = false
        chunk.done = true

        if (chunk.retryCount < maxRetries) {
          console.error(`Chunk upload failed but retrying`, reason, chunk, upload)
          chunk.retryCount++
          chunk.done = false
          return
        }

        const message = `Chunk upload failed, out of retries: ${reason.message}`
        console.error(message, reason, chunk, upload)
        upload.progress = 0.0
        upload.errors.push(message)
        upload.activelyUploading = false
        upload.doneWithUploading = true
      })
      .finally(() => {
        delete uploadingChunks[chunk.id]
        scheduleChunks()
      })
  }

  function scheduleChunks() {
    const currentParallelUploads = Object.keys(uploadingChunks).length
    if (currentParallelUploads >= maxParallelUploads) {
      return
    }

    uploadsInProgress.value
      .filter((u) => u.initialized)
      .filter((u) => !u.doneWithUploading)
      .flatMap((u) => u.chunks)
      .filter((c) => !c.active)
      .filter((c) => !c.done)
      .slice(0, maxParallelUploads - currentParallelUploads)
      .forEach((c) => scheduleChunk(c))
  }

  async function createUploadingFile(file: File, uploadId: string): Promise<UploadInProgress> {
    const chunkCount = Math.ceil(file.size / chunkSize)
    const chunks: UploadingChunk[] = []

    for (let i = 0; i < chunkCount; i++) {
      chunks.push({
        id: v4(),
        uploadId,
        part: i + 1,
        active: false,
        done: false,
        progress: 0.0,
        retryCount: 0,
      })
    }
    const upload: UploadInProgress = {
      id: uploadId,
      file,
      fileName: file.name,
      initialized: false,
      activelyUploading: false,
      doneWithUploading: false,
      finalized: false,
      progress: 0.0,
      chunks,
      errors: [],
    }

    await apolloClient
      .mutate({
        mutation: gql`
          mutation createMultipartUpload($command: CreateMultipartUpload!) {
            upload {
              createMultipartUpload(command: $command)
            }
          }
        `,
        variables: {
          command: {
            id: uploadId,
            name: file.name,
            contentType: file.type,
          },
        },
      })

      .then(() => {
        upload.initialized = true
      })
      .catch((reason) => {
        const message = `Could not create multipart upload: ${reason}`
        console.error(message)
        upload.errors.push(message)
        upload.activelyUploading = false
        upload.doneWithUploading = true
      })

    return upload
  }

  async function addUploads(files: File[], fileUploadIds?: Map<File, string>) {
    addUploadsLoading.value = true
    for (const file of files) {
      const fileUploadId = fileUploadIds?.get(file) || v4()
      uploadsInProgress.value.push(await createUploadingFile(file, fileUploadId))
    }

    scheduleChunks()
    addUploadsLoading.value = false
  }

  return {
    addUploads,
    addUploadsLoading,
    uploadsInProgress,
    uploadsDone,
    addUploadDoneListener,
  }
}

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { SelectOption } from '@/components/input/SelectField.vue'
import { computed, Ref, ref, watch } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import {
  HomeViewDataQuery,
  HomeViewDataQueryVariables,
  PermissionOrganization,
} from '@/generated/graphql'
import gql from 'graphql-tag'
import { useRouter } from 'vue-router'

const { t } = useI18n()
const router = useRouter()

type LocalOrgAndPerms = HomeViewDataQuery['account']['organizations'][0]
const fetchQuery = useQuery<HomeViewDataQuery, HomeViewDataQueryVariables>(gql`
  query HomeViewData {
    account {
      organizations {
        permissions
        organization {
          id
          name
          slug
        }
      }
    }
  }
`)
const orgOptions = computed<SelectOption<LocalOrgAndPerms>[]>(
  () =>
    fetchQuery.result?.value?.account.organizations.map((o) => ({
      value: o,
      title: o.organization.name,
    })) || [],
)
const selectedOrg = ref<LocalOrgAndPerms | undefined>()
watch(orgOptions, (v) => {
  if (v.length == 1) {
    selectedOrg.value = v[0].value
  }
})

function hasPermission(ref: Ref<LocalOrgAndPerms | undefined>, permission: PermissionOrganization) {
  return (
    ref.value?.permissions.includes(permission) ||
    ref.value?.permissions.includes(PermissionOrganization.OrgAdmin) ||
    false
  )
}
const isOperator = computed(() => hasPermission(selectedOrg, PermissionOrganization.OrgOperator))
const isProcessManagement = computed(() =>
  hasPermission(selectedOrg, PermissionOrganization.OrgProductManagement),
)
function operatorView() {
  router.push({
    name: 'operatorHome',
    params: { organizationSlug: selectedOrg.value?.organization.slug },
  })
}
function processView() {
  router.push({
    name: 'products',
    params: { organizationSlug: selectedOrg.value?.organization.slug },
  })
}

watch(selectedOrg, (v) => {
  if (!selectedOrg.value) {
    return
  }

  if (isOperator.value && !isProcessManagement.value) {
    operatorView()
  }
  if (!isOperator.value && isProcessManagement.value) {
    processView()
  }
})
</script>

<template>
  <h1>{{ t('view.home.welcome') }}</h1>

  <div class="text-center align-center">
    <v-select
      v-model="selectedOrg"
      :items="orgOptions"
      :label="t('view.home.selectOrganization')"
      :loading="fetchQuery.loading.value"
      style="width: 25em"
      class="mx-auto mb-10"
      hide-details
    />
    <v-btn
      v-if="isProcessManagement"
      variant="elevated"
      color="primary"
      class="mr-5"
      :disabled="!selectedOrg"
      @click="processView"
    >
      {{ t('view.home.buttonProcessManagement') }}
    </v-btn>
    <v-btn
      v-if="isOperator"
      variant="elevated"
      color="primary"
      :disabled="!selectedOrg"
      @click="operatorView"
    >
      {{ t('view.home.buttonOperator') }}
    </v-btn>
  </div>
</template>

<style lang="scss"></style>

import axios from 'axios'

export function downloadMbom(
  bopId: string,
  moduleIds: string[],
  filenameAppendix: string | undefined,
) {
  axios.get(`/download/mbom/bop/${bopId}/${moduleIds.join(',')}`).then((response) => {
    const contentDisposition = response.headers['content-disposition']
    const filename = contentDisposition.match(/filename="([^"]+)/)[1]
    const name = filename.substring(0, filename.lastIndexOf('.')) + (filenameAppendix || '')
    const extension = filename.substring(filename.lastIndexOf('.') + 1)
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')

    link.href = url
    link.setAttribute('download', name + '.' + extension)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  })
}

<script setup lang="ts" generic="T, MM">
import { SitePickerDataQuery, SitePickerDataQueryVariables } from '@/generated/graphql'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import SelectField, { SelectOption } from '@/components/input/SelectField.vue'
import { computed, watch } from 'vue'
import { itemSortByName } from '@/app'

const props = defineProps<{
  withArchived?: boolean
}>()
const model = defineModel<string | undefined>()

const fetchQuery = useQuery<SitePickerDataQuery, SitePickerDataQueryVariables>(gql`
  query SitePickerData {
    site {
      sites {
        id
        name
        archived
      }
    }
  }
`)
const options = computed<SelectOption<string>[]>(() =>
  itemSortByName(
    fetchQuery.result.value?.site.sites.filter((s) => !s.archived || props.withArchived),
    (c) => c.name,
  ).map((c) => ({
    title: c.name,
    value: c.id,
  })),
)
watch(options, (v) => {
  if (v.length == 1) {
    model.value = v[0].value
  }
})
</script>

<template>
  <select-field v-model="model" :items="options">
    <template v-for="(_, slot) of $slots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </select-field>
</template>

<style scoped lang="scss"></style>

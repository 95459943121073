<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ArticleUnit } from '@/generated/graphql'
import ArticleUnitTag from '@/components/article/ArticleUnitTag.vue'

const props = defineProps<{
  milliQuantity?: number
  unit?: ArticleUnit
  unitAbbreviated?: boolean
}>()

const { t } = useI18n()
</script>

<template>
  {{ props.milliQuantity ? props.milliQuantity / 1000 : '-' }}
  <article-unit-tag
    v-if="props.unit"
    :model-value="props.unit"
    :abbreviated="props.unitAbbreviated"
  />
</template>

<style scoped lang="scss"></style>

<script setup lang="ts">
import {
  BreadcrumbDataQuery,
  OperatorBreadcrumbDataQuery,
  OperatorBreadcrumbDataQueryVariables,
} from '@/generated/graphql'
import { useRoute, useRouter } from 'vue-router'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { SelectOption } from '@/components/input/SelectField.vue'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()

type LocalProduct = BreadcrumbDataQuery['product']['products'][0]
type LocalConfig = LocalProduct['productConfigurations'][0]
type LocalBop = NonNullable<BreadcrumbDataQuery['product']['productConfiguration']>['bops'][0]
type LocalModule = NonNullable<BreadcrumbDataQuery['product']['bop']>['nodes'][0]['module']

const productId = computed(() => route.params.productId as string | undefined)
const configId = computed(() => route.params.productConfigId as string | undefined)
const bopId = computed(() => route.params.bopId as string | undefined)
const moduleId = computed(() => route.params.moduleId as string | undefined)
const fetchQuery = useQuery<OperatorBreadcrumbDataQuery, OperatorBreadcrumbDataQueryVariables>(
  gql`
    query OperatorBreadcrumbData($includeBop: Boolean!, $bopId: ID!) {
      product {
        products {
          id
          name
          productConfigurations {
            id
            name
          }
        }
        bop(id: $bopId) @include(if: $includeBop) {
          version
          versionStatus
          site {
            name
          }
          nodes {
            module {
              id
              name
            }
          }
        }
      }
    }
  `,
  () => ({
    includeBop: !!bopId.value,
    bopId: bopId.value || '',
  }),
)

const products = computed<SelectOption<LocalProduct>[]>(
  () =>
    fetchQuery.result.value?.product.products.map((p) => ({
      title: p.name,
      value: p,
    })) || [],
)
const product = ref<LocalProduct>()
watch(products, (v) => {
  product.value = v.find((p) => p.value.id == productId.value)?.value
})
function chooseProduct(choice: LocalProduct) {
  product.value = choice
  router.push({ name: 'operatorProductConfigurations', params: { productId: choice.id } })
}

const configs = computed<SelectOption<LocalConfig>[]>(
  () =>
    products.value
      .find((p) => p.value.id == productId.value)
      ?.value.productConfigurations.map((c) => ({
        title: c.name,
        value: c,
      })) || [],
)
const config = ref<LocalConfig>()
watch(configs, (v) => {
  config.value = v.find((c) => c.value.id == configId.value)?.value
})

const bop = computed(() => fetchQuery.result.value?.product.bop)

const modules = computed<SelectOption<LocalModule>[]>(
  () =>
    fetchQuery.result.value?.product.bop?.nodes.map((n) => ({
      title: n.module.name,
      value: n.module,
    })) || [],
)
const module = ref<LocalModule>()
watch(modules, (v) => {
  module.value = v.find((m) => m.value.id == moduleId.value)?.value
})
function chooseModule(choice: LocalModule) {
  module.value = choice
  router.push({ name: 'operatorModule', params: { moduleId: choice.id, stepId: undefined } })
}
</script>

<template>
  <div class="breadcrumb-container">
    <router-link :to="{ name: 'operatorHome' }" class="breadcrumb">
      {{ t('entity.product.plural') }}
    </router-link>

    <div class="breadcrumb">
      <v-icon icon="navigate_next" />
      <v-select
        :model-value="product"
        :items="products"
        :loading="fetchQuery.loading.value"
        variant="plain"
        density="compact"
        class="route-selector"
        hide-details
        @update:model-value="chooseProduct"
      />
    </div>

    <div v-if="config" class="breadcrumb">
      <v-icon icon="navigate_next" />
      <router-link :to="{ name: 'operatorProductConfigurations' }">
        {{ config.name }}
      </router-link>
    </div>

    <div v-if="bop" class="breadcrumb">
      <v-icon icon="navigate_next" />
      <router-link :to="{ name: 'operatorProductConfigurations' }">
        {{ bop.site.name }}
      </router-link>
    </div>

    <div v-if="bop" class="breadcrumb">
      <v-icon icon="navigate_next" />
      <router-link :to="{ name: 'operatorProductConfigurations' }">
        {{ bop.version }} ({{ bop.versionStatus }})
      </router-link>
    </div>
    <div v-if="module" class="breadcrumb">
      <v-icon icon="navigate_next" />
      <v-select
        :model-value="module"
        :items="modules"
        :loading="fetchQuery.loading.value"
        variant="plain"
        density="compact"
        class="route-selector"
        hide-details
        @update:model-value="chooseModule"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.breadcrumb-container {
  clear: left;
}
.breadcrumb {
  display: inline-block;
  position: relative;
}
.route-selector {
  display: inline-block;
  margin: 0 0.5em 0.07em 0.5em;
  width: max-content;
  vertical-align: bottom;
}
</style>

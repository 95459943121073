<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useMutation } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed, ref, watch } from 'vue'
import {
  NumericCheckDeleteMutation,
  NumericCheckDeleteMutationVariables,
  NumericCheckSetMutation,
  NumericCheckSetMutationVariables,
} from '@/generated/graphql'
import NotificationSaved from '@/components/notifications/NotificationSaved.vue'
import MilliQuantityField from '@/components/input/MilliQuantityField.vue'
import { debounce } from 'lodash'
import { maxMilliQuantity, minMilliQuantity } from '@/validation'
import useVuelidate from '@vuelidate/core'

const { t } = useI18n()

const props = defineProps<{
  value?: number
  required?: boolean
  checkConfigurationId: string
  poNumber: string
  serialNumber: string
  lowerLimit?: number
  upperLimit?: number
}>()

const model = ref<number | undefined>(props.value)
const debouncedModel = ref<number | undefined>()
const debouncer = debounce(() => {
  debouncedModel.value = model.value
}, 1000)
watch(model, debouncer)

const validationRules = computed(() => {
  let rules: { [key: string]: object } = {}
  if (props.lowerLimit) {
    rules['lower'] = minMilliQuantity(props.lowerLimit)
  }
  if (props.upperLimit) {
    rules['upper'] = maxMilliQuantity(props.upperLimit)
  }
  return rules
})
const validation = useVuelidate(validationRules, debouncedModel)

const notifySaved = ref(false)
const setMutation = useMutation<NumericCheckSetMutation, NumericCheckSetMutationVariables>(gql`
  mutation NumericCheckSet($command: SetNumericCheckCommand!) {
    shopFloor {
      setNumericCheck(command: $command) {
        id
      }
    }
  }
`)
const deleteMutation = useMutation<NumericCheckDeleteMutation, NumericCheckDeleteMutationVariables>(
  gql`
    mutation NumericCheckDelete($command: DeleteCheckCommand!) {
      shopFloor {
        deleteCheck(command: $command)
      }
    }
  `,
)

watch(debouncedModel, (v) => {
  const baseCommand = {
    checkConfigurationId: props.checkConfigurationId,
    poNumber: props.poNumber,
    serialNumber: props.serialNumber,
  }

  validation.value.$touch()
  if (!v || Number.isNaN(v) || validation.value.$invalid) {
    deleteMutation.mutate({ command: baseCommand }).then(() => {
      notifySaved.value = true
    })
    return
  }

  setMutation.mutate({ command: { ...baseCommand, milliQuantity: v } }).then(() => {
    notifySaved.value = true
  })
})
</script>

<template>
  <milli-quantity-field
    v-model="model"
    :required="props.required"
    :label="serialNumber"
    :validation="validation"
    v-bind="$attrs"
  />
  <notification-saved v-model="notifySaved" />
</template>

<style scoped lang="scss"></style>

<script setup lang="ts" generic="MM">
import FormField from '@/components/input/FormField.vue'
import { Validation, ValidationArgs } from '@vuelidate/core'

const props = defineProps<{
  validation?: Validation<ValidationArgs, MM>
}>()
defineOptions({
  inheritAttrs: false,
})
</script>

<template>
  <form-field :validation="props.validation">
    <v-checkbox v-bind="$attrs" hide-details />
  </form-field>
</template>

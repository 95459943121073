<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useQuery } from '@vue/apollo-composable'
import {
  ViewCheckConfigurationsDataQuery,
  ViewCheckConfigurationsDataQueryVariables,
} from '@/generated/graphql'
import gql from 'graphql-tag'
import { computed } from 'vue'
import IconCheckType from '@/components/icons/IconCheckType.vue'
import ArticleQuantity from '@/components/article/ArticleQuantity.vue'

const props = defineProps<{
  stepId: string
}>()

const { t } = useI18n()

const fetchQuery = useQuery<
  ViewCheckConfigurationsDataQuery,
  ViewCheckConfigurationsDataQueryVariables
>(
  gql`
    query ViewCheckConfigurationsData($stepId: ID!) {
      product {
        checkConfigurations(stepId: $stepId) {
          id
          type
          isRequired
          description
          upperLimit
          lowerLimit
        }
      }
    }
  `,
  () => ({
    stepId: props.stepId,
  }),
)
const configs = computed(() => fetchQuery.result.value?.product.checkConfigurations || [])
</script>

<template>
  <v-row class="font-weight-bold" dense>
    <v-col cols="2">{{ t('entity.checkConfiguration.field.type') }}</v-col>
    <v-col cols="2">{{ t('entity.checkConfiguration.field.isRequired') }}</v-col>
    <v-col>
      {{ t('entity.checkConfiguration.field.description') }}
    </v-col>
  </v-row>
  <v-row v-if="configs.length == 0" dense>
    <v-col cols="2">-</v-col>
    <v-col cols="2">-</v-col>
    <v-col>-</v-col>
  </v-row>
  <v-row v-for="(c, i) in configs" :key="c.id" dense>
    <v-col cols="2">
      <icon-check-type :type="c.type" />
    </v-col>
    <v-col cols="2">
      <v-icon :icon="c.isRequired ? 'check_box' : 'check_box_outline_blank'" />
    </v-col>
    <v-col>
      {{ c.description }}

      <v-chip v-if="c.lowerLimit" density="compact">
        &le; <article-quantity :milli-quantity="c.lowerLimit" />
      </v-chip>
      <v-chip v-if="c.upperLimit" density="compact">
        &ge; <article-quantity :milli-quantity="c.upperLimit" />
      </v-chip>
    </v-col>
  </v-row>
</template>

<style scoped lang="scss"></style>

<script setup lang="ts">
import { unref, watch } from 'vue'
import { CurrentUserAndPermissions, Query } from '@/generated/graphql'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { currentOrganization } from '@/app'

const emit = defineEmits<{
  user: [value: CurrentUserAndPermissions]
}>()

const fetchQuery = useQuery<Query>(gql`
  query getCurrentUserAndPermissions {
    account {
      me {
        organization {
          id
          name
        }
        user {
          id
          firstname
          lastname
          email
        }
        userPermissions
        orgPermissions
      }
    }
  }
`)
let fetchedOrganization: string | undefined
watch(currentOrganization, () => {
  if (fetchedOrganization == currentOrganization.value?.id) {
    return
  }

  fetchQuery.refetch()
  fetchedOrganization = currentOrganization.value?.id
})
watch(fetchQuery.result, (v) => {
  if (!v) {
    return
  }

  emit('user', unref(v.account.me))
})
</script>

<template><span /></template>

<style scoped lang="scss"></style>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useMutation, useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import {
  BulkAllocationSuggestionActionsAcceptMutation,
  BulkAllocationSuggestionActionsAcceptMutationVariables,
  BulkAllocationSuggestionActionsDataQuery,
  BulkAllocationSuggestionActionsDataQueryVariables,
  BulkAllocationSuggestionActionsRejectMutation,
  BulkAllocationSuggestionActionsRejectMutationVariables,
  EbomPartAllocationSuggestionDecision,
} from '@/generated/graphql'
import { computed, ref } from 'vue'

const props = defineProps<{
  ebomId: string
}>()
defineEmits<{
  done: []
}>()

const { t } = useI18n()

const fetchQuery = useQuery<
  BulkAllocationSuggestionActionsDataQuery,
  BulkAllocationSuggestionActionsDataQueryVariables
>(
  gql`
    query BulkAllocationSuggestionActionsData($ebomId: ID!) {
      article {
        ebom(id: $ebomId) {
          ebomParts {
            milliQuantity
            stepAllocations {
              id
              milliQuantity
            }
            stepAllocationSuggestions {
              id
              decision
              milliQuantity
            }
          }
        }
      }
    }
  `,
  () => ({
    ebomId: props.ebomId,
  }),
)
const ebomParts = computed(() => fetchQuery.result.value?.article.ebom.ebomParts || [])

const undecidedAllocationIds = computed(() =>
  ebomParts.value.flatMap((p) =>
    p.stepAllocationSuggestions
      .filter((s) => s.decision == EbomPartAllocationSuggestionDecision.Undecided)
      .map((s) => s.id),
  ),
)
const notifyRejected = ref(false)
const rejectMutation = useMutation<
  BulkAllocationSuggestionActionsRejectMutation,
  BulkAllocationSuggestionActionsRejectMutationVariables
>(
  gql`
    mutation BulkAllocationSuggestionActionsReject($ids: [ID!]!) {
      article {
        rejectEbomPartAllocationSuggestions(ids: $ids)
      }
    }
  `,
  {
    refetchQueries: [
      'EbomPartTreeEbomParts',
      'BulkAllocationSuggestionActionsData',
      'EbomPartDetailsEbomPart',
    ],
  },
)
const reject = () => {
  rejectMutation.mutate({ ids: undecidedAllocationIds.value }).then(() => {
    notifyRejected.value = true
  })
}

const acceptAllocationIds = computed(() =>
  ebomParts.value.flatMap((p) => {
    const undecided = p.stepAllocationSuggestions.filter(
      (s) => s.decision == EbomPartAllocationSuggestionDecision.Undecided,
    )
    const totalSuggestedQuantity = undecided.reduce((agg, s) => agg + s.milliQuantity, 0)
    const allocatedQuantity = p.stepAllocations.reduce((agg, s) => agg + s.milliQuantity, 0)
    const unallocatedQuantity = p.milliQuantity - allocatedQuantity

    if (totalSuggestedQuantity != unallocatedQuantity) {
      return []
    }

    return undecided.map((s) => s.id)
  }),
)
const notifyAccepted = ref(false)
const acceptMutation = useMutation<
  BulkAllocationSuggestionActionsAcceptMutation,
  BulkAllocationSuggestionActionsAcceptMutationVariables
>(
  gql`
    mutation BulkAllocationSuggestionActionsAccept($ids: [ID!]!) {
      article {
        acceptEbomPartAllocationSuggestions(ids: $ids)
      }
    }
  `,
  {
    refetchQueries: [
      'EbomPartTreeEbomParts',
      'BulkAllocationSuggestionActionsData',
      'EbomPartDetailsEbomPart',
    ],
  },
)
const accept = () => {
  acceptMutation.mutate({ ids: acceptAllocationIds.value }).then(() => {
    notifyAccepted.value = true
  })
}

const notifyCreated = ref(false)
const createMutation = useMutation(
  gql`
    mutation BulkAllocationSuggestionActionsCreate($ebomId: ID!) {
      article {
        createEbomPartAllocationSuggestions(ebomId: $ebomId) {
          id
        }
      }
    }
  `,
  {
    refetchQueries: [
      'EbomPartTreeEbomParts',
      'BulkAllocationSuggestionActionsData',
      'EbomPartDetailsEbomPart',
    ],
  },
)
const create = () => {
  createMutation.mutate({ ebomId: props.ebomId }).then(() => {
    notifyCreated.value = true
  })
}
</script>

<template>
  <v-list-item :disabled="undecidedAllocationIds.length > 0">
    <template #prepend>
      <v-icon icon="settings_suggest" />
    </template>
    <v-list-item-title>
      {{ t('component.bulkAllocationSuggestionActions.createTitle') }}
    </v-list-item-title>
    <template #append>
      <v-btn :loading="createMutation.loading.value" @click="create">
        {{ t('component.bulkAllocationSuggestionActions.createButton') }}
      </v-btn>
    </template>
  </v-list-item>
  <v-list-item :disabled="acceptAllocationIds.length == 0">
    <template #prepend>
      <v-icon icon="check_circle" color="success" />
    </template>
    <v-list-item-title>
      {{
        t('component.bulkAllocationSuggestionActions.acceptTitle', {
          count: acceptAllocationIds.length,
        })
      }}
    </v-list-item-title>
    <template #append>
      <v-btn :loading="acceptMutation.loading.value" @click="accept">
        {{ t('component.bulkAllocationSuggestionActions.acceptButton') }}
      </v-btn>
    </template>
  </v-list-item>
  <v-list-item :disabled="undecidedAllocationIds.length == 0">
    <template #prepend>
      <v-icon icon="cancel" color="error" />
    </template>
    <v-list-item-title>
      {{
        t('component.bulkAllocationSuggestionActions.rejectTitle', {
          count: undecidedAllocationIds.length,
        })
      }}
    </v-list-item-title>
    <template #append>
      <v-btn :loading="rejectMutation.loading.value" @click="reject">
        {{ t('component.bulkAllocationSuggestionActions.rejectButton') }}
      </v-btn>
    </template>
  </v-list-item>

  <v-snackbar v-model="notifyCreated">
    {{ t('component.bulkAllocationSuggestionActions.createConfirmation') }}
  </v-snackbar>
  <v-snackbar v-model="notifyRejected">
    {{ t('component.bulkAllocationSuggestionActions.rejectConfirmation') }}
  </v-snackbar>
  <v-snackbar v-model="notifyAccepted">
    {{ t('component.bulkAllocationSuggestionActions.acceptConfirmation') }}
  </v-snackbar>
</template>

<style scoped lang="scss"></style>

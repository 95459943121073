<script setup lang="ts">
import { VTextField } from 'vuetify/components'
import { computed, Ref, unref } from 'vue'

const props = defineProps<{
  loading?: boolean | Ref<boolean>
}>()
const isLoading = computed(() => unref(props.loading))
const model = defineModel<string | undefined>()
</script>

<template>
  <v-menu :close-on-content-click="false">
    <template #activator="{ props }">
      <v-btn
        v-bind="props"
        :variant="!!model ? 'tonal' : 'flat'"
        density="comfortable"
        icon="search"
      />
    </template>

    <v-list>
      <v-list-item>
        <v-text-field
          ref="searchInput"
          v-model="model"
          :loading="isLoading"
          hide-details
          style="min-width: 25em"
          clearable
          autofocus
          tabindex="1"
        />
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<style scoped lang="scss"></style>

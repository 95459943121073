<script setup lang="ts" generic="MM">
import FormField from '@/components/input/FormField.vue'
import { Validation, ValidationArgs } from '@vuelidate/core'
import { ref, watch } from 'vue'

const props = defineProps<{
  label?: string
  validation?: Validation<ValidationArgs, MM>
}>()
const emit = defineEmits<{
  blur: []
}>()
const model = defineModel<number | undefined>()

const privateVal = ref<string>('')
watch(
  model,
  (v) => {
    privateVal.value = v ? `${v / 1000}` : ''
  },
  { immediate: true },
)
watch(privateVal, (v) => {
  const val = parseFloat(privateVal.value)
  if (isNaN(val)) {
    return
  }

  model.value = Math.trunc(val * 1000)
})

const onBlur = () => {
  const val = parseFloat(privateVal.value)
  if (isNaN(val)) {
    model.value = undefined
    privateVal.value = ''
  }

  props.validation?.$touch()
  emit('blur')
}
</script>

<template>
  <form-field :label="props.label" :validation="props.validation">
    <v-text-field
      v-model="privateVal"
      v-bind="$attrs"
      hide-details
      style="min-width: 8em"
      @blur="onBlur"
    >
      <template v-for="(_, slot) of $slots" #[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
    </v-text-field>
  </form-field>
</template>

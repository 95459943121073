<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, ref, watch } from 'vue'
import useCurrentProductionBatch from '@/components/operator/currentProductionBatch'
import { useLazyQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import {
  CompleteProductionBranchDataQuery,
  CompleteProductionBranchDataQueryVariables,
} from '@/generated/graphql'
import { currentUserAndPermissions } from '@/app'
import InvalidStepTreeNode from '@/components/productionBatch/InvalidStepTreeNode.vue'

const props = defineProps<{
  moduleId: string
}>()
const emit = defineEmits<{
  completed: []
}>()

const { t } = useI18n()
const { currentProductionBatch, setCompleted, deselectCurrentProductionBatch } =
  useCurrentProductionBatch()

const fetchQuery = useLazyQuery<
  CompleteProductionBranchDataQuery,
  CompleteProductionBranchDataQueryVariables
>(
  gql`
    query CompleteProductionBranchData($moduleId: ID!) {
      product {
        module(id: $moduleId) {
          id
          abbreviation
          nodes {
            id
            parentId
            step {
              id
              name
              checkConfigurations {
                id
                description
                isRequired
                unreleasedChecks {
                  ... on CheckI {
                    id
                    poNumber
                    serialNumber
                    reportingUser {
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
  () => ({ moduleId: props.moduleId }),
)

type LocalNode = CompleteProductionBranchDataQuery['product']['module']['nodes'][0]
const module = computed(() => fetchQuery.result.value?.product.module)
const nodes = computed<LocalNode[]>(() => fetchQuery.result.value?.product.module.nodes || [])

export type CompleteProductionBatchTreeNode = {
  step: LocalNode['step']
  children: CompleteProductionBatchTreeNode[]
  numbering: string
  isOrHasInvalid: boolean
  invalidCheckConfigs: CompleteProductionBatchTreeNode['step']['checkConfigurations']
}
const invalidTreeNodes = computed(() =>
  nodes.value
    .filter((n) => !n.parentId)
    .map((n, i) => createTreeNode(n, i, [module.value?.abbreviation || '']))
    .filter((n) => n.isOrHasInvalid),
)
const createTreeNode = (
  node: LocalNode,
  index: number,
  parentNumbering: string[],
): CompleteProductionBatchTreeNode => {
  const invalidCheckConfigs = node.step.checkConfigurations
    .filter((c) => c.isRequired)
    .filter((config) => {
      if (!currentProductionBatch.value) {
        return false
      }

      const poNumber = currentProductionBatch.value.poNumber
      const userId = currentUserAndPermissions.value?.user.id || 'impossible-to-match'
      const hasAllChecks = currentProductionBatch.value.serialNumbers
        .map(
          (serialNumber) =>
            !!config.unreleasedChecks.find(
              (check) =>
                check.poNumber == poNumber &&
                check.serialNumber == serialNumber &&
                check.reportingUser?.id == userId,
            ),
        )
        .reduce((agg, found) => agg && found, true)

      return !hasAllChecks
    })
  const ancestryNumbering = [...parentNumbering, `${index + 1}`]
  const children = nodes.value
    .filter((n) => n.parentId == node.id)
    .map((c, i) => createTreeNode(c, i, ancestryNumbering))
  const isOrHasInvalid = invalidCheckConfigs.length > 0 || children.some((c) => c.isOrHasInvalid)

  return {
    step: node.step,
    numbering: ancestryNumbering.join('.'),
    children,
    isOrHasInvalid,
    invalidCheckConfigs,
  }
}
const invalidChecks = computed(() => invalidTreeNodes.value.some((n) => n.isOrHasInvalid))

const isOpen = ref(false)
watch(isOpen, (v) => {
  if (!v) {
    return
  }

  if (!fetchQuery.load()) {
    fetchQuery.refetch()
  }
})

const data = computed(() => ({
  poNumber: currentProductionBatch.value?.poNumber,
  serialNumbers: currentProductionBatch.value?.serialNumbers.join(', '),
}))

const complete = () => {
  setCompleted()
  deselectCurrentProductionBatch()
  isOpen.value = false
  emit('completed')
}
</script>

<template>
  <template v-if="currentProductionBatch">
    <v-btn v-bind="$attrs" @click="isOpen = true">
      {{ t('component.completeProductionBatch.buttonActivator') }}
    </v-btn>
    <v-dialog v-model="isOpen" max-width="650">
      <v-card>
        <v-card-title>
          {{ t('component.completeProductionBatch.title', data) }}
        </v-card-title>
        <v-card-text>
          <v-alert
            v-if="invalidTreeNodes.length > 0"
            :title="t('component.completeProductionBatch.incompleteChecks')"
            type="error"
            prominent
            class="mb-5"
          >
            <v-list density="compact">
              <invalid-step-tree-node v-for="n in invalidTreeNodes" :key="n.step.id" :node="n" />
            </v-list>
          </v-alert>

          <p v-if="invalidTreeNodes.length == 0">
            {{ t('component.completeProductionBatch.text', data) }}
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="isOpen = false">
            {{ t('component.completeProductionBatch.buttonCancel') }}
          </v-btn>
          <v-spacer />
          <v-btn variant="elevated" color="primary" :disabled="invalidChecks" @click="complete()">
            {{ t('component.completeProductionBatch.buttonSave') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
</template>

<style scoped lang="scss"></style>

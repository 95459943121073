<script setup lang="ts" generic="MM">
import useVuelidate from '@vuelidate/core'
import { useI18n } from 'vue-i18n'
import TextField from '@/components/input/TextField.vue'
import { helpers } from '@vuelidate/validators'
import { computed, ComputedRef, ref, watch } from 'vue'
import { debounce } from 'lodash'
import { useQuery } from '@vue/apollo-composable'
import { Query } from '@/generated/graphql'
import gql from 'graphql-tag'
import { email, required } from '@/validation'

const props = defineProps<{
  modelValue?: string
  label: string
  required?: boolean
  currentUserId?: string
}>()
const emit = defineEmits<{
  'update:modelValue': [value: string]
  blur: []
}>()

const { t } = useI18n()

const model = computed(() => props.modelValue)
const debouncedEmailSearch = ref<string>()
const debouncer = debounce(() => {
  debouncedEmailSearch.value = model.value
}, 500)
watch(model, debouncer)
const fetchQuery = useQuery<Query, { email?: string; userId?: string }>(
  gql`
    query isUserEmailTaken($email: String!, $userId: ID) {
      account {
        isEmailTaken(email: $email, exceptUser: $userId)
      }
    }
  `,
  () => ({ email: debouncedEmailSearch.value || '', userId: props.currentUserId }),
  () => ({ enabled: (debouncedEmailSearch.value?.length || 0) > 5 }),
)
const isTaken = computed<boolean>(() => fetchQuery.result.value?.account.isEmailTaken || false)

const validation = useVuelidate<ComputedRef<string | undefined>>(
  {
    required,
    email,
    taken: helpers.withMessage(t('validation.userEmail.taken'), () => !isTaken.value),
  },
  model,
)

function onBlur() {
  validation.value.$touch()
  emit('blur')
}
</script>

<template>
  <text-field
    v-bind="$attrs"
    :label="label"
    :model-value="props.modelValue"
    :required="props.required"
    :validation="validation"
    @update:model-value="(v: string) => emit('update:modelValue', v)"
    @blur="onBlur"
  />
</template>

<style scoped lang="scss"></style>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { downloadMbom } from '@/components/mbom/mbom'

const props = defineProps<{
  bopId: string
  module: DownloadMbomButtonModule
  short?: boolean
}>()

export type DownloadMbomButtonModule = {
  id: string
  name: string
  abbreviation: string
}

const { t } = useI18n()

function download() {
  downloadMbom(props.bopId, [props.module.id], `-${props.module.abbreviation}`)
}
</script>

<template>
  <v-btn @click="download">
    <template v-if="props.short">
      {{ t('component.downloadModuleMbomButton.labelShort') }}
    </template>
    <template v-else>
      {{ t('component.downloadModuleMbomButton.labelLong', { name: props.module.name }) }}
    </template>
  </v-btn>
</template>

<style scoped lang="scss"></style>

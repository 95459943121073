<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, ref } from 'vue'
import { EbomPartTreeEbomPart } from '@/components/ebom/EbomPartTree.vue'
import ArticleQuantity from '@/components/article/ArticleQuantity.vue'
import ArticleName from '@/components/article/ArticleName.vue'

const props = defineProps<{
  node: EbomPartNode
  selectedEbomPartId?: string
  visibleEbomPartIds: string[]
}>()
defineEmits<{
  part: [value: EbomPartTreeEbomPart]
}>()

const { t } = useI18n()
export type EbomPartNode = {
  ebomPart: EbomPartTreeEbomPart
  hasAllocationAbberation: boolean
  children: EbomPartNode[]
}

const ebomPart = computed(() => props.node.ebomPart)
const isHighlighted = computed(() => props.selectedEbomPartId == ebomPart.value.id)
const isOpen = ref(
  props.selectedEbomPartId
    ? isEbomPartIdOrHasChildWithEbomPartId(props.node, [props.selectedEbomPartId])
    : false,
)
const isVisible = computed(() =>
  isEbomPartIdOrHasChildWithEbomPartId(props.node, props.visibleEbomPartIds),
)
function isEbomPartIdOrHasChildWithEbomPartId(node: EbomPartNode, ebomPartIds: string[]): boolean {
  if (ebomPartIds.includes(node.ebomPart.id)) {
    return true
  }

  return node.children.some((n) => isEbomPartIdOrHasChildWithEbomPartId(n, ebomPartIds))
}
const hasChildren = computed(() => props.node.children.length > 0)
</script>

<template>
  <v-list-item
    v-if="isVisible"
    class="ebom-part-tree-node"
    :variant="isHighlighted ? 'tonal' : 'text'"
    @click="$emit('part', node.ebomPart)"
  >
    <template #prepend>
      <v-icon
        density="compact"
        :icon="hasChildren ? (isOpen ? 'folder_open' : 'folder') : 'settings'"
        :color="hasChildren && props.node.hasAllocationAbberation ? 'error' : undefined"
        @click.stop="isOpen = !isOpen"
      />
    </template>
    <template #append>
      <v-chip v-if="ebomPart.undecidedSuggestionsCount > 0" density="compact" class="count">
        {{ ebomPart.undecidedSuggestionsCount }}
      </v-chip>
      <v-chip
        v-if="!!ebomPart.article"
        :color="ebomPart.netAllocationMilliQuantity == 0 ? 'success' : 'error'"
        density="compact"
        class="count"
      >
        <article-quantity
          :milli-quantity="ebomPart.netAllocationMilliQuantity"
          :unit="ebomPart.unit"
          unit-abbreviated
        />
      </v-chip>
    </template>
    <v-list-item-title>
      <article-name :article="props.node.ebomPart" />
    </v-list-item-title>
  </v-list-item>

  <div v-if="isOpen" class="children">
    <ebom-part-tree-node
      v-for="n in props.node.children"
      :key="n.ebomPart.id"
      :node="n"
      :selected-ebom-part-id="props.selectedEbomPartId"
      :visible-ebom-part-ids="props.visibleEbomPartIds"
      @part="(v) => $emit('part', v)"
    />
  </div>
</template>

<style scoped lang="scss">
.children {
  margin-left: 0.7em;
}
</style>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, ref, watch } from 'vue'
import ViewStep from '@/components/step/ViewStep.vue'
import { OperatorModuleViewTreeNode } from '@/views/organization/operator/OperatorModuleView.vue'
import CompleteProductionBatch from '@/components/productionBatch/CompleteProductionBatch.vue'
import StepChecks from '@/components/check/StepChecks.vue'

const props = defineProps<{
  moduleId: string
  stepId?: string
  treeNodes: OperatorModuleViewTreeNode[]
}>()
const emit = defineEmits<{
  selectStepId: [value: string | undefined]
  done: []
}>()

const { t } = useI18n()

const viableNodes = computed(() => {
  const steps: OperatorModuleViewTreeNode[] = []
  props.treeNodes.forEach((n) => recursiveAddIfChildless(n, steps))
  return steps
})
function recursiveAddIfChildless(
  node: OperatorModuleViewTreeNode,
  aggregator: OperatorModuleViewTreeNode[],
) {
  if (node.children.length == 0) {
    aggregator.push(node)
    return
  }

  node.children.forEach((n) => recursiveAddIfChildless(n, aggregator))
}
watch(viableNodes, (v) => {
  if (props.stepId || v.length == 0) {
    return
  }

  emit('selectStepId', v[0].stepNode.step.id)
})

const stepperModel = ref<number>(0)

watch(
  () => [props.stepId, viableNodes.value],
  () => {
    const index = viableNodes.value.findIndex((s) => s.stepNode.step.id == props.stepId)
    if (index >= 0) {
      stepperModel.value = index
    }
  },
  { immediate: true },
)

const isLastWindow = computed(() => stepperModel.value == viableNodes.value.length - 1)
function previous() {
  stepperModel.value = stepperModel.value - 1
  emit('selectStepId', viableNodes.value[stepperModel.value]?.stepNode.step.id)
}
function next() {
  stepperModel.value = stepperModel.value + 1
  emit('selectStepId', viableNodes.value[stepperModel.value]?.stepNode.step.id)
}
</script>

<template>
  <v-card>
    <v-card-title>
      <v-btn variant="tonal" :disabled="stepperModel == 0" @click="previous">
        {{ t('component.operatorModuleStepper.previousButton') }}
      </v-btn>
      <v-btn v-if="!isLastWindow" variant="tonal" color="primary" class="float-right" @click="next">
        {{ t('component.operatorModuleStepper.nextButton') }}
      </v-btn>
      <complete-production-batch
        v-if="isLastWindow"
        :module-id="moduleId"
        variant="tonal"
        color="primary"
        class="float-right"
        @completed="$router.push({ name: 'operatorHome' })"
      />
    </v-card-title>
    <v-card-text>
      <v-window v-model="stepperModel" class="mt-3 mb-3">
        <v-window-item
          v-for="(s, i) in viableNodes"
          :key="s.stepNode.id"
          :value="i"
          :eager="i == stepperModel + 1"
        >
          <view-step :step-id="s.stepNode.step.id" />
          <step-checks :step-id="s.stepNode.step.id" class="mt-10 mb-1" />
        </v-window-item>
      </v-window>
    </v-card-text>
    <v-card-actions>
      <v-btn variant="tonal" :disabled="stepperModel == 0" @click="previous">
        {{ t('component.operatorModuleStepper.previousButton') }}
      </v-btn>
      <v-spacer />
      <v-btn v-if="!isLastWindow" variant="tonal" color="primary" @click="next">
        {{ t('component.operatorModuleStepper.nextButton') }}
      </v-btn>
      <complete-production-batch
        v-if="isLastWindow"
        :module-id="moduleId"
        variant="tonal"
        color="primary"
        @completed="$router.push({ name: 'operatorHome' })"
      />
    </v-card-actions>
  </v-card>
</template>

<style scoped lang="scss"></style>

<script setup lang="ts">
import { authzIsGlobalAdmin, authzIsOrgAdmin, authzIsOrgArticleManagement } from '@/app'
import { useI18n } from 'vue-i18n'
import { upperFirst } from 'lodash'
import ExceptionTester from '@/components/ExceptionTester.vue'
import { useRoute } from 'vue-router'
import { computed } from 'vue'

const props = defineProps<{
  modelValue: boolean
}>()
defineEmits<{
  (e: 'update:modelValue', value: boolean): void
}>()

const { t } = useI18n()
const route = useRoute()

const isInOrgContext = computed(() => route.params.organizationSlug)
</script>

<template>
  <v-navigation-drawer
    nav
    class="main-navigation-drawer"
    theme="dark"
    :model-value="props.modelValue"
    @update:model-value="$emit('update:modelValue', false)"
  >
    <template v-if="authzIsGlobalAdmin">
      <h3>{{ t('navigationDrawer.administration') }}</h3>
      <v-list density="compact">
        <router-link :to="{ name: 'admin-organizations' }">
          <v-list-item :title="upperFirst(t('entity.organization.plural'))">
            <template #prepend>
              <v-icon icon="corporate_fare" />
            </template>
          </v-list-item>
        </router-link>
        <router-link :to="{ name: 'admin-users' }">
          <v-list-item :title="upperFirst(t('entity.user.plural'))">
            <template #prepend>
              <v-icon icon="people" />
            </template>
          </v-list-item>
        </router-link>
      </v-list>
    </template>
    <template v-if="isInOrgContext">
      <h3>{{ t('navigationDrawer.organization') }}</h3>
      <v-list density="compact">
        <template v-if="authzIsOrgAdmin">
          <router-link :to="{ name: 'sites' }">
            <v-list-item :title="upperFirst(t('entity.site.plural'))">
              <template #prepend>
                <v-icon icon="factory" />
              </template>
            </v-list-item>
          </router-link>
          <router-link :to="{ name: 'tools' }">
            <v-list-item :title="upperFirst(t('entity.tool.plural'))">
              <template #prepend>
                <v-icon icon="handyman" />
              </template>
            </v-list-item>
          </router-link>
          <router-link :to="{ name: 'stepTypes' }">
            <v-list-item :title="upperFirst(t('entity.stepType.plural'))">
              <template #prepend>
                <v-icon icon="list" />
              </template>
            </v-list-item>
          </router-link>
        </template>
        <router-link v-if="authzIsOrgArticleManagement" :to="{ name: 'articles' }">
          <v-list-item :title="upperFirst(t('entity.article.plural'))">
            <template #prepend>
              <v-icon icon="settings" />
            </template>
          </v-list-item>
        </router-link>
        <router-link :to="{ name: 'products' }">
          <v-list-item :title="upperFirst(t('entity.product.plural'))">
            <template #prepend>
              <v-icon icon="precision_manufacturing" />
            </template>
          </v-list-item>
        </router-link>
      </v-list>
    </template>
    <exception-tester />
  </v-navigation-drawer>
</template>

<style lang="scss">
@media print {
  .main-navigation-drawer {
    display: none !important;
  }
}
.main-navigation-drawer .v-navigation-drawer__content {
  padding: 15px 5px;

  a,
  a:visited {
    color: var(--v-theme-primary);
    text-decoration: none;
  }
}
</style>

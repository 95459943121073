<script setup lang="ts" generic="T, MM">
import { Query } from '@/generated/graphql'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import SelectField, { SelectOption } from '@/components/input/SelectField.vue'
import { computed } from 'vue'
import { itemSortByName } from '@/app'

const props = defineProps<{
  modelValue?: string
  label: string
  required?: boolean
}>()
defineEmits<{
  'update:modelValue': [value: string]
}>()

const fetchQuery = useQuery<Query>(gql`
  query getStepTypesForPicker {
    product {
      stepTypes {
        id
        name
        archived
      }
    }
  }
`)
const options = computed<SelectOption<string>[]>(() =>
  itemSortByName(fetchQuery.result.value?.product.stepTypes.filter((t) => !t.archived), (t) => t.name).map((t) => ({
    title: `${t.name} (${t.operation})`,
    value: t.id,
  })),
)
</script>

<template>
  <select-field
    :label="props.label"
    :required="props.required"
    :items="options"
    :model-value="props.modelValue"
    @update:model-value="(t) => $emit('update:modelValue', t)"
  />
</template>

<style scoped lang="scss"></style>

import { Ref, ref } from 'vue'

const globalSelectedIds: { [key: string]: Ref<string[]> } = {}

export default function useIdSelection(identifier: string) {
  if (!globalSelectedIds[identifier]) {
    globalSelectedIds[identifier] = ref<string[]>([])
  }

  const selectedIds = globalSelectedIds[identifier]

  const resetSelection = () => {
    selectedIds.value = []
  }
  const selectId = (id: string) => {
    selectedIds.value.push(id)
  }
  const deselectId = (id: string) => {
    selectedIds.value = selectedIds.value.filter((sId) => sId != id)
  }

  return {
    selectedIds,
    resetSelection,
    selectId,
    deselectId,
  }
}

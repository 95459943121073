<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useMutation, useQuery } from '@vue/apollo-composable'
import {
  EbomPartAllocationSuggestionDecision,
  EbomPartDetailsAcceptSuggestionMutation,
  EbomPartDetailsAcceptSuggestionMutationVariables,
  EbomPartDetailsAllocateEbomPartToStepMutation,
  EbomPartDetailsAllocateEbomPartToStepMutationVariables,
  EbomPartDetailsEbomPartQuery,
  EbomPartDetailsEbomPartQueryVariables,
  EbomPartDetailsModulesAndStepsQuery,
  EbomPartDetailsModulesAndStepsQueryVariables,
  EbomPartDetailsPromoteToArticlesMutation,
  EbomPartDetailsRejectSuggestionMutation,
  EbomPartDetailsRejectSuggestionMutationVariables,
  Step,
} from '@/generated/graphql'
import gql from 'graphql-tag'
import { computed, reactive, ref, watch } from 'vue'
import { SelectOption } from '../input/SelectField.vue'
import SelectField from '@/components/input/SelectField.vue'
import useVuelidate from '@vuelidate/core'
import { minValue, required } from '@/validation'
import NotificationSaved from '@/components/notifications/NotificationSaved.vue'
import NotificationDeleted from '@/components/notifications/NotificationDeleted.vue'
import StepPicker from '@/components/input/StepPicker.vue'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import ArticleQuantity from '@/components/article/ArticleQuantity.vue'
import ArticleQuantityField from '@/components/input/ArticleQuantityField.vue'

const props = defineProps<{
  bopId: string
  ebomPartId: string
}>()

type LocalModule = EbomPartDetailsModulesAndStepsQuery['product']['bop']['nodes'][0]['module']
type LocalEbomPart = EbomPartDetailsEbomPartQuery['article']['ebomPart']

const { t } = useI18n()
const modulesAndStepsFetchQuery = useQuery<
  EbomPartDetailsModulesAndStepsQuery,
  EbomPartDetailsModulesAndStepsQueryVariables
>(
  gql`
    query EbomPartDetailsModulesAndSteps($bopId: ID!) {
      product {
        bop(id: $bopId) {
          nodes {
            module {
              id
              name
              nodes {
                step {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  `,
  () => ({
    bopId: props.bopId,
  }),
)
const modules = computed(
  () => modulesAndStepsFetchQuery.result.value?.product.bop.nodes.map((n) => n.module) || [],
)
const stepToModule = computed(() => {
  let map: { [key: string]: LocalModule } = {}

  modules.value.forEach((m) => {
    m.nodes.forEach((n) => {
      map[n.step.id] = m
    })
  })

  return map
})

const ebomPartFetchQuery = useQuery<
  EbomPartDetailsEbomPartQuery,
  EbomPartDetailsEbomPartQueryVariables
>(
  gql`
    query EbomPartDetailsEbomPart($ebomPartId: ID!) {
      article {
        ebomPart(id: $ebomPartId) {
          id
          articleNumber
          revision
          name
          milliQuantity
          unit
          article {
            id
          }
          stepAllocations {
            milliQuantity
            step {
              id
              name
            }
          }
          stepAllocationSuggestions {
            id
            source
            decision
            milliQuantity
            step {
              id
              name
            }
          }
        }
      }
    }
  `,
  () => ({
    ebomPartId: props.ebomPartId,
  }),
)
const ebomPart = computed<LocalEbomPart | undefined>(
  () => ebomPartFetchQuery.result.value?.article.ebomPart,
)
const isAllocatable = computed(() => !!ebomPart.value?.article)
const stepAllocations = computed(() => ebomPart.value?.stepAllocations || [])
const stepAllocationSuggestions = computed(
  () =>
    ebomPart.value?.stepAllocationSuggestions.filter(
      (s) => s.decision == EbomPartAllocationSuggestionDecision.Undecided,
    ) || [],
)
const totalMilliQuantityAllocated = computed(() =>
  stepAllocations.value.reduce((p, c) => p + c.milliQuantity, 0),
)
const netMilliQuantityAllocated = computed(
  () => totalMilliQuantityAllocated.value - (ebomPart.value?.milliQuantity || 0),
)

const notifySaved = ref(false)

const askForPromotion = ref<boolean>(false)
const promoteToArticleMutation = useMutation<
  EbomPartDetailsPromoteToArticlesMutation,
  EbomPartDetailsPromoteToEbomPartMutationVariables
>(gql`
  mutation EbomPartDetailsPromoteToArticles($id: ID!) {
    article {
      promoteEbomPartsToArticles(ids: [$id]) {
        id
      }
    }
  }
`)
function promoteToArticle() {
  promoteToArticleMutation.mutate({ id: props.ebomPartId }).then(() => {
    notifySaved.value = true
    ebomPartFetchQuery.refetch()
  })
}

type Model = {
  module?: LocalModule
  step?: Step
  milliQuantity: number
}
const model = reactive<Model>({
  milliQuantity: 1000,
})
const validation = useVuelidate<Model>(
  {
    module: { required },
    step: { required },
    milliQuantity: { required, min: minValue(1) },
  },
  model,
)
watch(
  () => props.ebomPartId,
  () => {
    model.module = undefined
    model.step = undefined
    model.milliQuantity = 1000
  },
)
watch(
  () => model.module,
  () => {
    model.step = undefined
    model.milliQuantity = 1000
    validation.value.$reset()
  },
)
watch(
  () => model.step,
  () => {
    model.milliQuantity = 1000
    validation.value.$reset()
  },
)

const moduleOptions = computed<SelectOption<LocalModule>[]>(() =>
  modules.value.map((m) => ({
    title: m.name,
    value: m,
  })),
)

const allocateNewMutation = useMutation<
  EbomPartDetailsAllocateEbomPartToStepMutation,
  EbomPartDetailsAllocateEbomPartToStepMutationVariables
>(
  gql`
    mutation EbomPartDetailsAllocateEbomPartToStep($command: AllocateEbomPartToStepCommand!) {
      article {
        allocateEbomPartToStep(command: $command)
      }
    }
  `,
  {
    refetchQueries: ['EbomPartTreeEbomParts', 'EbomDiffTreeData'],
  },
)
function allocateNew() {
  validation.value.$touch()
  if (validation.value.$invalid || !isAllocatable.value) {
    return
  }

  const ebomPartId = ebomPart.value?.id as string
  const stepId = model.step?.id as string

  allocateNewMutation
    .mutate({
      command: {
        ebomPartId,
        stepId,
        milliQuantity: model.milliQuantity,
      },
    })
    .then(() => {
      notifySaved.value = true
      ebomPartFetchQuery.refetch()
      model.module = undefined
      model.step = undefined
      model.milliQuantity = 1000
      validation.value.$reset()
    })
}

const acceptSuggestionMutation = useMutation<
  EbomPartDetailsAcceptSuggestionMutation,
  EbomPartDetailsAcceptSuggestionMutationVariables
>(
  gql`
    mutation EbomPartDetailsAcceptSuggestion($command: AcceptEbomPartAllocationSuggestionCommand!) {
      article {
        acceptEbomPartAllocationSuggestion(command: $command) {
          decision
        }
      }
    }
  `,
  {
    refetchQueries: ['EbomPartTreeEbomParts'],
  },
)
function acceptSuggestion(id: string, milliQuantity: number) {
  acceptSuggestionMutation
    .mutate({
      command: {
        id,
        milliQuantity,
      },
    })
    .then(() => {
      notifySaved.value = true
      ebomPartFetchQuery.refetch()
    })
}

const rejectSuggestionMutation = useMutation<
  EbomPartDetailsRejectSuggestionMutation,
  EbomPartDetailsRejectSuggestionMutationVariables
>(
  gql`
    mutation EbomPartDetailsRejectSuggestion($id: ID!) {
      article {
        rejectEbomPartAllocationSuggestion(id: $id) {
          decision
        }
      }
    }
  `,
  {
    refetchQueries: ['EbomPartTreeEbomParts'],
  },
)
function rejectSuggestion(id: string) {
  rejectSuggestionMutation.mutate({ id }).then(() => {
    notifySaved.value = true
    ebomPartFetchQuery.refetch()
  })
}

const notifyDeleted = ref(false)
function deleteAllocation(stepId: string, ebomPartId: string) {
  allocateNewMutation
    .mutate({
      command: {
        stepId,
        ebomPartId,
        milliQuantity: 0,
      },
    })
    .then(() => {
      notifyDeleted.value = true
      ebomPartFetchQuery.refetch()
    })
}
</script>

<template>
  <h3>{{ ebomPart?.articleNumber }}</h3>

  <v-table density="compact" style="vertical-align: top">
    <tbody>
      <tr>
        <td style="width: 18em">{{ t('entity.ebomPart.field.name') }}</td>
        <td>{{ ebomPart?.name }}</td>
      </tr>
      <tr>
        <td>{{ t('entity.ebomPart.field.revision') }}</td>
        <td>{{ ebomPart?.revision }}</td>
      </tr>
      <tr>
        <td>
          {{ t('entity.ebomPart.isPromoted') }}
        </td>
        <td>
          <v-icon :icon="!!ebomPart?.article ? 'check' : 'highlight_off'" />
          <v-btn
            v-if="!isAllocatable"
            color="primary"
            variant="elevated"
            density="compact"
            class="ml-10"
            :loading="promoteToArticleMutation.loading.value"
            @click="askForPromotion = true"
          >
            {{ t('component.ebomPartDetails.buttonPromoteEbomPart') }}
          </v-btn>
        </td>
      </tr>
      <tr>
        <td>
          {{ t('entity.ebomPart.field.quantity') }}
        </td>
        <td>
          <article-quantity :milli-quantity="ebomPart?.milliQuantity" :unit="ebomPart?.unit" />
        </td>
      </tr>
      <tr v-if="isAllocatable">
        <td>
          {{ t('component.ebomPartDetails.quantityAllocatedToSteps') }}
        </td>
        <td>
          <article-quantity :milli-quantity="totalMilliQuantityAllocated" :unit="ebomPart?.unit" />

          <v-chip
            density="comfortable"
            :color="netMilliQuantityAllocated == 0 ? 'success' : 'error'"
            class="ml-2"
          >
            <article-quantity :milli-quantity="netMilliQuantityAllocated" :unit="ebomPart?.unit" />
          </v-chip>
        </td>
      </tr>
    </tbody>
  </v-table>

  <h3 class="mt-5">
    {{ t('component.ebomPartDetails.headerStepAllocations') }}
  </h3>
  <v-table density="compact">
    <thead>
      <tr>
        <th style="width: 35%">{{ t('component.ebomPartDetails.tableHeaderModule') }}</th>
        <th style="width: 40%">{{ t('component.ebomPartDetails.tableHeaderStep') }}</th>
        <th style="width: 25%">{{ t('component.ebomPartDetails.tableHeaderQuantity') }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-if="isAllocatable">
        <td>
          <select-field
            v-model="model.module"
            :items="moduleOptions"
            density="compact"
            :validation="validation.module"
          />
        </td>
        <td>
          <step-picker
            v-if="!!model.module"
            v-model="model.step"
            :module-id="model.module?.id"
            density="compact"
            :validation="validation.step"
          />
        </td>
        <td>
          <article-quantity-field
            v-model="model.milliQuantity"
            :validation="validation.milliQuantity"
            :unit="ebomPart?.unit"
            density="compact"
          >
            <template #append>
              <v-btn
                density="compact"
                variant="flat"
                icon="add"
                :disabled="validation.$invalid"
                :loading="allocateNewMutation.loading.value"
                @click="allocateNew()"
              />
            </template>
          </article-quantity-field>
        </td>
      </tr>
      <template v-for="s in stepAllocationSuggestions" :key="s.id">
        <tr class="bg-grey-lighten-4">
          <td class="suggestion-module">
            <router-link
              v-if="!!stepToModule[s.step.id]"
              :to="{ name: 'module', params: { moduleId: stepToModule[s.step.id].id } }"
            >
              {{ stepToModule[s.step.id]?.name }}
            </router-link>
            <v-chip density="compact" class="float-right">
              {{ t('component.ebomPartDetails.labelSuggestion') }}
            </v-chip>
          </td>
          <td>
            <router-link
              v-if="!!stepToModule[s.step.id]"
              :to="{
                name: 'module',
                params: { moduleId: stepToModule[s.step.id].id, stepId: s.step.id },
              }"
            >
              {{ s.step.name }}
            </router-link>
          </td>
          <td>
            <article-quantity :milli-quantity="s.milliQuantity" :unit="ebomPart?.unit" />
            <v-btn
              class="float-right"
              variant="text"
              density="compact"
              icon="thumb_down"
              :loading="allocateNewMutation.loading.value"
              @click="rejectSuggestion(s.id)"
            />
            <v-btn
              class="float-right mr-1"
              variant="text"
              density="compact"
              icon="thumb_up"
              :loading="allocateNewMutation.loading.value"
              @click="acceptSuggestion(s.id, s.milliQuantity)"
            />
          </td>
        </tr>
      </template>
      <tr v-for="a in stepAllocations" :key="a.step.id">
        <td>
          <router-link
            v-if="!!stepToModule[a.step.id]"
            :to="{ name: 'module', params: { moduleId: stepToModule[a.step.id].id } }"
          >
            {{ stepToModule[a.step.id]?.name }}
          </router-link>
        </td>
        <td>
          <router-link
            v-if="!!stepToModule[a.step.id]"
            :to="{
              name: 'module',
              params: { moduleId: stepToModule[a.step.id].id, stepId: a.step.id },
            }"
          >
            {{ a.step.name }}
          </router-link>
        </td>
        <td>
          <article-quantity :milli-quantity="a.milliQuantity" :unit="ebomPart?.unit" />
          <v-btn
            class="float-right"
            variant="text"
            density="compact"
            icon="delete"
            :loading="allocateNewMutation.loading.value"
            @click="deleteAllocation(a.step.id, props.ebomPartId)"
          />
        </td>
      </tr>
    </tbody>
  </v-table>

  <confirm-dialog
    v-model="askForPromotion"
    :title="t('component.ebomPartDetails.promoteDialogTitle')"
    :content="t('component.ebomPartDetails.promoteDialogContent')"
    @ok="promoteToArticle()"
  />
  <notification-saved v-model="notifySaved" />
  <notification-deleted v-model="notifyDeleted" />
</template>

<style scoped lang="scss"></style>

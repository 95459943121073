<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import OperatorStepTreeNode from '@/components/operator/OperatorStepTreeNode.vue'
import { OperatorModuleViewTreeNode } from '@/views/organization/operator/OperatorModuleView.vue'
import { ref } from 'vue'

const props = defineProps<{
  loading: boolean
  treeNodes: OperatorModuleViewTreeNode[]
}>()
defineEmits<{
  selectStepId: [value: string]
}>()

const { t } = useI18n()

const isRail = ref(false)
</script>

<template>
  <v-btn
    :icon="isRail ? 'chevron_left' : 'chevron_right'"
    density="comfortable"
    :size="40"
    style="position: absolute; left: -20px; top: 5px"
    @click.stop="isRail = !isRail"
  />

  <div v-if="!isRail" class="pl-8">
    <h2 class="mb-2">
      {{ t('component.operatorStepTree.title') }}
      <v-progress-circular v-if="props.loading" indeterminate size="20" />
    </h2>

    <operator-step-tree-node
      v-for="n in props.treeNodes"
      :key="n.stepNode.id"
      :node="n"
      @select-step-id="(v) => $emit('selectStepId', v)"
    />
  </div>
</template>

<style scoped lang="scss"></style>

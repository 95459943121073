<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'
import useAsyncDialog from '@/components/dialogs/useAsyncDialog'
import NotificationDeleted from '@/components/notifications/NotificationDeleted.vue'
import Checkbox from '@/components/input/Checkbox.vue'

const props = defineProps<{
  warning?: string
  info?: string
  hindrance?: boolean
}>()

const { t } = useI18n()
const { openAsyncDialog, asyncDialogResolve, asyncDialogLoading, asyncDialogIsOpen } =
  useAsyncDialog<() => Promise<void>>()

const explicitAcceptance = ref(false)
const itemName = ref<string>()
const deleteMutation = ref<() => Promise<unknown>>()
const open = async (name: string, mutation: () => Promise<unknown>) => {
  explicitAcceptance.value = !props.hindrance
  itemName.value = name
  deleteMutation.value = mutation
  return openAsyncDialog()
}

const notifyDeleted = ref(false)
const doDelete = async () => {
  if (!deleteMutation.value) {
    return
  }

  await deleteMutation.value()
  notifyDeleted.value = true
}

defineExpose({ open })
</script>

<template>
  <v-dialog v-if="asyncDialogResolve" v-model="asyncDialogIsOpen" max-width="650" v-bind="$attrs">
    <v-card>
      <v-card-title>
        {{ t('component.deleteDialog.title', { name: itemName }) }}
      </v-card-title>
      <v-card-text>
        <v-alert
          v-if="!!props.warning"
          type="warning"
          :title="t('notification.deleteWarningTitle')"
          variant="outlined"
          style="margin-bottom: 1em"
        >
          {{ props.warning }}
        </v-alert>

        <v-alert v-if="!!props.info" type="info" variant="outlined" style="margin-bottom: 1em">
          {{ props.info }}
        </v-alert>

        <checkbox
          v-if="props.hindrance"
          v-model="explicitAcceptance"
          required
          :label="t('notification.deleteHindranceText')"
        />

        <p>
          {{ t('component.deleteDialog.deleteText', { name: itemName }) }}
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="asyncDialogResolve()">
          {{ t('button.cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          variant="elevated"
          :disabled="!explicitAcceptance"
          :loading="asyncDialogLoading"
          @click="asyncDialogResolve(doDelete)"
        >
          {{ t('button.delete') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <notification-deleted v-model="notifyDeleted" />
</template>

<style scoped lang="scss"></style>

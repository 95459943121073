<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { EbomPartDiff } from '@/components/ebom/EbomDiffTree.vue'
import { computed, ref } from 'vue'
import { useMutation } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import {
  EbomDiffSamePartsMutation,
  EbomDiffSamePartsMutationVariables,
  ReplaceEbomPartAllocation,
} from '@/generated/graphql'

const props = defineProps<{
  oldEbomName: string
  newEbomName: string
  diffs: EbomPartDiff[]
}>()
defineEmits<{}>()

const allocatedOldParts = computed(() =>
  props.diffs
    .filter((d) => d.status == 'same')
    .filter((d) => !!d.inOld && d.inOld.stepAllocations.length != 0),
)
const replacements = computed<ReplaceEbomPartAllocation[]>(() =>
  allocatedOldParts.value.map((d) => ({
    oldEbomPartId: d.inOld?.id as string,
    newEbomPartId: d.inNew?.id as string,
  })),
)

const notifySwapped = ref(false)
const mutation = useMutation<EbomDiffSamePartsMutation, EbomDiffSamePartsMutationVariables>(
  gql`
    mutation EbomDiffSameParts($replacements: [ReplaceEbomPartAllocation!]!) {
      article {
        replaceEbomPartAllocations(command: { replacements: $replacements })
      }
    }
  `,
  {
    refetchQueries: ['EbomDiffTreeData'],
  },
)
const replaceOverlapped = () => {
  mutation
    .mutate({
      replacements: replacements.value,
    })
    .then(() => {
      notifySwapped.value = true
    })
}

const { t } = useI18n()
</script>

<template>
  <v-list-item v-if="allocatedOldParts.length > 0" class="ebom-diff-overlapping" rounded>
    <template #prepend>
      <v-icon icon="settings_suggest" />
    </template>

    <v-list-item-title class="font-weight-bold">
      {{ t('component.ebomDiffSameParts.title', { count: allocatedOldParts.length }) }}
    </v-list-item-title>

    <span style="font-size: 0.9em">
      {{
        t('component.ebomDiffSameParts.swapExplanation', {
          oldEbomName: props.oldEbomName,
          newEbomName: props.newEbomName,
        })
      }}
    </span>

    <template #append>
      <v-btn variant="tonal" :loading="mutation.loading.value" @click="replaceOverlapped()">
        {{ t('component.ebomDiffSameParts.swapButton') }}
      </v-btn>
    </template>
  </v-list-item>

  <v-snackbar v-model="notifySwapped" timeout="5000">
    {{ t('component.ebomDiffSameParts.notificationSwapped') }}
  </v-snackbar>
</template>

<style lang="scss">
.ebom-diff-overlapping {
  background: rgba(var(--v-theme-info), var(--v-activated-opacity)) !important;
}
</style>

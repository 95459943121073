import type { ThemeDefinition } from 'vuetify'

export const customLightTheme: ThemeDefinition = {
  dark: false,
  colors: {
    background: '#fff',
    surface: '#fff',
    primary: '#004D89',
    'primary-darken-1': '#002C4D',
    secondary: '#D53D00',
    'secondary-darken-1': '#A93000',
    error: '#FF4900',
    info: '#018CF6',
    success: '#00B668',
    warning: '#D5A000',
  },
}

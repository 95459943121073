<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { CheckType } from '@/generated/graphql'
import { computed } from 'vue'

const props = defineProps<{
  type: CheckType
}>()

const { t } = useI18n()

const icon = computed(() => {
  const t = props.type
  if (t == CheckType.String) {
    return 'article'
  }
  if (t == CheckType.Numeric) {
    return 'pin'
  }
  if (t == CheckType.Confirmation) {
    return 'check_box'
  }

  throw Error(`Unexpected check type: ${t}`)
})
</script>

<template>
  <v-icon :icon="icon" :title="t(`entity.checkConfiguration.type.${type}`)" />
</template>

<style scoped lang="scss"></style>

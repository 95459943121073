export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Base64: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  Instant: { input: any; output: any; }
};

export type AcceptEbomPartAllocationSuggestionCommand = {
  id: Scalars['ID']['input'];
  milliQuantity: Scalars['Int']['input'];
};

export type AccountMutationRoot = {
  __typename?: 'AccountMutationRoot';
  adminCreateOrganization: Organization;
  adminCreateUser: User;
  adminDeleteOrganization: Scalars['ID']['output'];
  adminDeleteOrganizationPermissions: Scalars['Boolean']['output'];
  adminSetOrganizationPermissions: Array<PermissionOrganization>;
  adminSetUserPermissions: Array<PermissionUser>;
  adminUpdateOrganization: Organization;
  adminUpdateUser: User;
  deleteOrganizationPermissions: Scalars['Boolean']['output'];
  setOrganizationPermissions: Array<PermissionOrganization>;
  updateOrganization: Organization;
};


export type AccountMutationRootAdminCreateOrganizationArgs = {
  command: CreateOrganization;
};


export type AccountMutationRootAdminCreateUserArgs = {
  command: CreateUser;
};


export type AccountMutationRootAdminDeleteOrganizationArgs = {
  id: Scalars['ID']['input'];
};


export type AccountMutationRootAdminDeleteOrganizationPermissionsArgs = {
  command: DeleteOrganizationPermissions;
};


export type AccountMutationRootAdminSetOrganizationPermissionsArgs = {
  command: SetOrganizationPermissions;
};


export type AccountMutationRootAdminSetUserPermissionsArgs = {
  command: SetUserPermissions;
};


export type AccountMutationRootAdminUpdateOrganizationArgs = {
  command: UpdateOrganization;
};


export type AccountMutationRootAdminUpdateUserArgs = {
  command: UpdateUser;
};


export type AccountMutationRootDeleteOrganizationPermissionsArgs = {
  command: DeleteOrganizationPermissions;
};


export type AccountMutationRootSetOrganizationPermissionsArgs = {
  command: SetOrganizationPermissions;
};


export type AccountMutationRootUpdateOrganizationArgs = {
  command: UpdateOrganization;
};

export type AccountRoot = {
  __typename?: 'AccountRoot';
  adminOrganizationPermissions: Array<OrganizationUserPermissions>;
  adminOrganizations: Array<Organization>;
  adminSearchUsers: Array<User>;
  adminUser: User;
  adminUserPermissions: Array<PermissionUser>;
  currentOrganization: Organization;
  isEmailTaken: Scalars['Boolean']['output'];
  isOrganizationSlugTaken: Scalars['Boolean']['output'];
  me?: Maybe<CurrentUserAndPermissions>;
  organizationBySlug?: Maybe<Organization>;
  organizations: Array<OrganizationUserPermissions>;
};


export type AccountRootAdminOrganizationPermissionsArgs = {
  userId: Scalars['ID']['input'];
};


export type AccountRootAdminSearchUsersArgs = {
  criteria: UserSearchCriteria;
};


export type AccountRootAdminUserArgs = {
  id: Scalars['ID']['input'];
};


export type AccountRootAdminUserPermissionsArgs = {
  userId: Scalars['ID']['input'];
};


export type AccountRootIsEmailTakenArgs = {
  email: Scalars['String']['input'];
  exceptUser?: InputMaybe<Scalars['ID']['input']>;
};


export type AccountRootIsOrganizationSlugTakenArgs = {
  exceptOrganization?: InputMaybe<Scalars['ID']['input']>;
  slug: Scalars['String']['input'];
};


export type AccountRootOrganizationBySlugArgs = {
  slug: Scalars['String']['input'];
};

export type AddTreeGraphNode = {
  entityId: Scalars['ID']['input'];
  node: SetTreeGraphNode;
};

export type AllocateArticleToStepCommand = {
  articleId: Scalars['ID']['input'];
  milliQuantity: Scalars['Int']['input'];
  stepId: Scalars['ID']['input'];
};

export type AllocateEbomPartToStepCommand = {
  ebomPartId: Scalars['ID']['input'];
  milliQuantity: Scalars['Int']['input'];
  stepId: Scalars['ID']['input'];
};

export type Article = IArticle & {
  __typename?: 'Article';
  articleNumber: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  released: Scalars['Boolean']['output'];
  revision: Scalars['String']['output'];
  type: ArticleType;
  unit: ArticleUnit;
};

export type ArticleAllocation = EbomPartAllocation | ManualArticleAllocation;

export type ArticleMutationRoot = {
  __typename?: 'ArticleMutationRoot';
  acceptEbomPartAllocationSuggestion: EbomPartAllocationSuggestion;
  acceptEbomPartAllocationSuggestions: Array<Maybe<Scalars['ID']['output']>>;
  allocateArticleToStep: Scalars['Boolean']['output'];
  allocateEbomPartToStep: Scalars['Boolean']['output'];
  bulkAllocateEbomPartsToStep: Scalars['Boolean']['output'];
  createConsumable: Article;
  createEbomPartAllocationSuggestions: Array<EbomPartAllocationSuggestion>;
  deleteArticle: Scalars['ID']['output'];
  deleteEbom: Scalars['ID']['output'];
  loadEbomFromCsv: LoadEbomResult;
  promoteEbomPartsToArticles: Array<EbomPart>;
  rejectEbomPartAllocationSuggestion: EbomPartAllocationSuggestion;
  rejectEbomPartAllocationSuggestions: Array<Maybe<Scalars['ID']['output']>>;
  replaceEbomPartAllocations: Scalars['Boolean']['output'];
  updateConsumable: Article;
};


export type ArticleMutationRootAcceptEbomPartAllocationSuggestionArgs = {
  command: AcceptEbomPartAllocationSuggestionCommand;
};


export type ArticleMutationRootAcceptEbomPartAllocationSuggestionsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type ArticleMutationRootAllocateArticleToStepArgs = {
  command: AllocateArticleToStepCommand;
};


export type ArticleMutationRootAllocateEbomPartToStepArgs = {
  command: AllocateEbomPartToStepCommand;
};


export type ArticleMutationRootBulkAllocateEbomPartsToStepArgs = {
  command: BulkAllocateEbomPartsToStepCommand;
};


export type ArticleMutationRootCreateConsumableArgs = {
  command: CreateConsumable;
};


export type ArticleMutationRootCreateEbomPartAllocationSuggestionsArgs = {
  ebomId: Scalars['ID']['input'];
};


export type ArticleMutationRootDeleteArticleArgs = {
  id: Scalars['ID']['input'];
};


export type ArticleMutationRootDeleteEbomArgs = {
  id: Scalars['ID']['input'];
};


export type ArticleMutationRootLoadEbomFromCsvArgs = {
  command: LoadEbomFromCsvCommand;
};


export type ArticleMutationRootPromoteEbomPartsToArticlesArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type ArticleMutationRootRejectEbomPartAllocationSuggestionArgs = {
  id: Scalars['ID']['input'];
};


export type ArticleMutationRootRejectEbomPartAllocationSuggestionsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type ArticleMutationRootReplaceEbomPartAllocationsArgs = {
  command: ReplaceEbomPartAllocationsCommand;
};


export type ArticleMutationRootUpdateConsumableArgs = {
  command: UpdateConsumable;
};

export type ArticlePath = {
  __typename?: 'ArticlePath';
  articleNumbers: Array<Scalars['String']['output']>;
};

export type ArticleRoot = {
  __typename?: 'ArticleRoot';
  article: Article;
  articles: Array<Article>;
  ebom: Ebom;
  ebomPart: EbomPart;
  ebomParts: Array<EbomPart>;
  ebomsForBop: Array<Ebom>;
};


export type ArticleRootArticleArgs = {
  id: Scalars['ID']['input'];
};


export type ArticleRootArticlesArgs = {
  query: ArticleSearchQuery;
};


export type ArticleRootEbomArgs = {
  id: Scalars['ID']['input'];
};


export type ArticleRootEbomPartArgs = {
  id: Scalars['ID']['input'];
};


export type ArticleRootEbomPartsArgs = {
  query: EbomPartSearchQuery;
};


export type ArticleRootEbomsForBopArgs = {
  bopId: Scalars['ID']['input'];
};

export type ArticleSearchQuery = {
  articleNumber?: InputMaybe<Scalars['String']['input']>;
  containsString?: InputMaybe<Scalars['String']['input']>;
  revision?: InputMaybe<Scalars['String']['input']>;
};

export enum ArticleType {
  Consumable = 'CONSUMABLE',
  Part = 'PART'
}

export enum ArticleUnit {
  Gram = 'GRAM',
  Kilogram = 'KILOGRAM',
  Liter = 'LITER',
  Meter = 'METER',
  Milliliter = 'MILLILITER',
  Millimeter = 'MILLIMETER',
  Pieces = 'PIECES'
}

export type Bop = {
  __typename?: 'BOP';
  id: Scalars['ID']['output'];
  nodes: Array<ModuleNode>;
  productConfiguration: ProductConfiguration;
  site: Site;
  version: Scalars['Int']['output'];
  versionStatus: VersionStatus;
};

export type BulkAllocateEbomPartsToStepCommand = {
  ebomPartIds: Array<Scalars['ID']['input']>;
  stepId: Scalars['ID']['input'];
};

export type Check = ConfirmCheck | NumericCheck | StringCheck;

export type CheckConfiguration = {
  __typename?: 'CheckConfiguration';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isRequired: Scalars['Boolean']['output'];
  lowerLimit?: Maybe<Scalars['Int']['output']>;
  order: Scalars['Int']['output'];
  pinnedId: Scalars['ID']['output'];
  type: CheckType;
  unreleasedChecks: Array<Check>;
  upperLimit?: Maybe<Scalars['Int']['output']>;
};

export type CheckI = {
  id: Scalars['ID']['output'];
  poNumber: Scalars['String']['output'];
  reportingUser: User;
  serialNumber: Scalars['String']['output'];
};

export enum CheckType {
  Confirmation = 'CONFIRMATION',
  Numeric = 'NUMERIC',
  String = 'STRING'
}

export type ConfirmCheck = CheckI & {
  __typename?: 'ConfirmCheck';
  confirmed: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  poNumber: Scalars['String']['output'];
  reportingUser: User;
  serialNumber: Scalars['String']['output'];
};

export type CreateCheckConfigurationCommand = {
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isRequired: Scalars['Boolean']['input'];
  lowerLimit?: InputMaybe<Scalars['Int']['input']>;
  order: Scalars['Int']['input'];
  stepId: Scalars['ID']['input'];
  type?: InputMaybe<CheckType>;
  upperLimit?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateConsumable = {
  articleNumber: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  revision: Scalars['String']['input'];
  unit: ArticleUnit;
};

export type CreateModule = {
  abbreviation: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  productId: Scalars['ID']['input'];
};

export type CreateMultipartUpload = {
  contentType: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type CreateOrganization = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};

export type CreateProduct = {
  abbreviation: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  imageUploadId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type CreateProductConfiguration = {
  abbreviation: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  forProductId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  imageUploadId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type CreateProductionBatchCommand = {
  assignedUserId?: InputMaybe<Scalars['ID']['input']>;
  bopId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  moduleId: Scalars['ID']['input'];
  poNumber: Scalars['String']['input'];
  serialNumbers: Array<Scalars['ID']['input']>;
};

export type CreateSite = {
  abbreviation: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type CreateStep = {
  durationInSeconds: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  imageUploadIds: Array<Scalars['ID']['input']>;
  instructionText?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  productId: Scalars['ID']['input'];
  stepTypeId: Scalars['ID']['input'];
  toolIds: Array<Scalars['ID']['input']>;
};

export type CreateStepType = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type CreateTool = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  imageUploadIds: Array<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  siteId: Scalars['ID']['input'];
};

export type CreateUser = {
  email: Scalars['String']['input'];
  firstname: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  lastname: Scalars['String']['input'];
};

export type CsvConfig = {
  articleNumberColumn: Scalars['String']['input'];
  ebomName: Scalars['String']['input'];
  nameColumn?: InputMaybe<Scalars['String']['input']>;
  parentArticleNumberColumn: Scalars['String']['input'];
  quantityColumn: Scalars['String']['input'];
  revisionColumn: Scalars['String']['input'];
  unitColumn?: InputMaybe<Scalars['String']['input']>;
};

export type CurrentUserAndPermissions = {
  __typename?: 'CurrentUserAndPermissions';
  orgPermissions?: Maybe<Array<PermissionOrganization>>;
  organization?: Maybe<Organization>;
  user: User;
  userPermissions: Array<PermissionUser>;
};

export type DagNode = {
  id: Scalars['ID']['output'];
  parentIds: Array<Scalars['ID']['output']>;
};

export type DeleteCheckCommand = {
  checkConfigurationId: Scalars['ID']['input'];
  poNumber: Scalars['String']['input'];
  serialNumber: Scalars['String']['input'];
};

export type DeleteOrganizationPermissions = {
  orgId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type Ebom = {
  __typename?: 'Ebom';
  ebomParts: Array<EbomPart>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  sourceId: Scalars['String']['output'];
  sourceType: EbomSourceType;
};

/**
 * A part in the EBOM in a specific assembly (which could be the root assembly).
 * The EBOM can consist of multiple EbomParts, referencing the same Part but in a diferent assembly in the EBOM.
 */
export type EbomPart = IArticle & {
  __typename?: 'EbomPart';
  article?: Maybe<Article>;
  articleNumber: Scalars['String']['output'];
  articlePath: ArticlePath;
  ebomId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  /** How many times the Part was present in the assembly in 1/1000 unit */
  milliQuantity: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  parentId?: Maybe<Scalars['ID']['output']>;
  revision: Scalars['String']['output'];
  stepAllocationSuggestions: Array<EbomPartAllocationSuggestion>;
  stepAllocations: Array<EbomPartAllocation>;
  unit: ArticleUnit;
};

export type EbomPartAllocation = IArticleAllocation & {
  __typename?: 'EbomPartAllocation';
  article: Article;
  ebomPart: EbomPart;
  id: Scalars['ID']['output'];
  milliQuantity: Scalars['Int']['output'];
  step: Step;
};

export type EbomPartAllocationSuggestion = {
  __typename?: 'EbomPartAllocationSuggestion';
  decision: EbomPartAllocationSuggestionDecision;
  ebomPart: EbomPart;
  id: Scalars['ID']['output'];
  milliQuantity: Scalars['Int']['output'];
  source: EbomPartAllocationSuggestionSource;
  step: Step;
};

export enum EbomPartAllocationSuggestionDecision {
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
  Undecided = 'UNDECIDED'
}

export enum EbomPartAllocationSuggestionSource {
  HistoricAllocation = 'HISTORIC_ALLOCATION'
}

export type EbomPartSearchQuery = {
  ebomId: Scalars['ID']['input'];
  searchString: Scalars['String']['input'];
};

export enum EbomSourceType {
  Csv = 'CSV'
}

export enum GraphEntityType {
  BillOfProcess = 'BILL_OF_PROCESS',
  Module = 'MODULE',
  Step = 'STEP'
}

export type IArticle = {
  articleNumber: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  revision: Scalars['String']['output'];
  unit: ArticleUnit;
};

export type IArticleAllocation = {
  article: Article;
  id: Scalars['ID']['output'];
  milliQuantity: Scalars['Int']['output'];
  step: Step;
};

export type ImageOptions = {
  height?: InputMaybe<Scalars['Int']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type LoadEbomFromCsvCommand = {
  bopId: Scalars['ID']['input'];
  config: CsvConfig;
  uploadId: Scalars['ID']['input'];
};

export type LoadEbomResult = {
  __typename?: 'LoadEbomResult';
  ebom?: Maybe<Ebom>;
  error?: Maybe<Scalars['String']['output']>;
};

export type ManualArticleAllocation = IArticleAllocation & {
  __typename?: 'ManualArticleAllocation';
  article: Article;
  id: Scalars['ID']['output'];
  milliQuantity: Scalars['Int']['output'];
  step: Step;
};

export type Module = {
  __typename?: 'Module';
  abbreviation: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  nodes: Array<StepNode>;
};

export type ModuleNode = DagNode & {
  __typename?: 'ModuleNode';
  id: Scalars['ID']['output'];
  module: Module;
  parentIds: Array<Scalars['ID']['output']>;
};

export type Move = {
  ids: Array<Scalars['ID']['input']>;
  otherId: Scalars['ID']['input'];
  placement: Placement;
};

export type Mutation = {
  __typename?: 'Mutation';
  account: AccountMutationRoot;
  article: ArticleMutationRoot;
  product: ProductMutationRoot;
  shopFloor: ShopFloorMutationRoot;
  site: SiteMutationRoot;
  upload: UploadMutationRoot;
};

export type NumericCheck = CheckI & {
  __typename?: 'NumericCheck';
  id: Scalars['ID']['output'];
  milliQuantity: Scalars['Int']['output'];
  poNumber: Scalars['String']['output'];
  reportingUser: User;
  serialNumber: Scalars['String']['output'];
};

export type Organization = {
  __typename?: 'Organization';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type OrganizationUserPermissions = {
  __typename?: 'OrganizationUserPermissions';
  organization: Organization;
  permissions: Array<PermissionOrganization>;
};

export enum PermissionOrganization {
  OrgAdmin = 'ORG_ADMIN',
  OrgArticleManagement = 'ORG_ARTICLE_MANAGEMENT',
  OrgMbom = 'ORG_MBOM',
  OrgMember = 'ORG_MEMBER',
  OrgOperator = 'ORG_OPERATOR',
  OrgPartAllocation = 'ORG_PART_ALLOCATION',
  OrgProductManagement = 'ORG_PRODUCT_MANAGEMENT',
  OrgUpload = 'ORG_UPLOAD'
}

export enum PermissionUser {
  UserGlobalAdmin = 'USER_GLOBAL_ADMIN'
}

export enum Placement {
  Above = 'ABOVE',
  Below = 'BELOW'
}

export type Product = {
  __typename?: 'Product';
  abbreviation: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageUpload?: Maybe<Upload>;
  imageUploadId?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  productConfigurations: Array<ProductConfiguration>;
};

export type ProductConfiguration = {
  __typename?: 'ProductConfiguration';
  abbreviation: Scalars['String']['output'];
  bops: Array<Bop>;
  bopsAtSite: Array<Bop>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageUpload?: Maybe<Upload>;
  imageUploadId?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  product: Product;
};


export type ProductConfigurationBopsAtSiteArgs = {
  siteId: Scalars['ID']['input'];
};

export type ProductMutationRoot = {
  __typename?: 'ProductMutationRoot';
  /** Top entity id refers to module id, node entity types are steps and entity ids refer to step ids */
  addNodeToModuleGraph: Scalars['Boolean']['output'];
  createCheckConfiguration: CheckConfiguration;
  createModule: Module;
  createProduct: Product;
  createProductConfiguration: ProductConfiguration;
  createStep: Step;
  createStepType: StepType;
  deleteCheckConfiguration: Scalars['ID']['output'];
  /** Deletes latest BOP version, only if it is not released */
  deleteLatestUnreleasedBOPVersion?: Maybe<Scalars['ID']['output']>;
  deleteModule?: Maybe<Scalars['ID']['output']>;
  deleteProduct: Scalars['ID']['output'];
  deleteProductConfiguration: Scalars['ID']['output'];
  deleteStep?: Maybe<Scalars['ID']['output']>;
  deleteStepType: Scalars['ID']['output'];
  /** Only possible if previous version was released */
  draftNewBOPVersion: Bop;
  moveCheckConfiguration: Scalars['Boolean']['output'];
  releaseLatestBOPVersion: Bop;
  /** Top entity id refers to BOP id, node entity types are modules and entity ids refer to module ids */
  setBillOfProcessGraph: Scalars['Boolean']['output'];
  /** Top entity id refers to module id, node entity types are steps and entity ids refer to step ids */
  setModuleGraph: Scalars['Boolean']['output'];
  setStepDurations: Scalars['Boolean']['output'];
  updateCheckConfiguration: CheckConfiguration;
  updateModule: Module;
  updateProduct: Product;
  updateProductConfiguration: ProductConfiguration;
  updateStep: Step;
  updateStepType: StepType;
};


export type ProductMutationRootAddNodeToModuleGraphArgs = {
  command: AddTreeGraphNode;
};


export type ProductMutationRootCreateCheckConfigurationArgs = {
  command: CreateCheckConfigurationCommand;
};


export type ProductMutationRootCreateModuleArgs = {
  command: CreateModule;
};


export type ProductMutationRootCreateProductArgs = {
  command: CreateProduct;
};


export type ProductMutationRootCreateProductConfigurationArgs = {
  command: CreateProductConfiguration;
};


export type ProductMutationRootCreateStepArgs = {
  command: CreateStep;
};


export type ProductMutationRootCreateStepTypeArgs = {
  command: CreateStepType;
};


export type ProductMutationRootDeleteCheckConfigurationArgs = {
  id: Scalars['ID']['input'];
};


export type ProductMutationRootDeleteLatestUnreleasedBopVersionArgs = {
  atSiteId: Scalars['ID']['input'];
  forProductConfigId: Scalars['ID']['input'];
};


export type ProductMutationRootDeleteModuleArgs = {
  id: Scalars['ID']['input'];
};


export type ProductMutationRootDeleteProductArgs = {
  id: Scalars['ID']['input'];
};


export type ProductMutationRootDeleteProductConfigurationArgs = {
  id: Scalars['ID']['input'];
};


export type ProductMutationRootDeleteStepArgs = {
  id: Scalars['ID']['input'];
};


export type ProductMutationRootDeleteStepTypeArgs = {
  id: Scalars['ID']['input'];
};


export type ProductMutationRootDraftNewBopVersionArgs = {
  atSiteId: Scalars['ID']['input'];
  forProductConfigId: Scalars['ID']['input'];
};


export type ProductMutationRootMoveCheckConfigurationArgs = {
  command: Move;
  inStepId: Scalars['ID']['input'];
};


export type ProductMutationRootReleaseLatestBopVersionArgs = {
  atSiteId: Scalars['ID']['input'];
  forProductConfigId: Scalars['ID']['input'];
};


export type ProductMutationRootSetBillOfProcessGraphArgs = {
  command: SetDagGraph;
};


export type ProductMutationRootSetModuleGraphArgs = {
  command: SetTreeGraph;
};


export type ProductMutationRootSetStepDurationsArgs = {
  command: SetStepDurations;
};


export type ProductMutationRootUpdateCheckConfigurationArgs = {
  command: UpdateCheckConfigurationCommand;
};


export type ProductMutationRootUpdateModuleArgs = {
  command: UpdateModule;
};


export type ProductMutationRootUpdateProductArgs = {
  command: UpdateProduct;
};


export type ProductMutationRootUpdateProductConfigurationArgs = {
  command: UpdateProductConfiguration;
};


export type ProductMutationRootUpdateStepArgs = {
  command: UpdateStep;
};


export type ProductMutationRootUpdateStepTypeArgs = {
  command: UpdateStepType;
};

export type ProductRoot = {
  __typename?: 'ProductRoot';
  bop: Bop;
  checkConfigurations: Array<CheckConfiguration>;
  module: Module;
  modules: Array<Module>;
  product: Product;
  productConfiguration: ProductConfiguration;
  products: Array<Product>;
  step: Step;
  stepTypes: Array<StepType>;
  steps?: Maybe<Array<Step>>;
};


export type ProductRootBopArgs = {
  id: Scalars['ID']['input'];
};


export type ProductRootCheckConfigurationsArgs = {
  stepId: Scalars['ID']['input'];
};


export type ProductRootModuleArgs = {
  id: Scalars['ID']['input'];
};


export type ProductRootModulesArgs = {
  productId: Scalars['ID']['input'];
};


export type ProductRootProductArgs = {
  id: Scalars['ID']['input'];
};


export type ProductRootProductConfigurationArgs = {
  id: Scalars['ID']['input'];
};


export type ProductRootStepArgs = {
  id: Scalars['ID']['input'];
};


export type ProductRootStepsArgs = {
  productId: Scalars['ID']['input'];
};

export type ProductionBatch = {
  __typename?: 'ProductionBatch';
  assignedUser?: Maybe<User>;
  bop: Bop;
  completed: Scalars['Boolean']['output'];
  currentStep?: Maybe<Step>;
  id: Scalars['ID']['output'];
  module: Module;
  poNumber: Scalars['String']['output'];
  serialNumbers: Array<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  account: AccountRoot;
  article: ArticleRoot;
  exception: Scalars['Boolean']['output'];
  product: ProductRoot;
  shopFloor: ShopFloorRoot;
  site: SiteRoot;
  upload: UploadRoot;
};

export type ReplaceEbomPartAllocation = {
  newEbomPartId: Scalars['ID']['input'];
  oldEbomPartId: Scalars['ID']['input'];
};

export type ReplaceEbomPartAllocationsCommand = {
  replacements: Array<ReplaceEbomPartAllocation>;
};

export type SetConfirmCheckCommand = {
  checkConfigurationId: Scalars['ID']['input'];
  confirmed: Scalars['Boolean']['input'];
  poNumber: Scalars['String']['input'];
  serialNumber: Scalars['String']['input'];
};

export type SetDagGraph = {
  entityId: Scalars['ID']['input'];
  nodes: Array<SetDagGraphNode>;
};

export type SetDagGraphNode = {
  entityId: Scalars['ID']['input'];
  entityType: GraphEntityType;
  id: Scalars['ID']['input'];
  parentIds: Array<Scalars['ID']['input']>;
};

export type SetNumericCheckCommand = {
  checkConfigurationId: Scalars['ID']['input'];
  milliQuantity: Scalars['Int']['input'];
  poNumber: Scalars['String']['input'];
  serialNumber: Scalars['String']['input'];
};

export type SetOrganizationPermissions = {
  orgId: Scalars['ID']['input'];
  permissions: Array<PermissionOrganization>;
  userId: Scalars['ID']['input'];
};

export type SetStepDuration = {
  durationInSeconds: Scalars['Int']['input'];
  stepId: Scalars['ID']['input'];
};

export type SetStepDurations = {
  durations: Array<SetStepDuration>;
};

export type SetStringCheckCommand = {
  checkConfigurationId: Scalars['ID']['input'];
  poNumber: Scalars['String']['input'];
  serialNumber: Scalars['String']['input'];
  text: Scalars['String']['input'];
};

export type SetTreeGraph = {
  entityId: Scalars['ID']['input'];
  nodes: Array<SetTreeGraphNode>;
};

export type SetTreeGraphNode = {
  entityId: Scalars['ID']['input'];
  entityType: GraphEntityType;
  id: Scalars['ID']['input'];
  parentId?: InputMaybe<Scalars['ID']['input']>;
};

export type SetUserPermissions = {
  permissions: Array<PermissionUser>;
  userId: Scalars['ID']['input'];
};

export type ShopFloorMutationRoot = {
  __typename?: 'ShopFloorMutationRoot';
  createProductionBatch: ProductionBatch;
  deleteCheck: Scalars['Boolean']['output'];
  deleteProductionBatch: Scalars['ID']['output'];
  setConfirmCheck: ConfirmCheck;
  setNumericCheck: NumericCheck;
  setStringCheck: StringCheck;
  updateProductionBatch: ProductionBatch;
};


export type ShopFloorMutationRootCreateProductionBatchArgs = {
  command: CreateProductionBatchCommand;
};


export type ShopFloorMutationRootDeleteCheckArgs = {
  command: DeleteCheckCommand;
};


export type ShopFloorMutationRootDeleteProductionBatchArgs = {
  id: Scalars['ID']['input'];
};


export type ShopFloorMutationRootSetConfirmCheckArgs = {
  command: SetConfirmCheckCommand;
};


export type ShopFloorMutationRootSetNumericCheckArgs = {
  command: SetNumericCheckCommand;
};


export type ShopFloorMutationRootSetStringCheckArgs = {
  command: SetStringCheckCommand;
};


export type ShopFloorMutationRootUpdateProductionBatchArgs = {
  command: UpdateProductionBatchCommand;
};

export type ShopFloorRoot = {
  __typename?: 'ShopFloorRoot';
  productionBatch?: Maybe<ProductionBatch>;
  productionBatchesInProgressForCurrentUser: Array<ProductionBatch>;
};


export type ShopFloorRootProductionBatchArgs = {
  id: Scalars['ID']['input'];
};

export type Site = {
  __typename?: 'Site';
  abbreviation: Scalars['String']['output'];
  archived: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  tools: Array<Tool>;
};

export type SiteMutationRoot = {
  __typename?: 'SiteMutationRoot';
  createSite: Site;
  createTool: Tool;
  deleteSite: Scalars['ID']['output'];
  deleteTool: Scalars['ID']['output'];
  updateSite: Site;
  updateTool: Tool;
};


export type SiteMutationRootCreateSiteArgs = {
  command: CreateSite;
};


export type SiteMutationRootCreateToolArgs = {
  command: CreateTool;
};


export type SiteMutationRootDeleteSiteArgs = {
  id: Scalars['ID']['input'];
};


export type SiteMutationRootDeleteToolArgs = {
  id: Scalars['ID']['input'];
};


export type SiteMutationRootUpdateSiteArgs = {
  command: UpdateSite;
};


export type SiteMutationRootUpdateToolArgs = {
  command: UpdateTool;
};

export type SiteRoot = {
  __typename?: 'SiteRoot';
  site: Site;
  sites: Array<Site>;
  tool: Tool;
};


export type SiteRootSiteArgs = {
  id: Scalars['ID']['input'];
};


export type SiteRootToolArgs = {
  id: Scalars['ID']['input'];
};

export type Step = {
  __typename?: 'Step';
  articleAllocations: Array<ArticleAllocation>;
  checkConfigurations: Array<CheckConfiguration>;
  durationInSeconds: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  imageUploadIds: Array<Scalars['ID']['output']>;
  imageUploads: Array<Upload>;
  instructionText?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  stepType: StepType;
  toolIds: Array<Scalars['ID']['output']>;
  tools: Array<Tool>;
};

export type StepNode = TreeNode & {
  __typename?: 'StepNode';
  id: Scalars['ID']['output'];
  parentId?: Maybe<Scalars['ID']['output']>;
  step: Step;
};

export type StepType = {
  __typename?: 'StepType';
  archived: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  released: Scalars['Boolean']['output'];
};

export type StringCheck = CheckI & {
  __typename?: 'StringCheck';
  id: Scalars['ID']['output'];
  poNumber: Scalars['String']['output'];
  reportingUser: User;
  serialNumber: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type Tool = {
  __typename?: 'Tool';
  archived: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageUploadIds: Array<Scalars['ID']['output']>;
  imageUploads: Array<Upload>;
  name: Scalars['String']['output'];
  released: Scalars['Boolean']['output'];
};

export type TreeGraph = {
  nodes: Array<TreeNode>;
};

export type TreeNode = {
  id: Scalars['ID']['output'];
  parentId?: Maybe<Scalars['ID']['output']>;
};

export type UpdateCheckConfigurationCommand = {
  description: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  isRequired: Scalars['Boolean']['input'];
  lowerLimit?: InputMaybe<Scalars['Int']['input']>;
  upperLimit?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateConsumable = {
  articleNumber: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  revision: Scalars['String']['input'];
  unit: ArticleUnit;
};

export type UpdateModule = {
  abbreviation: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type UpdateOrganization = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};

export type UpdateProduct = {
  abbreviation: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  imageUploadId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type UpdateProductConfiguration = {
  abbreviation: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  imageUploadId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type UpdateProductionBatchCommand = {
  assignedUserId?: InputMaybe<Scalars['ID']['input']>;
  completed: Scalars['Boolean']['input'];
  currentStepId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
};

export type UpdateSite = {
  abbreviation: Scalars['String']['input'];
  archived: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type UpdateStep = {
  durationInSeconds: Scalars['Int']['input'];
  id: Scalars['ID']['input'];
  imageUploadIds: Array<Scalars['ID']['input']>;
  instructionText?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  stepTypeId: Scalars['ID']['input'];
  toolIds: Array<Scalars['ID']['input']>;
};

export type UpdateStepType = {
  archived: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type UpdateTool = {
  archived: Scalars['Boolean']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  imageUploadIds: Array<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type UpdateUpload = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type UpdateUser = {
  email: Scalars['String']['input'];
  firstname: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  lastname: Scalars['String']['input'];
};

export type Upload = {
  __typename?: 'Upload';
  contentType: Scalars['String']['output'];
  createdAt: Scalars['Instant']['output'];
  csvColumnNames: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageUrl: Scalars['String']['output'];
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};


export type UploadImageUrlArgs = {
  options: ImageOptions;
};

export type UploadListItem = {
  __typename?: 'UploadListItem';
  contentType: Scalars['String']['output'];
  createdAt: Scalars['Instant']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type UploadMutationRoot = {
  __typename?: 'UploadMutationRoot';
  createMultipartUpload: Scalars['ID']['output'];
  deleteUpload: Scalars['ID']['output'];
  finalizeMultipartUpload: Upload;
  updateUpload: Upload;
};


export type UploadMutationRootCreateMultipartUploadArgs = {
  command: CreateMultipartUpload;
};


export type UploadMutationRootDeleteUploadArgs = {
  id: Scalars['ID']['input'];
};


export type UploadMutationRootFinalizeMultipartUploadArgs = {
  id: Scalars['ID']['input'];
};


export type UploadMutationRootUpdateUploadArgs = {
  command: UpdateUpload;
};

export type UploadQuery = {
  inContentTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  page: Scalars['Int']['input'];
  sortBy: UploadSortField;
  sortDirection: UploadSortDirection;
};

export type UploadRoot = {
  __typename?: 'UploadRoot';
  search: Array<UploadListItem>;
  upload: Upload;
  uploads: Array<Upload>;
};


export type UploadRootSearchArgs = {
  query: UploadQuery;
};


export type UploadRootUploadArgs = {
  id: Scalars['ID']['input'];
};


export type UploadRootUploadsArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export enum UploadSortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum UploadSortField {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME'
}

export type User = {
  __typename?: 'User';
  email: Scalars['String']['output'];
  firstname: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastname: Scalars['String']['output'];
};

export type UserSearchCriteria = {
  contains?: InputMaybe<Scalars['String']['input']>;
};

export enum VersionStatus {
  Approved = 'APPROVED',
  Draft = 'DRAFT',
  Released = 'RELEASED',
  Review = 'REVIEW'
}

export type BreadcrumbDataQueryVariables = Exact<{
  includeConfig: Scalars['Boolean']['input'];
  configId: Scalars['ID']['input'];
  includeBop: Scalars['Boolean']['input'];
  siteId: Scalars['ID']['input'];
  includeSite: Scalars['Boolean']['input'];
  bopId: Scalars['ID']['input'];
  includeEbom: Scalars['Boolean']['input'];
}>;


export type BreadcrumbDataQuery = { __typename?: 'Query', product: { __typename?: 'ProductRoot', products: Array<{ __typename?: 'Product', id: string, name: string, productConfigurations: Array<{ __typename?: 'ProductConfiguration', id: string, name: string }> }>, productConfiguration?: { __typename?: 'ProductConfiguration', name: string, bops: Array<{ __typename?: 'BOP', id: string, version: number, versionStatus: VersionStatus, site: { __typename?: 'Site', id: string, name: string } }> }, bop?: { __typename?: 'BOP', version: number, versionStatus: VersionStatus, nodes: Array<{ __typename?: 'ModuleNode', module: { __typename?: 'Module', id: string, name: string } }> } }, article: { __typename?: 'ArticleRoot', ebomsForBop?: Array<{ __typename?: 'Ebom', id: string, name: string }> }, site: { __typename?: 'SiteRoot', site?: { __typename?: 'Site', id: string, name: string } } };

export type TestExceptionQueryVariables = Exact<{ [key: string]: never; }>;


export type TestExceptionQuery = { __typename?: 'Query', exception: boolean };

export type OperatorBreadcrumbDataQueryVariables = Exact<{
  includeBop: Scalars['Boolean']['input'];
  bopId: Scalars['ID']['input'];
}>;


export type OperatorBreadcrumbDataQuery = { __typename?: 'Query', product: { __typename?: 'ProductRoot', products: Array<{ __typename?: 'Product', id: string, name: string, productConfigurations: Array<{ __typename?: 'ProductConfiguration', id: string, name: string }> }>, bop?: { __typename?: 'BOP', version: number, versionStatus: VersionStatus, site: { __typename?: 'Site', name: string }, nodes: Array<{ __typename?: 'ModuleNode', module: { __typename?: 'Module', id: string, name: string } }> } } };

export type ArticleAllocationListDataQueryVariables = Exact<{
  stepId: Scalars['ID']['input'];
}>;


export type ArticleAllocationListDataQuery = { __typename?: 'Query', product: { __typename?: 'ProductRoot', step: { __typename?: 'Step', articleAllocations: Array<{ __typename: 'EbomPartAllocation', id: string, milliQuantity: number, article: { __typename?: 'Article', id: string, articleNumber: string, revision: string, name?: string, unit: ArticleUnit, type: ArticleType }, ebomPart: { __typename?: 'EbomPart', id: string, ebomId: string, articleNumber: string, revision: string, name?: string, unit: ArticleUnit, articlePath: { __typename?: 'ArticlePath', articleNumbers: Array<string> } } } | { __typename: 'ManualArticleAllocation', id: string, milliQuantity: number, article: { __typename?: 'Article', id: string, articleNumber: string, revision: string, name?: string, unit: ArticleUnit, type: ArticleType } }> } } };

export type PartAllocationListAllocationMutationMutationVariables = Exact<{
  command: AllocateArticleToStepCommand;
}>;


export type PartAllocationListAllocationMutationMutation = { __typename?: 'Mutation', article: { __typename?: 'ArticleMutationRoot', allocateArticleToStep: boolean } };

export type EbomPartAllocationRowDataQueryVariables = Exact<{
  bopId: Scalars['ID']['input'];
}>;


export type EbomPartAllocationRowDataQuery = { __typename?: 'Query', article: { __typename?: 'ArticleRoot', ebomsForBop: Array<{ __typename?: 'Ebom', id: string, name: string }> } };

export type EbomPartAllocationRowAllocationMutationVariables = Exact<{
  command: AllocateEbomPartToStepCommand;
}>;


export type EbomPartAllocationRowAllocationMutation = { __typename?: 'Mutation', article: { __typename?: 'ArticleMutationRoot', allocateEbomPartToStep: boolean } };

export type ManualArticleAllocationRowAllocationMutationVariables = Exact<{
  command: AllocateArticleToStepCommand;
}>;


export type ManualArticleAllocationRowAllocationMutation = { __typename?: 'Mutation', article: { __typename?: 'ArticleMutationRoot', allocateArticleToStep: boolean } };

export type BopNavigationDrawerDataQueryVariables = Exact<{
  bopId: Scalars['ID']['input'];
}>;


export type BopNavigationDrawerDataQuery = { __typename?: 'Query', product: { __typename?: 'ProductRoot', bop: { __typename?: 'BOP', versionStatus: VersionStatus } }, article: { __typename?: 'ArticleRoot', ebomsForBop: Array<{ __typename?: 'Ebom', id: string, name: string, sourceType: EbomSourceType, sourceId: string }> } };

export type DeleteEbomMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteEbomMutation = { __typename?: 'Mutation', article: { __typename?: 'ArticleMutationRoot', deleteEbom: string } };

export type BopReleaseConfirmationDataQueryVariables = Exact<{
  productId: Scalars['ID']['input'];
  productConfigurationId: Scalars['ID']['input'];
  bopId: Scalars['ID']['input'];
}>;


export type BopReleaseConfirmationDataQuery = { __typename?: 'Query', product: { __typename?: 'ProductRoot', product: { __typename?: 'Product', name: string }, productConfiguration: { __typename?: 'ProductConfiguration', name: string }, bop: { __typename?: 'BOP', version: number, site: { __typename?: 'Site', id: string, name: string } } } };

export type BopReleaseConfirmationReleaseMutationVariables = Exact<{
  configId: Scalars['ID']['input'];
  siteId: Scalars['ID']['input'];
}>;


export type BopReleaseConfirmationReleaseMutation = { __typename?: 'Mutation', product: { __typename?: 'ProductMutationRoot', releaseLatestBOPVersion: { __typename?: 'BOP', id: string } } };

export type ConfirmationCheckSetMutationVariables = Exact<{
  command: SetConfirmCheckCommand;
}>;


export type ConfirmationCheckSetMutation = { __typename?: 'Mutation', shopFloor: { __typename?: 'ShopFloorMutationRoot', setConfirmCheck: { __typename?: 'ConfirmCheck', id: string } } };

export type ConfirmationCheckDeleteMutationVariables = Exact<{
  command: DeleteCheckCommand;
}>;


export type ConfirmationCheckDeleteMutation = { __typename?: 'Mutation', shopFloor: { __typename?: 'ShopFloorMutationRoot', deleteCheck: boolean } };

export type NumericCheckSetMutationVariables = Exact<{
  command: SetNumericCheckCommand;
}>;


export type NumericCheckSetMutation = { __typename?: 'Mutation', shopFloor: { __typename?: 'ShopFloorMutationRoot', setNumericCheck: { __typename?: 'NumericCheck', id: string } } };

export type NumericCheckDeleteMutationVariables = Exact<{
  command: DeleteCheckCommand;
}>;


export type NumericCheckDeleteMutation = { __typename?: 'Mutation', shopFloor: { __typename?: 'ShopFloorMutationRoot', deleteCheck: boolean } };

export type CheckDataQueryVariables = Exact<{
  stepId: Scalars['ID']['input'];
}>;


export type CheckDataQuery = { __typename?: 'Query', product: { __typename?: 'ProductRoot', checkConfigurations: Array<{ __typename?: 'CheckConfiguration', id: string, type: CheckType, isRequired: boolean, description: string, lowerLimit?: number, upperLimit?: number, unreleasedChecks: Array<{ __typename?: 'ConfirmCheck', id: string, poNumber: string, serialNumber: string, confirmed: boolean } | { __typename?: 'NumericCheck', id: string, poNumber: string, serialNumber: string, milliQuantity: number } | { __typename?: 'StringCheck', id: string, poNumber: string, serialNumber: string, text: string }> }> } };

export type StringCheckSetMutationVariables = Exact<{
  command: SetStringCheckCommand;
}>;


export type StringCheckSetMutation = { __typename?: 'Mutation', shopFloor: { __typename?: 'ShopFloorMutationRoot', setStringCheck: { __typename?: 'StringCheck', id: string } } };

export type StringCheckDeleteMutationVariables = Exact<{
  command: DeleteCheckCommand;
}>;


export type StringCheckDeleteMutation = { __typename?: 'Mutation', shopFloor: { __typename?: 'ShopFloorMutationRoot', deleteCheck: boolean } };

export type StepCheckConfigurationsDataQueryVariables = Exact<{
  stepId: Scalars['ID']['input'];
}>;


export type StepCheckConfigurationsDataQuery = { __typename?: 'Query', product: { __typename?: 'ProductRoot', checkConfigurations: Array<{ __typename?: 'CheckConfiguration', id: string, type: CheckType, isRequired: boolean, description: string, upperLimit?: number, lowerLimit?: number, order: number }> } };

export type CreateCheckConfigurationMutationVariables = Exact<{
  command: CreateCheckConfigurationCommand;
}>;


export type CreateCheckConfigurationMutation = { __typename?: 'Mutation', product: { __typename?: 'ProductMutationRoot', createCheckConfiguration: { __typename?: 'CheckConfiguration', id: string } } };

export type UpdateCheckConfigurationMutationVariables = Exact<{
  command: UpdateCheckConfigurationCommand;
}>;


export type UpdateCheckConfigurationMutation = { __typename?: 'Mutation', product: { __typename?: 'ProductMutationRoot', updateCheckConfiguration: { __typename?: 'CheckConfiguration', id: string } } };

export type MoveCheckConfigurationMutationVariables = Exact<{
  stepId: Scalars['ID']['input'];
  command: Move;
}>;


export type MoveCheckConfigurationMutation = { __typename?: 'Mutation', product: { __typename?: 'ProductMutationRoot', moveCheckConfiguration: boolean } };

export type DeleteCheckConfigurationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteCheckConfigurationMutation = { __typename?: 'Mutation', product: { __typename?: 'ProductMutationRoot', deleteCheckConfiguration: string } };

export type ViewCheckConfigurationsDataQueryVariables = Exact<{
  stepId: Scalars['ID']['input'];
}>;


export type ViewCheckConfigurationsDataQuery = { __typename?: 'Query', product: { __typename?: 'ProductRoot', checkConfigurations: Array<{ __typename?: 'CheckConfiguration', id: string, type: CheckType, isRequired: boolean, description: string, upperLimit?: number, lowerLimit?: number }> } };

export type CsvEbomLoaderGetCsvColumnNamesQueryVariables = Exact<{
  uploadId: Scalars['ID']['input'];
}>;


export type CsvEbomLoaderGetCsvColumnNamesQuery = { __typename?: 'Query', upload: { __typename?: 'UploadRoot', upload: { __typename?: 'Upload', id: string, csvColumnNames: Array<string> } } };

export type CsvEbomLoaderLoadEbomMutationVariables = Exact<{
  command: LoadEbomFromCsvCommand;
}>;


export type CsvEbomLoaderLoadEbomMutation = { __typename?: 'Mutation', article: { __typename?: 'ArticleMutationRoot', loadEbomFromCsv: { __typename?: 'LoadEbomResult', error?: string, ebom?: { __typename?: 'Ebom', id: string, ebomParts: Array<{ __typename?: 'EbomPart', id: string, articleNumber: string, revision: string, milliQuantity: number, name?: string, articlePath: { __typename?: 'ArticlePath', articleNumbers: Array<string> }, article?: { __typename?: 'Article', id: string } }> } } } };

export type EbomBulkAllocatePartTreeDataQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type EbomBulkAllocatePartTreeDataQuery = { __typename?: 'Query', article: { __typename?: 'ArticleRoot', ebom: { __typename?: 'Ebom', ebomParts: Array<{ __typename?: 'EbomPart', id: string, parentId?: string, articleNumber: string, name?: string, revision: string, unit: ArticleUnit, milliQuantity: number, article?: { __typename?: 'Article', id: string }, articlePath: { __typename?: 'ArticlePath', articleNumbers: Array<string> }, stepAllocations: Array<{ __typename?: 'EbomPartAllocation', id: string, milliQuantity: number, article: { __typename?: 'Article', unit: ArticleUnit } }> }> } } };

export type EbomBulkAllocateStepTreeDataQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type EbomBulkAllocateStepTreeDataQuery = { __typename?: 'Query', product: { __typename?: 'ProductRoot', bop: { __typename?: 'BOP', nodes: Array<{ __typename?: 'ModuleNode', id: string, parentIds: Array<string>, module: { __typename?: 'Module', id: string, name: string, description?: string, nodes: Array<{ __typename?: 'StepNode', id: string, parentId?: string, step: { __typename?: 'Step', id: string, name: string } }> } }> } } };

export type EbomBulkAllocateStepTreeNodeAllocateMutationVariables = Exact<{
  command: BulkAllocateEbomPartsToStepCommand;
}>;


export type EbomBulkAllocateStepTreeNodeAllocateMutation = { __typename?: 'Mutation', article: { __typename?: 'ArticleMutationRoot', bulkAllocateEbomPartsToStep: boolean } };

export type EbomDiffDialogDataQueryVariables = Exact<{
  ebomId: Scalars['ID']['input'];
  bopId: Scalars['ID']['input'];
}>;


export type EbomDiffDialogDataQuery = { __typename?: 'Query', article: { __typename?: 'ArticleRoot', ebom: { __typename?: 'Ebom', id: string, name: string }, ebomsForBop: Array<{ __typename?: 'Ebom', id: string, name: string, sourceType: EbomSourceType, sourceId: string }> } };

export type EbomDiffSamePartsMutationVariables = Exact<{
  replacements: Array<ReplaceEbomPartAllocation> | ReplaceEbomPartAllocation;
}>;


export type EbomDiffSamePartsMutation = { __typename?: 'Mutation', article: { __typename?: 'ArticleMutationRoot', replaceEbomPartAllocations: boolean } };

export type EbomDiffTreeDataQueryVariables = Exact<{
  oldEbomId: Scalars['ID']['input'];
  newEbomId: Scalars['ID']['input'];
}>;


export type EbomDiffTreeDataQuery = { __typename?: 'Query', article: { __typename?: 'ArticleRoot', oldEbom: { __typename?: 'Ebom', id: string, name: string, ebomParts: Array<{ __typename?: 'EbomPart', id: string, articleNumber: string, unit: ArticleUnit, revision: string, name?: string, milliQuantity: number, article?: { __typename?: 'Article', id: string }, articlePath: { __typename?: 'ArticlePath', articleNumbers: Array<string> }, stepAllocations: Array<{ __typename?: 'EbomPartAllocation', id: string, milliQuantity: number, article: { __typename?: 'Article', unit: ArticleUnit } }> }> }, newEbom: { __typename?: 'Ebom', id: string, name: string, ebomParts: Array<{ __typename?: 'EbomPart', id: string, articleNumber: string, unit: ArticleUnit, revision: string, name?: string, milliQuantity: number, article?: { __typename?: 'Article', id: string }, articlePath: { __typename?: 'ArticlePath', articleNumbers: Array<string> }, stepAllocations: Array<{ __typename?: 'EbomPartAllocation', id: string, milliQuantity: number, article: { __typename?: 'Article', unit: ArticleUnit } }> }> } } };

export type EbomPartDataFragment = { __typename?: 'Ebom', id: string, name: string, ebomParts: Array<{ __typename?: 'EbomPart', id: string, articleNumber: string, unit: ArticleUnit, revision: string, name?: string, milliQuantity: number, article?: { __typename?: 'Article', id: string }, articlePath: { __typename?: 'ArticlePath', articleNumbers: Array<string> }, stepAllocations: Array<{ __typename?: 'EbomPartAllocation', id: string, milliQuantity: number, article: { __typename?: 'Article', unit: ArticleUnit } }> }> };

export type EbomDiffViewerDataQueryVariables = Exact<{
  oldEbomId: Scalars['ID']['input'];
  newEbomId: Scalars['ID']['input'];
}>;


export type EbomDiffViewerDataQuery = { __typename?: 'Query', article: { __typename?: 'ArticleRoot', oldEbom: { __typename?: 'Ebom', id: string, name: string, sourceType: EbomSourceType, sourceId: string }, newEbom: { __typename?: 'Ebom', id: string, name: string, sourceType: EbomSourceType, sourceId: string } } };

export type EbomDataFragment = { __typename?: 'Ebom', id: string, name: string, sourceType: EbomSourceType, sourceId: string };

export type EbomPartAllocationSuggestionsLoaderCreateSuggestionsMutationVariables = Exact<{
  ebomId: Scalars['ID']['input'];
}>;


export type EbomPartAllocationSuggestionsLoaderCreateSuggestionsMutation = { __typename?: 'Mutation', article: { __typename?: 'ArticleMutationRoot', createEbomPartAllocationSuggestions: Array<{ __typename?: 'EbomPartAllocationSuggestion', source: EbomPartAllocationSuggestionSource }> } };

export type EbomPartDetailsModulesAndStepsQueryVariables = Exact<{
  bopId: Scalars['ID']['input'];
}>;


export type EbomPartDetailsModulesAndStepsQuery = { __typename?: 'Query', product: { __typename?: 'ProductRoot', bop: { __typename?: 'BOP', nodes: Array<{ __typename?: 'ModuleNode', module: { __typename?: 'Module', id: string, name: string, nodes: Array<{ __typename?: 'StepNode', step: { __typename?: 'Step', id: string, name: string } }> } }> } } };

export type EbomPartDetailsEbomPartQueryVariables = Exact<{
  ebomPartId: Scalars['ID']['input'];
}>;


export type EbomPartDetailsEbomPartQuery = { __typename?: 'Query', article: { __typename?: 'ArticleRoot', ebomPart: { __typename?: 'EbomPart', id: string, articleNumber: string, revision: string, name?: string, milliQuantity: number, unit: ArticleUnit, article?: { __typename?: 'Article', id: string }, stepAllocations: Array<{ __typename?: 'EbomPartAllocation', milliQuantity: number, step: { __typename?: 'Step', id: string, name: string } }>, stepAllocationSuggestions: Array<{ __typename?: 'EbomPartAllocationSuggestion', id: string, source: EbomPartAllocationSuggestionSource, decision: EbomPartAllocationSuggestionDecision, milliQuantity: number, step: { __typename?: 'Step', id: string, name: string } }> } } };

export type EbomPartDetailsPromoteToArticlesMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type EbomPartDetailsPromoteToArticlesMutation = { __typename?: 'Mutation', article: { __typename?: 'ArticleMutationRoot', promoteEbomPartsToArticles: Array<{ __typename?: 'EbomPart', id: string }> } };

export type EbomPartDetailsAllocateEbomPartToStepMutationVariables = Exact<{
  command: AllocateEbomPartToStepCommand;
}>;


export type EbomPartDetailsAllocateEbomPartToStepMutation = { __typename?: 'Mutation', article: { __typename?: 'ArticleMutationRoot', allocateEbomPartToStep: boolean } };

export type EbomPartDetailsAcceptSuggestionMutationVariables = Exact<{
  command: AcceptEbomPartAllocationSuggestionCommand;
}>;


export type EbomPartDetailsAcceptSuggestionMutation = { __typename?: 'Mutation', article: { __typename?: 'ArticleMutationRoot', acceptEbomPartAllocationSuggestion: { __typename?: 'EbomPartAllocationSuggestion', decision: EbomPartAllocationSuggestionDecision } } };

export type EbomPartDetailsRejectSuggestionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type EbomPartDetailsRejectSuggestionMutation = { __typename?: 'Mutation', article: { __typename?: 'ArticleMutationRoot', rejectEbomPartAllocationSuggestion: { __typename?: 'EbomPartAllocationSuggestion', decision: EbomPartAllocationSuggestionDecision } } };

export type EbomPartTreeEbomPartsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type EbomPartTreeEbomPartsQuery = { __typename?: 'Query', article: { __typename?: 'ArticleRoot', ebom: { __typename?: 'Ebom', ebomParts: Array<{ __typename?: 'EbomPart', id: string, parentId?: string, articleNumber: string, name?: string, revision: string, milliQuantity: number, unit: ArticleUnit, article?: { __typename?: 'Article', id: string }, articlePath: { __typename?: 'ArticlePath', articleNumbers: Array<string> }, stepAllocations: Array<{ __typename?: 'EbomPartAllocation', milliQuantity: number }>, stepAllocationSuggestions: Array<{ __typename?: 'EbomPartAllocationSuggestion', decision: EbomPartAllocationSuggestionDecision }> }> } } };

export type PromoteEbomPartsEbomQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type PromoteEbomPartsEbomQuery = { __typename?: 'Query', article: { __typename?: 'ArticleRoot', ebom: { __typename?: 'Ebom', id: string, ebomParts: Array<{ __typename?: 'EbomPart', id: string, articleNumber: string, revision: string, milliQuantity: number, unit: ArticleUnit, name?: string, articlePath: { __typename?: 'ArticlePath', articleNumbers: Array<string> }, article?: { __typename?: 'Article', id: string } }> } } };

export type PromoteEbomPartsPromotionMutationVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type PromoteEbomPartsPromotionMutation = { __typename?: 'Mutation', article: { __typename?: 'ArticleMutationRoot', promoteEbomPartsToArticles: Array<{ __typename?: 'EbomPart', id: string, article?: { __typename?: 'Article', id: string, name?: string } }> } };

export type BulkAllocationSuggestionActionsDataQueryVariables = Exact<{
  ebomId: Scalars['ID']['input'];
}>;


export type BulkAllocationSuggestionActionsDataQuery = { __typename?: 'Query', article: { __typename?: 'ArticleRoot', ebom: { __typename?: 'Ebom', ebomParts: Array<{ __typename?: 'EbomPart', milliQuantity: number, stepAllocations: Array<{ __typename?: 'EbomPartAllocation', id: string, milliQuantity: number }>, stepAllocationSuggestions: Array<{ __typename?: 'EbomPartAllocationSuggestion', id: string, decision: EbomPartAllocationSuggestionDecision, milliQuantity: number }> }> } } };

export type BulkAllocationSuggestionActionsRejectMutationVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type BulkAllocationSuggestionActionsRejectMutation = { __typename?: 'Mutation', article: { __typename?: 'ArticleMutationRoot', rejectEbomPartAllocationSuggestions: Array<string> } };

export type BulkAllocationSuggestionActionsAcceptMutationVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type BulkAllocationSuggestionActionsAcceptMutation = { __typename?: 'Mutation', article: { __typename?: 'ArticleMutationRoot', acceptEbomPartAllocationSuggestions: Array<string> } };

export type BulkAllocationSuggestionActionsCreateMutationVariables = Exact<{
  ebomId: Scalars['ID']['input'];
}>;


export type BulkAllocationSuggestionActionsCreateMutation = { __typename?: 'Mutation', article: { __typename?: 'ArticleMutationRoot', createEbomPartAllocationSuggestions: Array<{ __typename?: 'EbomPartAllocationSuggestion', id: string }> } };

export type EbomPartPickerDataQueryVariables = Exact<{
  query: EbomPartSearchQuery;
}>;


export type EbomPartPickerDataQuery = { __typename?: 'Query', article: { __typename?: 'ArticleRoot', ebomParts: Array<{ __typename?: 'EbomPart', id: string, articleNumber: string, revision: string, name?: string, articlePath: { __typename?: 'ArticlePath', articleNumbers: Array<string> }, article?: { __typename?: 'Article', id: string } }> } };

export type PartPickerDataQueryVariables = Exact<{
  searchString?: InputMaybe<Scalars['String']['input']>;
}>;


export type PartPickerDataQuery = { __typename?: 'Query', article: { __typename?: 'ArticleRoot', articles: Array<{ __typename?: 'Article', id: string, articleNumber: string, revision: string, name?: string }> } };

export type GetBopsForPickerQueryVariables = Exact<{
  productConfigurationId: Scalars['ID']['input'];
  siteId: Scalars['ID']['input'];
}>;


export type GetBopsForPickerQuery = { __typename?: 'Query', product: { __typename?: 'ProductRoot', productConfiguration: { __typename?: 'ProductConfiguration', bopsAtSite: Array<{ __typename?: 'BOP', id: string, version: number, versionStatus: VersionStatus }> } } };

export type GetUploadUrlForImageQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  maxDimension: Scalars['Int']['input'];
}>;


export type GetUploadUrlForImageQuery = { __typename?: 'Query', upload: { __typename?: 'UploadRoot', upload: { __typename?: 'Upload', id: string, imageUrl: string } } };

export type GetModulesForPickerQueryVariables = Exact<{
  bopId: Scalars['ID']['input'];
}>;


export type GetModulesForPickerQuery = { __typename?: 'Query', product: { __typename?: 'ProductRoot', bop: { __typename?: 'BOP', nodes: Array<{ __typename?: 'ModuleNode', module: { __typename?: 'Module', id: string, name: string, description?: string } }> } } };

export type GetUploadUrlForImagesQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  maxDimension: Scalars['Int']['input'];
}>;


export type GetUploadUrlForImagesQuery = { __typename?: 'Query', upload: { __typename?: 'UploadRoot', uploads: Array<{ __typename?: 'Upload', id: string, imageUrl: string }> } };

export type IsOrgSlugTakenQueryVariables = Exact<{
  slug: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type IsOrgSlugTakenQuery = { __typename?: 'Query', account: { __typename?: 'AccountRoot', isOrganizationSlugTaken: boolean } };

export type GetProductConfigsForPickerQueryVariables = Exact<{
  productId: Scalars['ID']['input'];
}>;


export type GetProductConfigsForPickerQuery = { __typename?: 'Query', product: { __typename?: 'ProductRoot', product: { __typename?: 'Product', productConfigurations: Array<{ __typename?: 'ProductConfiguration', id: string, name: string }> } } };

export type SitePickerDataQueryVariables = Exact<{ [key: string]: never; }>;


export type SitePickerDataQuery = { __typename?: 'Query', site: { __typename?: 'SiteRoot', sites: Array<{ __typename?: 'Site', id: string, name: string, archived: boolean }> } };

export type StepPickerDataQueryVariables = Exact<{
  moduleId: Scalars['ID']['input'];
}>;


export type StepPickerDataQuery = { __typename?: 'Query', product: { __typename?: 'ProductRoot', module: { __typename?: 'Module', abbreviation: string, nodes: Array<{ __typename?: 'StepNode', id: string, parentId?: string, step: { __typename?: 'Step', id: string, name: string, durationInSeconds: number, instructionText?: string, stepType: { __typename?: 'StepType', id: string, name: string } } }> } } };

export type GetStepTypesForPickerQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStepTypesForPickerQuery = { __typename?: 'Query', product: { __typename?: 'ProductRoot', stepTypes: Array<{ __typename?: 'StepType', id: string, name: string, archived: boolean }> } };

export type ToolPickerDataQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;


export type ToolPickerDataQuery = { __typename?: 'Query', site: { __typename?: 'SiteRoot', site: { __typename?: 'Site', tools: Array<{ __typename?: 'Tool', id: string, name: string, description?: string, archived: boolean, imageUploads: Array<{ __typename?: 'Upload', imageUrl: string }> }> } } };

export type IsUserEmailTakenQueryVariables = Exact<{
  email: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type IsUserEmailTakenQuery = { __typename?: 'Query', account: { __typename?: 'AccountRoot', isEmailTaken: boolean } };

export type GetSwitchOrganizationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSwitchOrganizationsQuery = { __typename?: 'Query', account: { __typename?: 'AccountRoot', organizations: Array<{ __typename?: 'OrganizationUserPermissions', organization: { __typename?: 'Organization', id: string, slug: string, name: string } }> } };

export type GetOrganizationInfoQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;


export type GetOrganizationInfoQuery = { __typename?: 'Query', account: { __typename?: 'AccountRoot', organizationBySlug?: { __typename?: 'Organization', id: string, name: string, slug: string } } };

export type GetCurrentUserAndPermissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserAndPermissionsQuery = { __typename?: 'Query', account: { __typename?: 'AccountRoot', me?: { __typename?: 'CurrentUserAndPermissions', userPermissions: Array<PermissionUser>, orgPermissions?: Array<PermissionOrganization>, organization?: { __typename?: 'Organization', id: string, name: string }, user: { __typename?: 'User', id: string, firstname: string, lastname: string, email: string } } } };

export type DownloadMbomDialogDataQueryVariables = Exact<{
  bopId: Scalars['ID']['input'];
}>;


export type DownloadMbomDialogDataQuery = { __typename?: 'Query', product: { __typename?: 'ProductRoot', bop: { __typename?: 'BOP', nodes: Array<{ __typename?: 'ModuleNode', id: string, module: { __typename?: 'Module', id: string, name: string, abbreviation: string, description?: string } }> } } };

export type CurrentProductionBatchDataQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentProductionBatchDataQuery = { __typename?: 'Query', shopFloor: { __typename?: 'ShopFloorRoot', productionBatchesInProgressForCurrentUser: Array<{ __typename?: 'ProductionBatch', id: string, poNumber: string, serialNumbers: Array<string>, completed: boolean, assignedUser?: { __typename?: 'User', id: string }, module: { __typename?: 'Module', id: string }, currentStep?: { __typename?: 'Step', id: string } }> } };

export type CurrentProductionBatchUpdateMutationVariables = Exact<{
  command: UpdateProductionBatchCommand;
}>;


export type CurrentProductionBatchUpdateMutation = { __typename?: 'Mutation', shopFloor: { __typename?: 'ShopFloorMutationRoot', updateProductionBatch: { __typename?: 'ProductionBatch', id: string, poNumber: string, serialNumbers: Array<string>, completed: boolean, assignedUser?: { __typename?: 'User', id: string }, module: { __typename?: 'Module', id: string }, currentStep?: { __typename?: 'Step', id: string } } } };

export type CompleteProductionBranchDataQueryVariables = Exact<{
  moduleId: Scalars['ID']['input'];
}>;


export type CompleteProductionBranchDataQuery = { __typename?: 'Query', product: { __typename?: 'ProductRoot', module: { __typename?: 'Module', id: string, abbreviation: string, nodes: Array<{ __typename?: 'StepNode', id: string, parentId?: string, step: { __typename?: 'Step', id: string, name: string, checkConfigurations: Array<{ __typename?: 'CheckConfiguration', id: string, description: string, isRequired: boolean, unreleasedChecks: Array<{ __typename?: 'ConfirmCheck', id: string, poNumber: string, serialNumber: string, reportingUser: { __typename?: 'User', id: string } } | { __typename?: 'NumericCheck', id: string, poNumber: string, serialNumber: string, reportingUser: { __typename?: 'User', id: string } } | { __typename?: 'StringCheck', id: string, poNumber: string, serialNumber: string, reportingUser: { __typename?: 'User', id: string } }> }> } }> } } };

export type CreateProductionBatchMutationVariables = Exact<{
  command: CreateProductionBatchCommand;
}>;


export type CreateProductionBatchMutation = { __typename?: 'Mutation', shopFloor: { __typename?: 'ShopFloorMutationRoot', createProductionBatch: { __typename?: 'ProductionBatch', id: string } } };

export type UserProductionBatchesDataQueryVariables = Exact<{ [key: string]: never; }>;


export type UserProductionBatchesDataQuery = { __typename?: 'Query', shopFloor: { __typename?: 'ShopFloorRoot', productionBatchesInProgressForCurrentUser: Array<{ __typename?: 'ProductionBatch', id: string, poNumber: string, serialNumbers: Array<string>, currentStep?: { __typename?: 'Step', id: string, name: string }, bop: { __typename?: 'BOP', id: string, version: number, site: { __typename?: 'Site', id: string, name: string }, productConfiguration: { __typename?: 'ProductConfiguration', id: string, name: string, product: { __typename?: 'Product', id: string, name: string } } }, module: { __typename?: 'Module', id: string, name: string } }> } };

export type UserProductionBatchesDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type UserProductionBatchesDeleteMutation = { __typename?: 'Mutation', shopFloor: { __typename?: 'ShopFloorMutationRoot', deleteProductionBatch: string } };

export type CreateStepAndAddNodeToModuleGraphMutationVariables = Exact<{
  step: CreateStep;
  node: AddTreeGraphNode;
}>;


export type CreateStepAndAddNodeToModuleGraphMutation = { __typename?: 'Mutation', product: { __typename?: 'ProductMutationRoot', addNodeToModuleGraph: boolean, createStep: { __typename?: 'Step', id: string } } };

export type StepToolbarDataQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type StepToolbarDataQuery = { __typename?: 'Query', product: { __typename?: 'ProductRoot', step: { __typename?: 'Step', id: string, name: string } } };

export type DeleteStepMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteStepMutation = { __typename?: 'Mutation', product: { __typename?: 'ProductMutationRoot', deleteStep?: string } };

export type StepTreeDataQueryVariables = Exact<{
  bopId: Scalars['ID']['input'];
  moduleId: Scalars['ID']['input'];
}>;


export type StepTreeDataQuery = { __typename?: 'Query', product: { __typename?: 'ProductRoot', bop: { __typename?: 'BOP', versionStatus: VersionStatus }, module: { __typename?: 'Module', id: string, abbreviation: string, nodes: Array<{ __typename?: 'StepNode', id: string, parentId?: string, step: { __typename?: 'Step', id: string, name: string, durationInSeconds: number, stepType: { __typename?: 'StepType', id: string, name: string } } }> } } };

export type SetGraphForModuleMutationVariables = Exact<{
  graph: SetTreeGraph;
}>;


export type SetGraphForModuleMutation = { __typename?: 'Mutation', product: { __typename?: 'ProductMutationRoot', setModuleGraph: boolean } };

export type GetStepQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetStepQuery = { __typename?: 'Query', product: { __typename?: 'ProductRoot', step: { __typename?: 'Step', id: string, durationInSeconds: number, imageUploadIds: Array<string>, instructionText?: string, name: string, toolIds: Array<string>, imageUploads: Array<{ __typename?: 'Upload', id: string, imageUrl: string }>, stepType: { __typename?: 'StepType', id: string, name: string }, tools: Array<{ __typename?: 'Tool', id: string, name: string, imageUploads: Array<{ __typename?: 'Upload', id: string, imageUrl: string }> }> } } };

export type UpdateStepMutationVariables = Exact<{
  step: UpdateStep;
}>;


export type UpdateStepMutation = { __typename?: 'Mutation', product: { __typename?: 'ProductMutationRoot', updateStep: { __typename?: 'Step', id: string } } };

export type ViewStepDataQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  imgHeight: Scalars['Int']['input'];
  imgWidth: Scalars['Int']['input'];
}>;


export type ViewStepDataQuery = { __typename?: 'Query', product: { __typename?: 'ProductRoot', step: { __typename?: 'Step', id: string, durationInSeconds: number, imageUploadIds: Array<string>, instructionText?: string, name: string, toolIds: Array<string>, imageUploads: Array<{ __typename?: 'Upload', id: string, imageUrl: string }>, stepType: { __typename?: 'StepType', id: string, name: string }, tools: Array<{ __typename?: 'Tool', id: string, name: string, description?: string, imageUploads: Array<{ __typename?: 'Upload', id: string, imageUrl: string }> }>, articleAllocations: Array<{ __typename?: 'EbomPartAllocation', id: string, milliQuantity: number, article: { __typename?: 'Article', id: string, type: ArticleType, name?: string, unit: ArticleUnit, revision: string, articleNumber: string } } | { __typename?: 'ManualArticleAllocation', id: string, milliQuantity: number, article: { __typename?: 'Article', id: string, type: ArticleType, name?: string, unit: ArticleUnit, revision: string, articleNumber: string } }> } } };

export type ImagesViewerDataQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
}>;


export type ImagesViewerDataQuery = { __typename?: 'Query', upload: { __typename?: 'UploadRoot', uploads: Array<{ __typename?: 'Upload', id: string, name: string, imageUrl: string }> } };

export type UploadPickerDataQueryVariables = Exact<{
  query: UploadQuery;
}>;


export type UploadPickerDataQuery = { __typename?: 'Query', upload: { __typename?: 'UploadRoot', search: Array<{ __typename?: 'UploadListItem', id: string, contentType: string, name: string, createdAt: any }> } };

export type UploadPickerSelectedUploadsQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type UploadPickerSelectedUploadsQuery = { __typename?: 'Query', upload: { __typename?: 'UploadRoot', uploads: Array<{ __typename?: 'Upload', id: string, contentType: string, name: string }> } };

export type GetUserPermissionsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetUserPermissionsQuery = { __typename?: 'Query', account: { __typename?: 'AccountRoot', adminUserPermissions: Array<PermissionUser>, adminOrganizations: Array<{ __typename?: 'Organization', id: string, name: string }>, adminUser: { __typename?: 'User', firstname: string, lastname: string }, adminOrganizationPermissions: Array<{ __typename?: 'OrganizationUserPermissions', permissions: Array<PermissionOrganization>, organization: { __typename?: 'Organization', id: string, name: string } }> } };

export type AdminSetUserPermissionsMutationVariables = Exact<{
  command: SetUserPermissions;
}>;


export type AdminSetUserPermissionsMutation = { __typename?: 'Mutation', account: { __typename?: 'AccountMutationRoot', adminSetUserPermissions: Array<PermissionUser> } };

export type AdminSetOrgPermissionsMutationVariables = Exact<{
  command: SetOrganizationPermissions;
}>;


export type AdminSetOrgPermissionsMutation = { __typename?: 'Mutation', account: { __typename?: 'AccountMutationRoot', adminSetOrganizationPermissions: Array<PermissionOrganization> } };

export type DeleteOrgUserPermissionsMutationVariables = Exact<{
  command: DeleteOrganizationPermissions;
}>;


export type DeleteOrgUserPermissionsMutation = { __typename?: 'Mutation', account: { __typename?: 'AccountMutationRoot', adminDeleteOrganizationPermissions: boolean } };

export type FinalizeMultipartUploadMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type FinalizeMultipartUploadMutation = { __typename?: 'Mutation', upload: { __typename?: 'UploadMutationRoot', finalizeMultipartUpload: { __typename?: 'Upload', id: string, name: string, contentType: string, createdAt: any } } };

export type CreateMultipartUploadMutationVariables = Exact<{
  command: CreateMultipartUpload;
}>;


export type CreateMultipartUploadMutation = { __typename?: 'Mutation', upload: { __typename?: 'UploadMutationRoot', createMultipartUpload: string } };

export type HomeViewDataQueryVariables = Exact<{ [key: string]: never; }>;


export type HomeViewDataQuery = { __typename?: 'Query', account: { __typename?: 'AccountRoot', organizations: Array<{ __typename?: 'OrganizationUserPermissions', permissions: Array<PermissionOrganization>, organization: { __typename?: 'Organization', id: string, name: string, slug: string } }> } };

export type AdminOrganizationViewDataQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminOrganizationViewDataQuery = { __typename?: 'Query', account: { __typename?: 'AccountRoot', adminOrganizations: Array<{ __typename?: 'Organization', id: string, name: string, slug: string }> } };

export type AdminOrganizationViewCreateMutationVariables = Exact<{
  command: CreateOrganization;
}>;


export type AdminOrganizationViewCreateMutation = { __typename?: 'Mutation', account: { __typename?: 'AccountMutationRoot', adminCreateOrganization: { __typename?: 'Organization', id: string } } };

export type AdminOrganizationViewUpdateMutationVariables = Exact<{
  command: UpdateOrganization;
}>;


export type AdminOrganizationViewUpdateMutation = { __typename?: 'Mutation', account: { __typename?: 'AccountMutationRoot', adminUpdateOrganization: { __typename?: 'Organization', id: string } } };

export type AdminOrganizationViewDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type AdminOrganizationViewDeleteMutation = { __typename?: 'Mutation', account: { __typename?: 'AccountMutationRoot', adminDeleteOrganization: string } };

export type AdminUserViewDataQueryVariables = Exact<{
  contains?: InputMaybe<Scalars['String']['input']>;
}>;


export type AdminUserViewDataQuery = { __typename?: 'Query', account: { __typename?: 'AccountRoot', adminSearchUsers: Array<{ __typename?: 'User', id: string, email: string, firstname: string, lastname: string }> } };

export type AdminUserViewCreateMutationVariables = Exact<{
  command: CreateUser;
}>;


export type AdminUserViewCreateMutation = { __typename?: 'Mutation', account: { __typename?: 'AccountMutationRoot', adminCreateUser: { __typename?: 'User', id: string } } };

export type AdminUserViewUpdateMutationVariables = Exact<{
  command: UpdateUser;
}>;


export type AdminUserViewUpdateMutation = { __typename?: 'Mutation', account: { __typename?: 'AccountMutationRoot', adminUpdateUser: { __typename?: 'User', id: string } } };

export type ArticleViewDataQueryVariables = Exact<{
  query: ArticleSearchQuery;
}>;


export type ArticleViewDataQuery = { __typename?: 'Query', article: { __typename?: 'ArticleRoot', articles: Array<{ __typename?: 'Article', id: string, type: ArticleType, articleNumber: string, revision: string, unit: ArticleUnit, name?: string, released: boolean }> } };

export type ArticleViewArticleAndRevisionTakenQueryVariables = Exact<{
  articleNumber: Scalars['String']['input'];
  revision: Scalars['String']['input'];
}>;


export type ArticleViewArticleAndRevisionTakenQuery = { __typename?: 'Query', article: { __typename?: 'ArticleRoot', articles: Array<{ __typename?: 'Article', id: string }> } };

export type ArticleViewCreateConsumableMutationVariables = Exact<{
  command: CreateConsumable;
}>;


export type ArticleViewCreateConsumableMutation = { __typename?: 'Mutation', article: { __typename?: 'ArticleMutationRoot', createConsumable: { __typename?: 'Article', id: string } } };

export type ArticleViewUpdateConsumableMutationVariables = Exact<{
  command: UpdateConsumable;
}>;


export type ArticleViewUpdateConsumableMutation = { __typename?: 'Mutation', article: { __typename?: 'ArticleMutationRoot', updateConsumable: { __typename?: 'Article', id: string } } };

export type ArticleViewDeleteArticleMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ArticleViewDeleteArticleMutation = { __typename?: 'Mutation', article: { __typename?: 'ArticleMutationRoot', deleteArticle: string } };

export type BopProcessViewDataQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
  productId: Scalars['ID']['input'];
  productConfigId: Scalars['ID']['input'];
  bopId: Scalars['ID']['input'];
}>;


export type BopProcessViewDataQuery = { __typename?: 'Query', site: { __typename?: 'SiteRoot', site: { __typename?: 'Site', id: string, abbreviation: string } }, product: { __typename?: 'ProductRoot', product: { __typename?: 'Product', id: string, abbreviation: string }, productConfiguration: { __typename?: 'ProductConfiguration', id: string, abbreviation: string }, bop: { __typename?: 'BOP', id: string, versionStatus: VersionStatus, nodes: Array<{ __typename?: 'ModuleNode', id: string, parentIds: Array<string>, module: { __typename?: 'Module', id: string, name: string, abbreviation: string, description?: string } }> } } };

export type SetGraphMutationVariables = Exact<{
  graph: SetDagGraph;
}>;


export type SetGraphMutation = { __typename?: 'Mutation', product: { __typename?: 'ProductMutationRoot', setBillOfProcessGraph: boolean } };

export type CreateModuleMutationVariables = Exact<{
  module: CreateModule;
  graph: SetDagGraph;
}>;


export type CreateModuleMutation = { __typename?: 'Mutation', product: { __typename?: 'ProductMutationRoot', setBillOfProcessGraph: boolean, createModule: { __typename?: 'Module', id: string } } };

export type UpdateModuleMutationVariables = Exact<{
  module: UpdateModule;
}>;


export type UpdateModuleMutation = { __typename?: 'Mutation', product: { __typename?: 'ProductMutationRoot', updateModule: { __typename?: 'Module', id: string } } };

export type DeleteModuleMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteModuleMutation = { __typename?: 'Mutation', product: { __typename?: 'ProductMutationRoot', deleteModule?: string } };

export type BopViewDataQueryVariables = Exact<{
  configId: Scalars['ID']['input'];
  siteId: Scalars['ID']['input'];
}>;


export type BopViewDataQuery = { __typename?: 'Query', product: { __typename?: 'ProductRoot', productConfiguration: { __typename?: 'ProductConfiguration', bopsAtSite: Array<{ __typename?: 'BOP', id: string, version: number, versionStatus: VersionStatus }> } } };

export type BopViewCreateMutationVariables = Exact<{
  configId: Scalars['ID']['input'];
  siteId: Scalars['ID']['input'];
}>;


export type BopViewCreateMutation = { __typename?: 'Mutation', product: { __typename?: 'ProductMutationRoot', draftNewBOPVersion: { __typename?: 'BOP', id: string } } };

export type BopViewDeleteMutationVariables = Exact<{
  configId: Scalars['ID']['input'];
  siteId: Scalars['ID']['input'];
}>;


export type BopViewDeleteMutation = { __typename?: 'Mutation', product: { __typename?: 'ProductMutationRoot', deleteLatestUnreleasedBOPVersion?: string } };

export type GetEbomQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetEbomQuery = { __typename?: 'Query', article: { __typename?: 'ArticleRoot', ebom: { __typename?: 'Ebom', id: string, name: string, sourceId: string, sourceType: EbomSourceType } } };

export type ModulePrintViewQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  maxStepImageDimensions: Scalars['Int']['input'];
  maxToolImageDimensions: Scalars['Int']['input'];
}>;


export type ModulePrintViewQuery = { __typename?: 'Query', product: { __typename?: 'ProductRoot', module: { __typename?: 'Module', id: string, name: string, description?: string, nodes: Array<{ __typename?: 'StepNode', id: string, parentId?: string, step: { __typename?: 'Step', id: string, name: string, instructionText?: string, imageUploadIds: Array<string>, stepType: { __typename?: 'StepType', id: string, name: string }, imageUploads: Array<{ __typename?: 'Upload', id: string, imageUrl: string }>, tools: Array<{ __typename?: 'Tool', id: string, name: string, description?: string, imageUploads: Array<{ __typename?: 'Upload', imageUrl: string }> }> } }> } } };

export type ModuleViewDataQueryVariables = Exact<{
  bopId: Scalars['ID']['input'];
  moduleId: Scalars['ID']['input'];
}>;


export type ModuleViewDataQuery = { __typename?: 'Query', product: { __typename?: 'ProductRoot', bop: { __typename?: 'BOP', id: string, versionStatus: VersionStatus }, module: { __typename?: 'Module', id: string, name: string, abbreviation: string } } };

export type ProductConfigurationViewDataQueryVariables = Exact<{
  productId: Scalars['ID']['input'];
}>;


export type ProductConfigurationViewDataQuery = { __typename?: 'Query', site: { __typename?: 'SiteRoot', sites: Array<{ __typename?: 'Site', id: string, name: string }> }, product: { __typename?: 'ProductRoot', product: { __typename?: 'Product', productConfigurations: Array<{ __typename?: 'ProductConfiguration', id: string, name: string, abbreviation: string, description?: string, imageUploadId?: string, imageUpload?: { __typename?: 'Upload', id: string, imageUrl: string }, bops: Array<{ __typename?: 'BOP', id: string, version: number, versionStatus: VersionStatus, site: { __typename?: 'Site', id: string, name: string } }> }> } } };

export type ProductConfigurationViewCreateMutationVariables = Exact<{
  command: CreateProductConfiguration;
}>;


export type ProductConfigurationViewCreateMutation = { __typename?: 'Mutation', product: { __typename?: 'ProductMutationRoot', createProductConfiguration: { __typename?: 'ProductConfiguration', id: string } } };

export type ProductConfigurationViewUpdateMutationVariables = Exact<{
  command: UpdateProductConfiguration;
}>;


export type ProductConfigurationViewUpdateMutation = { __typename?: 'Mutation', product: { __typename?: 'ProductMutationRoot', updateProductConfiguration: { __typename?: 'ProductConfiguration', id: string } } };

export type ProductConfigurationViewDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ProductConfigurationViewDeleteMutation = { __typename?: 'Mutation', product: { __typename?: 'ProductMutationRoot', deleteProductConfiguration: string } };

export type ProductViewDataQueryVariables = Exact<{ [key: string]: never; }>;


export type ProductViewDataQuery = { __typename?: 'Query', product: { __typename?: 'ProductRoot', products: Array<{ __typename?: 'Product', id: string, name: string, abbreviation: string, description?: string, imageUploadId?: string, imageUpload?: { __typename?: 'Upload', id: string, imageUrl: string } }> } };

export type ProductViewCreateMutationVariables = Exact<{
  command: CreateProduct;
}>;


export type ProductViewCreateMutation = { __typename?: 'Mutation', product: { __typename?: 'ProductMutationRoot', createProduct: { __typename?: 'Product', id: string } } };

export type ProductViewUpdateMutationVariables = Exact<{
  command: UpdateProduct;
}>;


export type ProductViewUpdateMutation = { __typename?: 'Mutation', product: { __typename?: 'ProductMutationRoot', updateProduct: { __typename?: 'Product', id: string } } };

export type ProductViewDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ProductViewDeleteMutation = { __typename?: 'Mutation', product: { __typename?: 'ProductMutationRoot', deleteProduct: string } };

export type SitesViewDataQueryVariables = Exact<{ [key: string]: never; }>;


export type SitesViewDataQuery = { __typename?: 'Query', site: { __typename?: 'SiteRoot', sites: Array<{ __typename?: 'Site', id: string, name: string, abbreviation: string, archived: boolean }> } };

export type SiteViewCreateMutationVariables = Exact<{
  command: CreateSite;
}>;


export type SiteViewCreateMutation = { __typename?: 'Mutation', site: { __typename?: 'SiteMutationRoot', createSite: { __typename?: 'Site', id: string } } };

export type SiteViewUpdateMutationVariables = Exact<{
  command: UpdateSite;
}>;


export type SiteViewUpdateMutation = { __typename?: 'Mutation', site: { __typename?: 'SiteMutationRoot', updateSite: { __typename?: 'Site', id: string } } };

export type SiteViewDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type SiteViewDeleteMutation = { __typename?: 'Mutation', site: { __typename?: 'SiteMutationRoot', deleteSite: string } };

export type StepTypeViewDataQueryVariables = Exact<{ [key: string]: never; }>;


export type StepTypeViewDataQuery = { __typename?: 'Query', product: { __typename?: 'ProductRoot', stepTypes: Array<{ __typename?: 'StepType', id: string, name: string, archived: boolean, released: boolean }> } };

export type StepTypeViewCreateMutationVariables = Exact<{
  command: CreateStepType;
}>;


export type StepTypeViewCreateMutation = { __typename?: 'Mutation', product: { __typename?: 'ProductMutationRoot', createStepType: { __typename?: 'StepType', id: string } } };

export type StepTypeViewUpdateMutationVariables = Exact<{
  command: UpdateStepType;
}>;


export type StepTypeViewUpdateMutation = { __typename?: 'Mutation', product: { __typename?: 'ProductMutationRoot', updateStepType: { __typename?: 'StepType', id: string } } };

export type StepTypeViewDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type StepTypeViewDeleteMutation = { __typename?: 'Mutation', product: { __typename?: 'ProductMutationRoot', deleteStepType: string } };

export type ToolsViewDataQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;


export type ToolsViewDataQuery = { __typename?: 'Query', site: { __typename?: 'SiteRoot', site: { __typename?: 'Site', tools: Array<{ __typename?: 'Tool', id: string, name: string, description?: string, imageUploadIds: Array<string>, archived: boolean, released: boolean }> } } };

export type ToolsViewCreateMutationVariables = Exact<{
  command: CreateTool;
}>;


export type ToolsViewCreateMutation = { __typename?: 'Mutation', site: { __typename?: 'SiteMutationRoot', createTool: { __typename?: 'Tool', id: string } } };

export type ToolsViewUpdateMutationVariables = Exact<{
  command: UpdateTool;
}>;


export type ToolsViewUpdateMutation = { __typename?: 'Mutation', site: { __typename?: 'SiteMutationRoot', updateTool: { __typename?: 'Tool', id: string } } };

export type ToolsViewDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ToolsViewDeleteMutation = { __typename?: 'Mutation', site: { __typename?: 'SiteMutationRoot', deleteTool: string } };

export type OperatorViewDataQueryVariables = Exact<{ [key: string]: never; }>;


export type OperatorViewDataQuery = { __typename?: 'Query', product: { __typename?: 'ProductRoot', products: Array<{ __typename?: 'Product', id: string, name: string, abbreviation: string, description?: string, imageUploadId?: string, imageUpload?: { __typename?: 'Upload', id: string, imageUrl: string } }> } };

export type OperatorStepTreeDataQueryVariables = Exact<{
  moduleId: Scalars['ID']['input'];
}>;


export type OperatorStepTreeDataQuery = { __typename?: 'Query', product: { __typename?: 'ProductRoot', module: { __typename?: 'Module', id: string, abbreviation: string, nodes: Array<{ __typename?: 'StepNode', id: string, parentId?: string, step: { __typename?: 'Step', id: string, name: string, durationInSeconds: number, stepType: { __typename?: 'StepType', id: string, name: string } } }> } } };

export type OperatorProductConfigurationViewDataQueryVariables = Exact<{
  productId: Scalars['ID']['input'];
}>;


export type OperatorProductConfigurationViewDataQuery = { __typename?: 'Query', site: { __typename?: 'SiteRoot', sites: Array<{ __typename?: 'Site', id: string, name: string }> }, product: { __typename?: 'ProductRoot', product: { __typename?: 'Product', productConfigurations: Array<{ __typename?: 'ProductConfiguration', id: string, name: string, abbreviation: string, description?: string, imageUploadId?: string, imageUpload?: { __typename?: 'Upload', id: string, imageUrl: string }, bops: Array<{ __typename?: 'BOP', id: string, version: number, versionStatus: VersionStatus, site: { __typename?: 'Site', id: string, name: string } }> }> } } };

export type OperatorProductConfigurationViewBopModulesQueryVariables = Exact<{
  bopId: Scalars['ID']['input'];
}>;


export type OperatorProductConfigurationViewBopModulesQuery = { __typename?: 'Query', product: { __typename?: 'ProductRoot', bop: { __typename?: 'BOP', nodes: Array<{ __typename?: 'ModuleNode', module: { __typename?: 'Module', id: string, name: string, description?: string, abbreviation: string } }> } } };

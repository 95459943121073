<script setup lang="ts">
import { ArticleType } from '@/generated/graphql'
import IconConsumable from '@/components/icons/IconConsumable.vue'
import IconPart from '@/components/icons/IconPart.vue'

const props = defineProps<{
  type: ArticleType
}>()
</script>

<template>
  <icon-consumable v-if="props.type == ArticleType.Consumable" />
  <icon-part v-if="props.type == ArticleType.Part" />
</template>

<style scoped lang="scss"></style>
